export const dataToFormData = data => {
    const formData = new FormData();
    for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        formData.append(key, element);
        }
    }

    return formData;
};


import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isIP } from 'is-ip';

// styles
import {
  RightNavContainer,
  RightNavContainerFoot,
  RightNavContainerX,
  RightNavContainerXPar,
  RightNavSecDiv,
  RNCFBtn,
  RNCFBtn2,
  SBMSDiv3,
  SBMSDiv3a,
  SBMSInput2,
  SDMHeading,
  SDMHeadingP,
  SideBarMainDiv,
  TeamHeadBtnImgP,
  TeamHeadBtnImgPa,
} from '../styles';

// Config
import {
  Int_Rec_Format_Options2,
  Rec_Format_Options1,
  Rec_Format_Options2,
  ext_Rec_Format_Options3,
  int_Rec_Format_Options3,
} from 'Config/permissionConstant';

// PNG
import cross from '../../../Assets/images/cross.png';

// Redux actions
import { apiOnboardOrgRobotRequest, apiClearInventoryRobotInfoRequest, apiGetInventoryRobotInfoRequest} from 'Redux/actions/gear';

const OnboardRobot = ({
  onChangeRightNav,
  organisationId,
  organisationName,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const gears = useSelector(state => state.gear);

  // useState
  const [airId, setAirId] = useState('');
  const [internal_record, set_internal_record] = useState({
    part1: '1080',
    part2: '23.98',
    part3: 'h.264',
  });
  const [external_stream, set_external_stream] = useState({
    part1: '1080',
    part2: 'p24',
    part3: 'h.264',
  });
  const [onboardRobotData, setOnboardRobotData] = useState({
    owner_name: organisationName,
    default_auto_update: true,
    default_auto_update_stream_ip: true,
    correct_air_id: false,
  });
  const [error, setError] = useState({});

  const formatAirId = (inputString) => {
    let digits = inputString.replace(/-/g, "");
    let digitChuckNum = Math.ceil(digits.length / 4);

    let result = '';
    for (let i = 0 ; i < digitChuckNum ; i++) {
      if (i !== digitChuckNum - 1) {
        result += digits.substring(i*4, i*4+4) + '-';
      } else {
        result += digits.substring(i*4);
      }
    }
    return result;
  }

  const setFormAirId = e => {
    const airId = formatAirId(e.target.value);
    setAirId(airId);

    if (airId.length == 14) {
      setOnboardRobotData(prev => ({ ...prev, air_id: airId, correct_air_id: false,}));
      setError(prev => ({
        ...prev,
        air_id: null,
      }));
      dispatch(apiGetInventoryRobotInfoRequest(auth.token, airId));
    } else {
      setOnboardRobotData(prev => ({
        ...prev,
        correct_air_id: false,
      }));
    }
  };

  const validateOnboardData = () => {
    const newErrors = {};

    if (!onboardRobotData.air_id) {
      newErrors.air_id = 'This field is required';
    } else if (onboardRobotData.air_id.length !== 14) {
      newErrors.air_id = 'Please provide a valid Air Id';
    } else if (!onboardRobotData.correct_air_id) {
      newErrors.air_id = 'Invalid AIR ID. Contact A.I.R. to confirm your AIR ID';
      toast.error("The AIR ID you provide is not valid, please contact A.I.R. to confirm your AIR ID");
    }

    if (!onboardRobotData.robot_org_nick_name) {
      newErrors.robot_org_nick_name = 'This field is required';
    }
    const nickNameRegex = /^[a-zA-Z0-9\s]+$/;
    if (nickNameRegex.test(onboardRobotData.robot_org_nick_name) === false) {
      newErrors.robot_org_nick_name = 'Nickname must only contains letters, numbers, and spaces.';
    }

    if (onboardRobotData.default_lan_ip && !isIP(onboardRobotData.default_lan_ip)) {
      newErrors.default_lan_ip = 'Invalid LAN IP address';
    }

    if (onboardRobotData.default_public_ip && !isIP(onboardRobotData.default_public_ip)) {
      newErrors.default_public_ip = 'Invalid WAN IP address';
    }

    if (onboardRobotData.default_stream_destination_ip && !isIP(onboardRobotData.default_stream_destination_ip)) {
      newErrors.default_stream_destination_ip = 'Invalid Stream Destination IP address';
    }

    if (onboardRobotData?.default_internal_record_format) {
      if (!internal_record.part1 || !internal_record.part2 || !internal_record.part3) {
        newErrors.internal_record = 'Internal record format is not valid';
      }
    } else {
      newErrors.internal_record = 'Internal record format must be set';
    }

    if (onboardRobotData?.default_external_stream_format) {
      if (!external_stream.part1 || !external_stream.part2 || !external_stream.part3) {
        newErrors.external_stream = 'External Stream format is not valid';
      }
    } else {
      newErrors.external_stream = 'External Stream format must be set';
    }

    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const closeSideBar = () => {
    onChangeRightNav('');
    dispatch(apiClearInventoryRobotInfoRequest());
  };

  const onConfirm = () => {
    const isValid = validateOnboardData();
    if (isValid) {
      onboardRobotData['organisation_id'] = organisationId;
      delete onboardRobotData['correct_air_id'];
      dispatch(apiOnboardOrgRobotRequest(auth.token, onboardRobotData));
      closeSideBar();
    }
  };

  useEffect(() => {
    let inventoryRobot = gears?.inventoryRobotInfo;
    if (Object.keys(inventoryRobot).length !== 0) {
      setOnboardRobotData(prev => ({
        ...prev,
        owner_name: organisationName || prev.owner_name,
        robot_org_nick_name: inventoryRobot?.nick_name || prev.robot_org_nick_name,
        default_lan_ip: inventoryRobot?.air_station_lan_ip || prev.default_lan_ip || "",
        default_public_ip: inventoryRobot?.air_station_public_ip || prev.default_public_ip || "",
        default_stream_destination_ip: inventoryRobot?.air_station_stream_destination_ip || prev.default_stream_destination_ip || "",
        correct_air_id: true,
        robot_id: inventoryRobot.id
      }));
      setError(prev => ({
        ...prev,
        air_id: null,
      }));
    } else {
      setOnboardRobotData(prev => ({
        ...prev,
        correct_air_id: false,
      }));
    }
  }, [gears?.inventoryRobotInfo]);

  useEffect(() => {
    if (internal_record.part1 && internal_record.part2 && internal_record.part3) {
      setOnboardRobotData(prev => ({
        ...prev,
        default_internal_record_format: `${internal_record.part1}/${internal_record.part2}/${internal_record.part3}`,
      }));
      setError(prev => ({
        ...prev,
        internal_record: '',
      }));
    } else if (internal_record.part1 || internal_record.part2 || internal_record.part3) {
      setError(prev => ({
        ...prev,
        internal_record: 'Internal record format is not valid. Input all three values',
      }));
      setOnboardRobotData(prev => ({
        ...prev,
        default_internal_record_format: '',
      }));
    } else {
      setError(prev => ({
        ...prev,
        internal_record: '',
      }));
      setOnboardRobotData(prev => ({
        ...prev,
        default_internal_record_format: '',
      }));
    }
  }, [internal_record]);

  useEffect(() => {
    if (external_stream.part1 && external_stream.part2 && external_stream.part3) {
      setOnboardRobotData(prev => ({
        ...prev,
        default_external_stream_format: `${external_stream.part1}/${external_stream.part2}/${external_stream.part3}`,
      }));
      setError(prev => ({
        ...prev,
        external_stream: '',
      }));
    } else if (external_stream.part1 || external_stream.part2 || external_stream.part3) {
      setError(prev => ({
        ...prev,
        external_stream: 'External Stream format is not valid. Input all three values.',
      }));
      setOnboardRobotData(prev => ({
        ...prev,
        default_external_stream_format: '',
      }));
    } else {
      setError(prev => ({
        ...prev,
        external_stream: '',
      }));
      setOnboardRobotData(prev => ({
        ...prev,
        default_external_stream_format: '',
      }));
    }
  }, [external_stream]);

  // We might re-use this in the future
  // const cameraOptions = [{ value: 'air_one', label: 'AIR One™' }];

  // const airTypeStyles = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     color: '#344054',
  //     width: '100%',
  //     backgroundColor: '#fff',
  //   }),
  //   control: () => ({
  //     width: '100%',
  //     display: 'flex',
  //     border: '1px solid #D0D5DD',
  //     borderRadius: 8,
  //     height: 44,
  //     fontWeight: 400,
  //     color: '#344054',
  //     fontSize: 16,
  //     fontFamily: 'Inter',
  //   }),
  //   container: base => ({
  //     ...base,
  //     flex: 1,
  //   }),
  // };

  // const ValueContainer = props => {
  //   return (
  //     <components.Option {...props}>
  //       <OptionsValueContainer>
  //         <OptionText>{props.data.label}</OptionText>
  //         {props.data.label === optionSelect || props.isSelected ? (
  //           <img src={tick} style={{ width: 13.33, height: 9.17 }} />
  //         ) : null}
  //       </OptionsValueContainer>
  //     </components.Option>
  //   );
  // };

  // const DropDownIcon = props => (
  //   <DropDownIconContainer>
  //     <svg width={12} height={8} fill="none" xmlns="http://www.w3.org/2000/svg">
  //       <path
  //         d="m1 1.5 5 5 5-5"
  //         stroke="#667085"
  //         strokeWidth={1.667}
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //     </svg>
  //   </DropDownIconContainer>
  // );

  return (
    <RightNavContainer>
      {/* =========================Header=================================== */}
      <RightNavContainerX onClick={closeSideBar}>
        <RightNavContainerXPar src={cross} />
      </RightNavContainerX>

      <SideBarMainDiv>
        <SDMHeading>Onboard Robot</SDMHeading>
        {/* =========================Body=================================== */}
        <RightNavSecDiv
          style={{
            padding: '5px',
            marginBottom: '70px',
            paddingBottom: '50px',
          }}
        >
          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>AIR ID</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="0000-0000-0000"
                maxLength={14}
                value={airId}
                onChange={setFormAirId}
              />
            </SBMSDiv3>
            {error?.air_id && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error?.air_id}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Owner</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="Owner"
                maxLength={30}
                readOnly
                value={onboardRobotData.owner_name}
                style={{ color: '#667085' }}
              />
            </SBMSDiv3>
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Nickname</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="Enter Nickname"
                maxLength={30}
                onChange={e =>
                  setOnboardRobotData(prev => ({ ...prev, robot_org_nick_name: e.target.value }))
                }
                value={onboardRobotData?.robot_org_nick_name || ''}
              />
            </SBMSDiv3>
            {error?.robot_org_nick_name && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error?.robot_org_nick_name}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15, marginLeft: '5px', cursor: 'pointer' }}>
            <input
              type="checkbox"
              className="form-check-input"
              id="default_auto_update"
              onChange={e =>
                setOnboardRobotData(prev => ({ ...prev, default_auto_update: e.target.checked }))
              }
              defaultChecked={true}
            />
            <label
              className="form-label form-check-label"
              htmlFor="default_auto_update"
              style={{ marginLeft: '8px' }}
            >
              Auto Update Lan IP / WAN IP
            </label>
          </div>

          <div style={{ marginTop: 5 }}>
            <SDMHeadingP>Default Lan IP</SDMHeadingP>
            <input
              type="text"
              className="form-control"
              placeholder="Example: 123.123.123.123"
              maxLength={30}
              value={onboardRobotData.default_lan_ip}
              onChange={e => {
                if (e.target.value.length > 0 && !isIP(e.target.value)) {
                  setError(prev => ({
                    ...prev,
                    default_lan_ip: 'Invalid LAN IP address',
                  }));
                  setOnboardRobotData(prev => ({ ...prev, default_lan_ip: e.target.value }));
                } else {
                  setOnboardRobotData(prev => ({ ...prev, default_lan_ip: e.target.value }));
                  setError(prev => ({ ...prev, default_lan_ip: null }));
                }
              }}
            />
            {error?.default_lan_ip && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.default_lan_ip}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Default WAN IP</SDMHeadingP>
            <input
              type="text"
              placeholder="Example: 123.123.123.123"
              maxLength={30}
              className="form-control"
              value={onboardRobotData.default_public_ip}
              onChange={e => {
                if (e.target.value.length > 0 && !isIP(e.target.value)) {
                  setError(prev => ({
                    ...prev,
                    default_public_ip: 'Invalid WAN IP address',
                  }));
                  setOnboardRobotData(prev => ({ ...prev, default_public_ip: e.target.value }));
                } else {
                  setOnboardRobotData(prev => ({ ...prev, default_public_ip: e.target.value }));
                  setError(prev => ({ ...prev, default_public_ip: null }));
                }
              }}
            />
            {error?.default_public_ip && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.default_public_ip}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15, marginLeft: '5px', cursor: 'pointer' }}>
            <input
              type="checkbox"
              className="form-check-input"
              id="default_auto_update_stream_ip"
              onChange={e =>
                setOnboardRobotData(prev => ({ ...prev, default_auto_update_stream_ip: e.target.checked }))
              }
              defaultChecked={true}
            />
            <label
              className="form-label form-check-label"
              htmlFor="default_auto_update_stream_ip"
              style={{ marginLeft: '8px' }}
            >
              Auto Update Stream Destination IP
            </label>
          </div>

          <div style={{ marginTop: 5 }}>
            <SDMHeadingP>Default Stream Destination IP</SDMHeadingP>
            <input
              type="text"
              className="form-control"
              placeholder="Example: 123.123.123.123"
              maxLength={30}
              value={onboardRobotData.default_stream_destination_ip}
              onChange={e => {
                if (e.target.value.length > 0 && !isIP(e.target.value)) {
                  setError(prev => ({
                    ...prev,
                    default_stream_destination_ip: 'Invalid Stream Destination IP address',
                  }));
                  setOnboardRobotData(prev => ({ ...prev, default_stream_destination_ip: e.target.value }));
                } else {
                  setOnboardRobotData(prev => ({ ...prev, default_stream_destination_ip: e.target.value }));
                  setError(prev => ({ ...prev, default_stream_destination_ip: null }));
                }
              }}
            />
            {error?.default_stream_destination_ip && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.default_stream_destination_ip}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>Internal Record Format</SDMHeadingP>
            <SBMSDiv3a style={{ columnGap: '4px' }}>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part1: e.target.value,
                  }));
                }}
              >
                {Rec_Format_Options1.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === internal_record?.part1}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part2: e.target.value,
                  }));
                }}
              >
                {Int_Rec_Format_Options2.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === internal_record?.part2}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part3: e.target.value,
                  }));
                }}
              >
                {int_Rec_Format_Options3.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === internal_record?.part3}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
            </SBMSDiv3a>
            {error?.internal_record && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.internal_record}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>External Stream Format</SDMHeadingP>
            <SBMSDiv3a style={{ columnGap: '4px' }}>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_external_stream(prev => ({
                    ...prev,
                    part1: e.target.value,
                  }));
                }}
              >
                {Rec_Format_Options1.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === external_stream?.part1}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_external_stream(prev => ({
                    ...prev,
                    part2: e.target.value,
                  }));
                }}
              >
                {Rec_Format_Options2.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === external_stream?.part2}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_external_stream(prev => ({
                    ...prev,
                    part3: e.target.value,
                  }));
                }}
              >
                {ext_Rec_Format_Options3.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === external_stream?.part3}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
            </SBMSDiv3a>
            {error?.external_stream && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.external_stream}
                </p>
              </div>
            )}
          </div>
        </RightNavSecDiv>
      </SideBarMainDiv>

      {/* =========================Footer=================================== */}
      <RightNavContainerFoot>
        <RNCFBtn>
          <TeamHeadBtnImgP onClick={closeSideBar}>Cancel</TeamHeadBtnImgP>
        </RNCFBtn>
        <RNCFBtn2>
          <TeamHeadBtnImgPa onClick={onConfirm}>Confirm</TeamHeadBtnImgPa>
        </RNCFBtn2>
      </RightNavContainerFoot>
    </RightNavContainer>
  );
};

export default OnboardRobot;

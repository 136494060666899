import React, { useState, useEffect } from 'react';
import { isIP } from 'is-ip';

// styles
import {
  RNCFBtn,
  RNCFBtn2,
  SBMSDiv3a,
  SDMHeading,
  SDMHeadingP,
  SideBarMainDiv,
  TeamHeadBtnImgP,
  RightNavSecDiv,
  TeamHeadBtnImgPa,
  RightNavContainer,
  RightNavContainerFoot,
  RightNavContainerX,
  RightNavContainerXPar,
} from './styles';

// Config
import {
  Int_Rec_Format_Options2,
  Rec_Format_Options1,
  Rec_Format_Options2,
  ext_Rec_Format_Options3,
  int_Rec_Format_Options3,
} from 'Config/permissionConstant';

// PNG
import cross from '../../Assets/images/cross.png';

const EditOrgRobot = ({
  selectedRobot,
  onGearEditApi,
  closeSideBar,
}) => {
  const [robotUpdateData, setRobotUpdateData] = useState({...selectedRobot});

  const [error, setError] = useState({
    robot_org_nick_name: null,
    internal_record: null,
    external_stream: null,
    default_lan_ip: null,
    default_public_ip: null,
  });
  const [internal_record, set_internal_record] = useState({
    part1: '1080',
    part2: '23.98',
    part3: 'h.264',
  });
  const [external_stream, set_external_stream] = useState({
    part1: '1080',
    part2: 'p24',
    part3: 'h.264',
  });
  const nickNameRegex = /^[a-zA-Z0-9\s]+$/;

  const splitFormat = format => {
    return format.split('/');
  };

  const validateInternalRecordFormat = () => {
    if (robotUpdateData?.default_internal_record_format) {
      if (
        !internal_record.part1 ||
        !internal_record.part2 ||
        !internal_record.part3
      ) {
        setError(prev => ({
          ...prev,
          internal_record: 'Internal record format is not valid. Input all three values.',
        }));
        return false;
      }
    }
    return true;
  }

  const validateExternalStreamFormat = () => {
    if (robotUpdateData?.default_external_stream_format) {
      if (
        !external_stream.part1 ||
        !external_stream.part2 ||
        !external_stream.part3
      ) {
        setError(prev => ({
          ...prev,
          external_stream: 'External Stream format is not valid. Input all three values.',
        }));
        return false;
      }
    }
    return true;
  }

  const validateUpdateData = () => {
    if (!robotUpdateData?.air_id) {
      setError(prev => ({ ...prev, air_id: 'This field is required' }));
      return;
    }
    if (!robotUpdateData?.robot_org_nick_name || !robotUpdateData?.robot_org_nick_name === '') {
      setError(prev => ({ ...prev, robot_org_nick_name: 'This field is required' }));
      return;
    }

    if (!validateInternalRecordFormat() || !validateExternalStreamFormat()) return;
    if (error?.default_lan_ip || error?.default_public_ip) return;
    return true;
  };

  const onConfirm = () => {
    if (validateUpdateData()) {
      onGearEditApi(robotUpdateData);
      closeSideBar();
    } else {
      return;
    }
  };

  useEffect(() => {
    if (selectedRobot?.default_internal_record_format) {
      const [part1, part2, part3] = splitFormat(selectedRobot?.default_internal_record_format);
      set_internal_record({ part1, part2, part3 });
    }
    if (selectedRobot?.default_external_stream_format) {
      const [part1, part2, part3] = splitFormat(selectedRobot?.default_external_stream_format);
      set_external_stream({ part1, part2, part3 });
    }
  }, []);

  useEffect(() => {
    if (internal_record.part1 && internal_record.part2 && internal_record.part3) {
      setRobotUpdateData(prev => ({
        ...prev,
        default_internal_record_format: `${internal_record.part1}/${internal_record.part2}/${internal_record.part3}`,
      }));
      setError(prev => ({
        ...prev,
        internal_record: '',
      }));
    } else if (internal_record.part1 || internal_record.part2 || internal_record.part3) { // Only show error if the record format is not complete
      setError(prev => ({
        ...prev,
        internal_record: 'Internal record format is not valid. Input all three values',
      }));
      setRobotUpdateData(prev => ({
        ...prev,
        default_internal_record_format: '',
      }));
    } else {
      setError(prev => ({
        ...prev,
        internal_record: '',
      }));
      setRobotUpdateData(prev => ({
        ...prev,
        default_internal_record_format: '',
      }));
    }
  }, [internal_record]);

  useEffect(() => {
    if (external_stream.part1 && external_stream.part2 && external_stream.part3) {
      setRobotUpdateData(prev => ({
        ...prev,
        default_external_stream_format: `${external_stream.part1}/${external_stream.part2}/${external_stream.part3}`,
      }));
      setError(prev => ({
        ...prev,
        external_stream: null,
      }));
    } else if (external_stream.part1 || external_stream.part2 || external_stream.part3) {
      setError(prev => ({
        ...prev,
        external_stream: 'External Stream format is not valid. Input all three values.',
      }));
      setRobotUpdateData(prev => ({
        ...prev,
        default_external_stream_format: '',
      }));
    } else {
      setError(prev => ({
        ...prev,
        external_stream: '',
      }));
      setRobotUpdateData(prev => ({
        ...prev,
        default_external_stream_format: '',
      }));
    }
  }, [external_stream]);

  return (
    <RightNavContainer>
      {/* =========================Header=================================== */}
      <RightNavContainerX onClick={closeSideBar}>
        <RightNavContainerXPar src={cross} />
      </RightNavContainerX>
      <SideBarMainDiv>
        <SDMHeading>Edit Org Robot Info </SDMHeading>
        {/* =========================Body=================================== */}
        <RightNavSecDiv>
          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Owner</SDMHeadingP>
            <input
              type="text"
              placeholder="Owner"
              className="form-control"
              defaultValue={robotUpdateData?.owner_name}
              style={{ color: '#667085' }}
              onChange={e =>
                setRobotUpdateData(prev => ({
                  ...prev,
                  owner_name: e.target.value,
                }))
              }
            />
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Org Robot Nickname</SDMHeadingP>
            <input
              type="text"
              placeholder="Enter Nickname"
              className="form-control"
              defaultValue={robotUpdateData?.robot_org_nick_name}
              onChange={e => {
                if (e.target.value.length > 0 && nickNameRegex.test(e.target.value) === false) {
                  setError(prev => ({
                    ...prev,
                    robot_org_nick_name: 'Nickname must only contains letters, numbers, and spaces.',
                  }));
                  setRobotUpdateData(prev => ({
                    ...prev,
                    robot_org_nick_name: e.target.value,
                  }))
                } else {
                  setRobotUpdateData(prev => ({
                    ...prev,
                    robot_org_nick_name: e.target.value,
                  }))
                  setError(prev => ({ ...prev, robot_org_nick_name: null }));
                }
              }}
            />
            {error?.robot_org_nick_name && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error?.robot_org_nick_name}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>AIR ID</SDMHeadingP>
            <input
              type="text"
              placeholder="0000-0000-0000"
              className="form-control"
              maxLength={14}
              defaultValue={robotUpdateData?.air_id}
              disabled
            />
          </div>

          <div style={{ marginTop: 15, marginLeft: '5px', cursor: 'pointer' }}>
            <input
              type="checkbox"
              className="form-check-input"
              id="default_auto_update"
              onChange={e =>
                setRobotUpdateData(prev => ({ ...prev, default_auto_update: e.target.checked }))
              }
              defaultChecked={robotUpdateData?.default_auto_update}
            />
            <label
              className="form-label form-check-label"
              htmlFor="default_auto_update"
              style={{ marginLeft: '8px' }}
            >
              Auto Update Lan IP / WAN IP
            </label>
          </div>

          <div style={{ marginTop: 5 }}>
            <SDMHeadingP>Lan IP</SDMHeadingP>
            <input
              type="text"
              className="form-control"
              placeholder="-"
              maxLength={30}
              defaultValue={robotUpdateData?.default_lan_ip}
              onChange={e =>
                {
                  if (e.target.value.length > 0 && !isIP(e.target.value)) {
                    setError(prev => ({
                      ...prev,
                      default_lan_ip: 'Invalid LAN IP address',
                    }));
                    setRobotUpdateData(prev => ({
                      ...prev,
                      default_lan_ip: e.target.value,
                    }));
                  } else {
                    setRobotUpdateData(prev => ({
                      ...prev,
                      default_lan_ip: e.target.value,
                    }));
                    setError(prev => ({ ...prev, default_lan_ip: null }));
                  }
                }
              }
            />
            {error.default_lan_ip && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.default_lan_ip}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>WAN IP</SDMHeadingP>
            <input
              type="text"
              placeholder="-"
              maxLength={30}
              className="form-control"
              defaultValue={robotUpdateData?.default_public_ip}
              onChange={e =>
                {
                  if (e.target.value.length > 0 && !isIP(e.target.value)) {
                    setError(prev => ({
                      ...prev,
                      default_public_ip: 'Invalid WAN IP address',
                    }));
                    setRobotUpdateData(prev => ({
                      ...prev,
                      default_public_ip: e.target.value,
                    }));
                  } else {
                    setRobotUpdateData(prev => ({
                      ...prev,
                      default_public_ip: e.target.value,
                    }));
                    setError(prev => ({ ...prev, default_public_ip: null }));
                  }
                }
              }
            />
            {error.default_public_ip && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.default_public_ip}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15, marginLeft: '5px', cursor: 'pointer' }}>
            <input
              type="checkbox"
              className="form-check-input"
              id="default_auto_update_stream_ip"
              onChange={e =>
                setRobotUpdateData(prev => ({ ...prev, default_auto_update_stream_ip: e.target.checked }))
              }
              defaultChecked={robotUpdateData?.default_auto_update_stream_ip}
            />
            <label
              className="form-label form-check-label"
              htmlFor="default_auto_update_stream_ip"
              style={{ marginLeft: '8px' }}
            >
              Auto Update Stream Destination IP
            </label>
          </div>

          <div style={{ marginTop: 5 }}>
            <SDMHeadingP>Stream Destination IP</SDMHeadingP>
            <input
              type="text"
              className="form-control"
              placeholder="-"
              maxLength={30}
              defaultValue={robotUpdateData?.default_stream_destination_ip}
              onChange={e =>
                {
                  if (e.target.value.length > 0 && !isIP(e.target.value)) {
                    setError(prev => ({
                      ...prev,
                      default_stream_destination_ip: 'Invalid LAN IP address',
                    }));
                    setRobotUpdateData(prev => ({
                      ...prev,
                      default_stream_destination_ip: e.target.value,
                    }));
                  } else {
                    setRobotUpdateData(prev => ({
                      ...prev,
                      default_stream_destination_ip: e.target.value,
                    }));
                    setError(prev => ({ ...prev, default_stream_destination_ip: null }));
                  }
                }
              }
            />
            {error.default_stream_destination_ip && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.default_stream_destination_ip}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>Internal Record Format</SDMHeadingP>
            <SBMSDiv3a style={{ columnGap: '4px' }}>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part1: e.target.value,
                  }));
                }}
              >
                {Rec_Format_Options1.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === internal_record?.part1}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part2: e.target.value,
                  }));
                }}
              >
                {Int_Rec_Format_Options2.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === internal_record?.part2}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part3: e.target.value,
                  }));
                }}
              >
                {int_Rec_Format_Options3.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === internal_record?.part3}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
            </SBMSDiv3a>
            {error.internal_record && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.internal_record}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>External Stream Format</SDMHeadingP>
            <SBMSDiv3a style={{ columnGap: '4px' }}>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_external_stream(prev => ({
                    ...prev,
                    part1: e.target.value,
                  }));
                }}
              >
                {Rec_Format_Options1.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === external_stream?.part1}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_external_stream(prev => ({
                    ...prev,
                    part2: e.target.value,
                  }));
                }}
              >
                {Rec_Format_Options2.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === external_stream?.part2}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_external_stream(prev => ({
                    ...prev,
                    part3: e.target.value,
                  }));
                }}
              >
                {ext_Rec_Format_Options3.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === external_stream?.part3}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
            </SBMSDiv3a>
            {error.external_stream && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.external_stream}
                </p>
              </div>
            )}
          </div>
        </RightNavSecDiv>
      </SideBarMainDiv>
      {/* =========================Footer=================================== */}
      <RightNavContainerFoot>
        <RNCFBtn>
          <TeamHeadBtnImgP onClick={closeSideBar}>Cancel</TeamHeadBtnImgP>
        </RNCFBtn>
        <RNCFBtn2>
          <TeamHeadBtnImgPa onClick={onConfirm}>Confirm</TeamHeadBtnImgPa>
        </RNCFBtn2>
      </RightNavContainerFoot>
    </RightNavContainer>
  );
};

export default EditOrgRobot;

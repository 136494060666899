import React from 'react';
import {
  NoCollaborator,
  RightNavContainer,
  RightNavContainerFoot,
  RightNavContainerX,
  RightNavContainerXPar,
  RNCFBtn,
  RNCFBtn2,
  SDMHeading,
  SideBarMainDiv,
  TeamHeadBtnImgP,
  TeamHeadBtnImgPa,
  PendingInvitationDiv,
} from '../styles';
import cross from '../../../Assets/images/cross.png';
import Loader from 'Components/Loader';
import { PendingInvitedUserListItem } from '../../../Components/MemberListItem';

const PendingInvitations = ({
  closeSideBar,
  loading,
  orgPendingInvitations,
}) => {
  return (
    <RightNavContainer>
      {/* ======================= Cross======================== */}
      <RightNavContainerX onClick={closeSideBar}>
        <RightNavContainerXPar src={cross} />
      </RightNavContainerX>
      <SideBarMainDiv>
        <SDMHeading>Organization Pending Invitations</SDMHeading>
      </SideBarMainDiv>
      {/* ======================= Users ======================== */}

      {loading ? (
        <Loader />
      ) : orgPendingInvitations?.length !== 0 ? (
        <PendingInvitationDiv>
          {orgPendingInvitations?.map((invitation, index) => (
            <PendingInvitedUserListItem
              key={invitation?.id}
              invitationInformation={invitation}
              teamPageAccess={true}
            />
          ))}
        </PendingInvitationDiv>
      ) : (
        <NoCollaborator>
          <p style={{ marginBottom: 0, color: '#667085' }}>
            No Pending Invitations
          </p>
        </NoCollaborator>
      )}

      {/* ======================= Footer ======================== */}
      <RightNavContainerFoot>
        <RNCFBtn>
          <TeamHeadBtnImgP onClick={closeSideBar}>Cancel</TeamHeadBtnImgP>
        </RNCFBtn>
        <RNCFBtn2>
          <TeamHeadBtnImgPa onClick={closeSideBar}>Done</TeamHeadBtnImgPa>
        </RNCFBtn2>
      </RightNavContainerFoot>
    </RightNavContainer>
  );
};

export default PendingInvitations;

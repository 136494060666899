import styled from 'styled-components';

export const LayoutContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  flex-direction: row;
  height: 100vh;
  width: 100%;
`;

export const ContentGround = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const SiteContainer = styled.div`
  height: calc(100vh - 80px);
  width: 100%;
`;
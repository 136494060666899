// Invite
export const API_INVITE_REQUEST = 'API_INVITE_REQUEST';
export const API_INVITE_REQUEST_SUCCESS = 'API_INVITE_REQUEST_SUCCESS';
export const API_INVITE_REQUEST_FAILED = 'API_INVITE_REQUEST_FAILED';

export const API_CHECK_INVITATION_REQUEST = 'API_CHECK_INVITATION_REQUEST';
export const API_CHECK_INVITATION_SUCCESS = 'API_CHECK_INVITATION_SUCCESS';
export const API_CHECK_INVITATION_FAILED = 'API_CHECK_INVITATION_FAILED';

export const API_GET_PENDING_INVITATIONS_REQUEST = 'API_GET_PENDING_INVITATIONS_REQUEST';
export const API_GET_PROJECT_PENDING_INVITATIONS_SUCCESS = 'API_GET_PROJECT_PENDING_INVITATIONS_SUCCESS';
export const API_GET_ORG_PENDING_INVITATIONS_SUCCESS = 'API_GET_ORG_PENDING_INVITATIONS_SUCCESS';
export const API_GET_PENDING_INVITATIONS_FAILED = 'API_GET_PENDING_INVITATIONS_FAILED';

export const API_RESEND_INVITATION_REQUEST = 'API_RESEND_INVITATION_REQUEST';
export const API_RESEND_INVITATION_SUCCESS = 'API_RESEND_INVITATION_SUCCESS';
export const API_RESEND_INVITATION_FAILED = 'API_RESEND_INVITATION_FAILED';

export const RESET_INVITE = 'RESET_INVITE';

import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import acrynom from '../../Utils/acrynom';

// Icons
import Mail from '../Icons/Mail';
import TrashCan from '../Icons/TrashCan';
import Message from '../Icons/Message';
import Meatball from '../Icons/Meatball';

// styles
import {
  ActionButton,
  ActionsDiv,
  AddCrewMemberBtn,
  CrewJobTitle,
  CrewName,
  MemberContainer,
  NameAvatarDiv,
  NameJobTitleDiv,
} from './styles';
import { Avatar } from '../CommonStyles';

// Config
import { ADD_COLLABORATORS } from 'Config/permissionConstant';
import * as types from 'Config/permissionConstant';

// Redux actions
import { apiResendInvitationRequest } from 'Redux/actions/invite';

const MemberListItem = ({
  name,
  job_title,
  avatar,
  children,
  draggable,
  onAvatarDragStart,
  email,
  id,
  ...props
}) => {
  return (
    <MemberContainer {...props}>
      <NameAvatarDiv>
        {draggable ? (
          <Avatar
            draggable
            onDrag={onAvatarDragStart}
            onSelect={onAvatarDragStart}
            bgImage={avatar}
          >
            {avatar ? null : acrynom(name)}
          </Avatar>
        ) : (
          <Avatar
            draggable={draggable}
            onDrag={onAvatarDragStart}
            onSelect={onAvatarDragStart}
            bgImage={avatar}
          >
            {avatar ? null : acrynom(name)}
          </Avatar>
        )}

        <NameJobTitleDiv>
          <CrewName>{name}</CrewName>
          <CrewJobTitle>{email?.toLowerCase()}</CrewJobTitle>
        </NameJobTitleDiv>
      </NameAvatarDiv>
      <ActionsDiv>{children}</ActionsDiv>
    </MemberContainer>
  );
};

export const CrewMemberListItem = ({
  id,
  name,
  job_title,
  avatar,
  phone,
  user_details,
  onMessage,
  onMail,
  onDragStart,
  handleMemberDelete,
  email,
  myPermission,
  ...props
}) => {
  const memoizedOnMessage = useCallback(() => {
    onMessage(id);
  }, [id, onMessage]);

  const memoizedOnMail = useCallback(() => {
    onMail(id);
  }, [id, onMail]);

  const onMemberDragStart = event => {
    onDragStart({ id, name, job_title, avatar }, event);
  };
  const [showContactOption, setShowContactOption] = useState(false);

  return (
    <MemberListItem
      name={name}
      email={email?.toLowerCase()}
      avatar={avatar}
      id={id}
      job_title={job_title}
      draggable={
        myPermission?.includes(types.EDIT_SETUP_SCREEN)
          ? onDragStart
            ? true
            : false
          : false
      }
      onAvatarDragStart={onMemberDragStart}
      {...props}
    >
      {/* {showContactOption && (
        <ContactMenuSection onMouseLeave={() => setShowContactOption(false)}>
          <ContactMenuDiv>
            <div>
              <ActionButton2>
                <ContactMenuAnc href={`sms://${phone}`}>
                  <Message />
                  <p> Message</p>
                </ContactMenuAnc>
              </ActionButton2>
            </div>
          </ContactMenuDiv>
          <ContactMenuDiv>
            <div>
              <ActionButton2 onClick={memoizedOnMail}>
                <ContactMenuAnc href={`mailto:${user_details?.email}`}>
                  <Mail />
                  <p> Email </p>
                </ContactMenuAnc>
              </ActionButton2>
            </div>
          </ContactMenuDiv>
        </ContactMenuSection>
      )}
      <div
        onClick={() => setShowContactOption(!showContactOption)}
        style={{ cursor: 'pointer' }}
      >
        <img
          src={Dropdown}
          style={{ width: 10, height: 10, marginRight: 15, opacity: 0.5 }}
        />
      </div> */}

      <div className="dropdown me-1">
        {myPermission?.includes(ADD_COLLABORATORS) ? (
          <ActionButton type="button" data-bs-toggle="dropdown">
            <Meatball />
          </ActionButton>
        ) : null}
        {myPermission?.includes(ADD_COLLABORATORS) && (
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuOffset">
            {/* <li>
            <span
              style={{ cursor: "pointer" }}
              className="dropdown-item"
              // onClick={onEditClicked}
            >
              <span className="dropdown-item-icon">
                <EditSquare />
              </span>
              <span className="dropdown-item-text-custom">Edit</span>
            </span>
          </li> */}

            <li>
              <a
                style={{ cursor: 'pointer' }}
                className="dropdown-item"
                href={`sms://${phone}`}
              >
                <span className="dropdown-item-icon">
                  <Message />
                </span>
                <span className="dropdown-item-text-custom">Message</span>
              </a>
              <a
                style={{ cursor: 'pointer' }}
                className="dropdown-item"
                onClick={() => memoizedOnMail()}
                href={`mailto:${user_details?.email}`}
              >
                <span className="dropdown-item-icon">
                  <Mail />
                </span>
                <span className="dropdown-item-text-custom">Mail</span>
              </a>
              <span
                style={{ cursor: 'pointer' }}
                className="dropdown-item"
                onClick={() => handleMemberDelete(id, name)}
              >
                <span className="dropdown-item-icon">
                  <TrashCan />
                </span>
                <span className="dropdown-item-text-custom">Delete</span>
              </span>
            </li>
          </ul>
        )}
      </div>
    </MemberListItem>
  );
};

export const SearchCrewMemberListItem = ({
  id,
  name,
  job_title,
  avatar,
  isAdded,
  onAdd,
  onDelete,
  forDelete,
  email,
}) => {
  const memoizedOnAdd = useCallback(() => {
    if (!isAdded) {
      const [first_name, last_name] = name.split(' ');
      onAdd({
        user: id,
        first_name,
        last_name,
        profile_image: avatar,
        email,
      });
    }
  }, [id, isAdded, onAdd]);

  return (
    <MemberListItem name={name} draggable={false} avatar={avatar} email={email}>
      {forDelete ? (
        <AddCrewMemberBtn onClick={onDelete}>Remove</AddCrewMemberBtn>
      ) : (
        <AddCrewMemberBtn disabled={isAdded} onClick={memoizedOnAdd}>
          {isAdded ? 'Added' : '+ Add'}
        </AddCrewMemberBtn>
      )}
    </MemberListItem>
  );
};

export const EmailCrewsListItem = ({
  name,
  avatar,
  email,
  emailAll,
  onEditEmailList,
}) => {
  const [checked, setChecked] = useState(emailAll);
  const onCheckChange = (checked) => {
    if (checked) {
      setChecked(true);
      onEditEmailList(email, true);
    } else {
      setChecked(false);
      onEditEmailList(email, false);
    }
  }
  useEffect(() => {
    if (emailAll === true) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [emailAll]);

  return (
    <MemberListItem name={name} draggable={false} avatar={avatar} email={email}>
      {email !== undefined && (
        <div style={{ marginTop: 15, marginLeft: '5px', cursor: 'pointer' }}>
          <input
            type="checkbox"
            className="form-check-input"
            id="addEmailList"
            onChange={e => {onCheckChange(e.target.checked)}}
            checked={checked}
            disabled={emailAll}
          />
        </div>
      )}
    </MemberListItem>
  );
};

export const PendingInvitedUserListItem = ({
  invitationInformation,
  ...props
}) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const resendInvitation = () => {
    let convertInvitationInformation = invitationInformation;
    if (convertInvitationInformation?.project === 'null') {
      convertInvitationInformation['project'] = null;
    }
    if (convertInvitationInformation?.organisation === 'null') {
      convertInvitationInformation['organisation'] = null;
    }
    dispatch(apiResendInvitationRequest(auth.token, convertInvitationInformation));
  };

  return (
    <MemberListItem
      name={'Pending Invitation'}
      email={invitationInformation?.email}
      avatar={null}
      draggable={false}
      onAvatarDragStart={() => {}}
    >
      <div className="dropdown me-1">
        {(props?.myPermission?.includes(ADD_COLLABORATORS) || props?.teamPageAccess) ? (
          <ActionButton type="button" data-bs-toggle="dropdown">
            <Meatball />
          </ActionButton>
        ) : null}
        {(props?.myPermission?.includes(ADD_COLLABORATORS) || props?.teamPageAccess) && (
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuOffset">
            <li>
              <a
                style={{ cursor: 'pointer' }}
                className="dropdown-item"
                href={`mailto:${invitationInformation?.email}`}
              >
                <span className="dropdown-item-icon">
                  <Mail />
                </span>
                <span className="dropdown-item-text-custom">Mail</span>
              </a>
            </li>
            <li>
              <span
                style={{ cursor: 'pointer' }}
                className="dropdown-item"
                onClick={resendInvitation}
              >
                <span className="dropdown-item-icon">
                  <Mail />
                </span>
                <span className="dropdown-item-text-custom">Resend Invitation</span>
              </span>
            </li>
          </ul>
        )}
      </div>
    </MemberListItem>
  );
};
import React, { useEffect, useState } from 'react';

import {
  AddCrewContentContainer,
  AddGearFormContainer,
  NavFooter,
  DoneButton,
} from './styles';
import { OutlinedButton } from '../IconButton/styles';
import { EmailCrewsListItem } from '../MemberListItem';

const EmailCrews = ({
  members,
  onCancel
}) => {
  const [selectedCrewEmails, setSelectedCrewEmails] = useState([]);
  const [emailAll, setEmailAll] = useState(false);

  const onClickEmailAll = (check) => {
    if (check === true) {
      setEmailAll(true);
      let tempEmailList = [];
      for (const member of members) {
        if (member?.user_details?.email) {
          tempEmailList.push(member?.user_details?.email.toLowerCase());
        }
      }
      setSelectedCrewEmails(tempEmailList);
    } else {
      setEmailAll(false);
      setSelectedCrewEmails([]);
    }
  }

  const onEditEmailList = (email, isAdded) => {
    if (isAdded === true) {
      let tempSelectedCrewEmails = selectedCrewEmails;
      tempSelectedCrewEmails.push(email);
      setSelectedCrewEmails(tempSelectedCrewEmails);
    } else {
      let tempSelectedCrewEmails = selectedCrewEmails.filter(userEmail => userEmail !== email);
      if (tempSelectedCrewEmails !== undefined) {
        setSelectedCrewEmails(tempSelectedCrewEmails);
      } else {
        setSelectedCrewEmails([]);
      }
    }
  };

  const onConfirm = () => {
    if (selectedCrewEmails && selectedCrewEmails.length !== 0) {
      let url = `mailto:${selectedCrewEmails.toString()}`;
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.click();
    }
    onCancel();
  };

  useEffect(() => {
    if (selectedCrewEmails === undefined || selectedCrewEmails.length === 0) {
      setEmailAll(false);
    }
  }, [selectedCrewEmails]);

  return (
    <AddCrewContentContainer>
      <AddGearFormContainer>
        <div style={{ marginTop: 15, marginLeft: 15, marginBottom: 15, cursor: 'pointer' }}>
          <input
            type="checkbox"
            className="form-check-input"
            id="emailAll"
            onChange={e => onClickEmailAll(e.target.checked)}
            checked={emailAll}
          />
          <label
            className="form-label form-check-label"
            htmlFor="emailAll"
            style={{ marginLeft: '8px' }}
          >
            Email All
          </label>
        </div>
        {members?.map((m, index) => (
            <EmailCrewsListItem
              name={m?.first_name ? `${m?.first_name} ${m?.last_name}` : m?.email}
              avatar={m?.profile_image}
              email={m?.user_details?.email.toLowerCase()}
              emailAll={emailAll}
              onEditEmailList={onEditEmailList}
            />
        ))}
      </AddGearFormContainer>

      <NavFooter>
        <OutlinedButton className="btn btn-sm" onClick={onCancel}>
          Cancel
        </OutlinedButton>
        <DoneButton
          className="btn btn-primary btn-sm"
          onClick={() => {onConfirm()}}
        >
          Email
        </DoneButton>
      </NavFooter>
    </AddCrewContentContainer>
  );
};

export default EmailCrews;


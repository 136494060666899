import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useInterval } from 'react-timing-hooks';
import { Button } from 'react-bootstrap';
import produce from 'immer';

// styles
import {SessionWrapper, ModalFooter} from './styles';

// Redux
import { channelServices } from 'Redux/services/channel';

// Components
import UserCard from '../../Components/UserCard/UserCard';
import InviteOthers from '../../Components/UserCard/InviteOthers';
import ButtonContained from '../../Components/UserCard/StyledButtonContained';
import ModalComponent from 'Components/Modal';
import Loader from 'Components/Loader';

// mui
import { Grid, Box, Modal, Typography } from '@mui/material';
import CallEndIcon from '@mui/icons-material/CallEnd';
import MicOffIcon from '@mui/icons-material/MicOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import { Mic } from '@mui/icons-material';
import MaterialButton from '@mui/material/Button';

// Layouts
import theme from '../../Layouts/SyncstageSessionLayout/theme';
import modalStyle from '../../Layouts/SyncstageSessionLayout/ModalStyle';
import { mountedStyle } from '../../Layouts/SyncstageSessionLayout/AnimationStyles';

// Syncstage
import { SyncStageSDKErrorCode } from '@opensesamemedia/syncstage';
import SyncStageConnectivityDelegate from './SyncStageConnectivityDelegate';
import SyncStageDesktopAgentDelegate from './SyncStageDesktopAgentDelegate';
import SyncStageDiscoveryDelegate from './SyncStageDiscoveryDelegate';
import SyncStageUserDelegate from './SyncStageUserDelegate';
import SyncStageWorkerWrapper from './syncStageWorkerWrapper';
import syncstage_image from '../../Assets/images/sync_stage.png';

const MEASUREMENTS_INTERVAL_MS = 5000;

const SyncstageSessionPage = () => {
  const { syncstageSessionCode } = useParams();

  // useRef
  const desktopAgentConnectedRef = useRef(false);
  const desktopAgentConnectedTimeoutRef = useRef(null);

  // useSelector
  const auth = useSelector(state => state.auth);

  // Other
  let userId = auth.user_information.id;
  let fullName = (auth.user_information?.first_name + " " + auth.user_information?.last_name).trim();
  if (fullName.length === 0){
    fullName = "UserId: " + String(userId);
  }

  // useState
  const [syncStageWorkerWrapper, setSyncStageWorkerWrapper] = useState(null);
  const [sessionData, setSessionData] = useState(null);

  const [desktopAgentModalShow, setDesktopAgentModalShow] = useState(true);
  const [desktopAgentProtocolHandler, setDesktopAgentProtocolHandler] = useState('');
  const [desktopAgentConnected, setDesktopAgentConnected] = useState(false);
  const [desktopAgentProvisioned, setDesktopAgentProvisioned] = useState(false);
  const [desktopAgentCompatible, setDesktopAgentCompatible] = useState(null);
  const [desktopAgentLatestCompatibleVersion, setDesktopAgentLatestCompatibleVersion] = useState(null);
  const [desktopAgentCompatibleModalClosed, setDesktopAgentCompatibleModalClosed] = useState(false);
  const [desktopAgentConnectedTimeoutId, setDesktopAgentConnectedTimeoutId] = useState(false);
  const [desktopAgentConnectedTimeout, setDesktopAgentConnectedTimeout] = useState(null);
  const [sessionLoadTime, setSessionLoadTime] = useState(new Date());
  const [appLoadTime, setAppLoadTime] = useState(new Date());

  // Transmitter
  const [muted, setMuted] = useState(false);
  const [connected, setConnected] = useState(true);
  const [measurements, setMeasurements] = useState({});

  // Receivers
  const [measurementsMap, setMeasurementsMap] = useState({});
  const [volumeMap, setVolumeMap] = useState({});
  const [connectedMap, setConnectedMap] = useState({});
  const [receiversMap, setReceiversMap] = useState({});

  const [leavingSession, setLeavingSession] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [settingsOpened, setSettingsOpened] = useState(false);
  const [successfullyJoinSession, setSuccessfullyJoinSession] = useState(false);

  const getDownloadLink = () => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf('Mac') !== -1) {
      // eslint-disable-next-line max-len
      return `https://public.sync-stage.com/agent/macos/prod/0.5.0/SyncStageAgent_0.5.0.dmg`;
    } else if (userAgent.indexOf('Win') !== -1) {
      // eslint-disable-next-line max-len
      return `https://public.sync-stage.com/agent/windows/prod/0.2.0/SyncStageAgent_0.2.0.exe`;
    } else {
      return null;
    }
  };
  const downloadLink = getDownloadLink();

  const onGetSyncstageSDKJWT = async () => {
    let jwt = await channelServices.apiGetSyncstageSDKJwt(auth.token);
    return jwt.slice(1, -1);
  };

  const onJwtExpired = async () => {
    const jwt = await channelServices.apiGetSyncstageSDKJwt(auth.token);
    return jwt.slice(1, -1);
  };

  const onLeaveSession = async () => {
    setLeavingSession(true);
    let errorCode = await syncStageWorkerWrapper.leave();
    if (errorCode === SyncStageSDKErrorCode.TOKEN_EXPIRED) {
      errorCode = await syncStageWorkerWrapper.leave();
    } else {
      console.log('Leave session failed. The errorCode is: ' + errorCode);
    }
    console.log('You left a session');
    localStorage.setItem("SyncstageSessionActive", "false");
    window.close();
  };

  const beforeUnloadHandler = async (event) => {
    localStorage.setItem("SyncstageSessionActive", "false");
  };

  const onSessionOut = useCallback(() => {
    console.log("You are out of session.")
  }, []);

  const onStartRecording = async () => {
    let errorCode = await syncStageWorkerWrapper.startRecording();
    if (errorCode === SyncStageSDKErrorCode.TOKEN_EXPIRED) {
      errorCode = await syncStageWorkerWrapper.startRecording();
    } else {
      console.log('Start Recording failed. The errorCode is: ' + errorCode);
    }
  };

  const onStopRecording = async () => {
    let errorCode = await syncStageWorkerWrapper.stopRecording();
    if (errorCode === SyncStageSDKErrorCode.TOKEN_EXPIRED) {
      errorCode = await syncStageWorkerWrapper.stopRecording();
    } else {
      console.log('Stop Recording failed. The errorCode is: ' + errorCode);
    }
  };

  const onMutedToggle = useCallback(async () => {
    const mutedState = !muted;
    setMuted(mutedState);
    const errorCode = await syncStageWorkerWrapper.toggleMicrophone(mutedState);
    if (errorCode !== SyncStageSDKErrorCode.OK) {
      setMuted(!mutedState);
    }
  }, [syncStageWorkerWrapper, muted]);

  const onUserJoined = useCallback(async (connection) => {
    console.log('onUserJoined');
    if (syncStageWorkerWrapper === null) {
      return;
    }
    // Not adding self connection and avoid duplicates
    if (
      (sessionData && sessionData.transmitter && sessionData.transmitter.identifier === connection.identifier) ||
      receiversMap[connection.identifier]
    ) {
      return;
    }

    setReceiversMap(
      produce((draft) => {
        draft[connection.identifier] = connection;
      }),
    );
    // Volume
    let [volumeValue, errorCode] = await syncStageWorkerWrapper.getReceiverVolume(connection.identifier);
    setVolumeMap(
      produce((draft) => {
        draft[connection.identifier] = volumeValue;
      }),
    );
  }, [syncStageWorkerWrapper]);

  const onUserLeft = useCallback((identifier) => {
    console.log('onUserLeft');

    setReceiversMap(
      produce((draft) => {
        delete draft[identifier];
      }),
    );

    setVolumeMap(
      produce((draft) => {
        delete draft[identifier];
      }),
    );
  }, []);

  const onUserMuted = useCallback((identifier) => {
    setReceiversMap(
      produce((draft) => {
        if (draft[identifier]) {
          draft[identifier].isMuted = true;
        }
      }),
    );
  }, []);

  const onRecordingStarted = useCallback(() => {
    console.log('onRecordingStarted');

    setIsRecording(true);
  }, []);

  const onRecordingStopped = useCallback(() => {
    console.log('onRecordingStopped');

    setIsRecording(false);
  }, []);

  const onUserUnmuted = useCallback((identifier) => {
    setReceiversMap(
      produce((draft) => {
        if (draft[identifier]) {
          draft[identifier].isMuted = false;
        }
      }),
    );
  }, []);

  const onTransmitterConnectivityChanged = useCallback((connected) => {
    setConnected(connected);
  }, []);

  const onReceiverConnectivityChanged = useCallback((identifier, connected) => {
    console.log(`onReceiverConnectivityChanged ${identifier}: connected ${connected}`);
    setConnectedMap(
      produce((draft) => {
        const connectedReceiver = draft[identifier];
        if (!connectedReceiver) {
          draft[identifier] = connected;
        }
        draft[identifier] = connected;
      }),
    );
  }, []);

  const buildViewSessionState = async (sessionData, setConnectedMap, syncStageWorkerWrapper, setVolumeMap) => {
    if (syncStageWorkerWrapper !== null && sessionData != null) {
      let errorCode;
      // initialize connection and volume, receivers map based on the sessionData state

      if (sessionData.receivers) {
        sessionData.receivers.forEach(async (receiver) => {
          setConnectedMap(
            produce((draft) => {
              const connectedReceiver = draft[receiver.identifier];
              if (!connectedReceiver) {
                draft[receiver.identifier] = undefined;
              }
            }),
          );

          // Volume
          let volumeValue;
          [volumeValue, errorCode] = await syncStageWorkerWrapper.getReceiverVolume(receiver.identifier);

          setVolumeMap(
            produce((draft) => {
              draft[receiver.identifier] = volumeValue;
            }),
          );

          setReceiversMap(
            produce((draft) => {
              draft[receiver.identifier] = receiver;
            }),
          );
        });
      }

      setIsRecording(sessionData.recordingStatus === 'started');
    }
  };

  const onDesktopAgentReconnected = useCallback(async () => {
    console.log(`onDesktopAgentReconnected in session at time: ${new Date().toISOString()}`);
    if (syncStageWorkerWrapper === null) {
      console.log('syncStageWorkerWrapper is null');
      return;
    }
    const [data, errorCode] = await syncStageWorkerWrapper.session();

    if (errorCode === SyncStageSDKErrorCode.API_UNAUTHORIZED) {
      onSessionOut();
    } else if (errorCode === SyncStageSDKErrorCode.NOT_IN_SESSION && new Date() - sessionLoadTime > 5000) {
      // timeout to allow rejoining session on refresh
      console.log('Not in session, navigating to join session, sessionLoadTime: ', sessionLoadTime);
      onSessionOut();
    } else if (errorCode === SyncStageSDKErrorCode.OK) {
      setSessionData(data);
    }

    await buildViewSessionState(data, setConnectedMap, syncStageWorkerWrapper, setVolumeMap);
  }, [syncStageWorkerWrapper]);

  const updateMeasurements = async () => {
    if (syncStageWorkerWrapper === null) {
      return;
    }
    let errorCode;
    let measurements;

    //Tx measurements
    [measurements, errorCode] = await syncStageWorkerWrapper.getTransmitterMeasurements();

    if (measurements) {
      setMeasurements({
        delay: measurements.networkDelayMs,
        jitter: measurements.networkJitterMs,
        quality: measurements.quality,
      });
    }
    //Rx measurements
    // console.log(`Receivers map: ${JSON.stringify(receiversMap)}`);
    Object.entries(receiversMap).forEach(async ([_, receiver]) => {
      let errorCode;
      let measurements;
      [measurements, errorCode] = await syncStageWorkerWrapper.getReceiverMeasurements(receiver.identifier);
      if (measurements) {
        setMeasurementsMap(
          produce((draft) => {
            draft[receiver.identifier] = {
              delay: measurements.networkDelayMs,
              jitter: measurements.networkJitterMs,
              quality: measurements.quality,
            };
          }),
        );
      }
    });
  };

  const clearDelegates = () => {
    if (syncStageWorkerWrapper === null) {
      return;
    }
    syncStageWorkerWrapper.userDelegate = new SyncStageUserDelegate(
      () => {},
      () => {},
      () => {},
      () => {},
      () => {},
      () => {},
      () => {},
    );
  };

  const setDesktopAgentConnectedTimeoutIfNotConnected = () => {
    console.log(`desktopAgentConnectedTimeoutRef.current: ${desktopAgentConnectedTimeoutRef.current}`);
    if (!desktopAgentConnectedRef.current && desktopAgentConnectedTimeoutRef.current === null) {
      console.log('Desktop not connected. Setting timeout');

      setDesktopAgentConnectedTimeout(true);
    } else {
      setDesktopAgentConnectedTimeout(false);
    }
  };

  const initializeSyncStage = async () => {
    if (syncStageWorkerWrapper !== null && desktopAgentConnected && desktopAgentConnectedTimeout === null) {
      console.log('initializeSyncStage useEffect syncStage init');
      setDesktopAgentCompatible(await syncStageWorkerWrapper.isCompatible());
      setDesktopAgentLatestCompatibleVersion(await syncStageWorkerWrapper.getLatestCompatibleDesktopAgentVersion());

      const syncStageProvisioned = await syncStageWorkerWrapper.checkProvisionedStatus();
      console.log(`SyncStage provisioned: ${syncStageProvisioned}`);

      const jwt = await onGetSyncstageSDKJWT();
      const provision = async () => {
        const initErrorCode = await syncStageWorkerWrapper.init(jwt);

        if (initErrorCode == SyncStageSDKErrorCode.OK) {
          // if (location.pathname === `${PathEnum.LOADING}` && !inSession) {
          //   console.log(`In session: ${inSession}`);
          //   if (nickname) {
          //     navigate(PathEnum.SESSIONS_JOIN);
          //   } else {
          //     navigate(PathEnum.SESSION_NICKNAME);
          //   }
          // }
          console.log('Success init SyncStage, correct jwt');
        } else {
          console.log('Could not init SyncStage, invalid jwt');
          // signOut();
          return undefined;
        }
      };
      if (syncStageProvisioned) {
        const updateErrorCode = await syncStageWorkerWrapper.updateToken(jwt);
        if (updateErrorCode == SyncStageSDKErrorCode.OK) {
          console.log('Successfully update SyncStage token');
          return undefined;
        } else {
          console.log('Could not update SyncStage token');
          await provision();
          return undefined;
        }
      } else {
        await provision();
        return undefined;
      }
    }
    else if (syncStageWorkerWrapper !== null && desktopAgentConnectedTimeout) {
      let currentTime = new Date();

      // Calculate the time difference in seconds
      let timeDifference = (currentTime - appLoadTime) / 1000;

      // If less than 10 seconds have elapsed, navigate to setup screen
      if (timeDifference < 10) {
        console.log('initializeSyncStage useEffect desktopAgentConnectedTimeout');
        console.log('Desktop connected timeout, going to setup screen');
        // navigate(PathEnum.SETUP);
        return undefined;
      }
    }
  };

  const onDesktopAgentAquired = () => {
    console.log('SyncStage Desktop Agent Aquired or in use');
  };

  const onDesktopAgentReleased = () => {
    console.log('SyncStage Desktop Agent Released');
  };

  const onDesktopAgentConnected = async () => {
    console.log('onDesktopAgentConnected')
    setDesktopAgentConnected(true);
    setDesktopAgentModalShow(false)
    clearTimeout(desktopAgentConnectedTimeoutId);
    setDesktopAgentConnectedTimeout(null);
  };

  const onDesktopAgentDisconnected = () => {
    setDesktopAgentConnected(false);
  };

  const onDesktopAgentDeprovisioned = async () => {
    setDesktopAgentProvisioned(false);
    await initializeSyncStage();
  };

  const onDesktopAgentProvisioned = () => {
    setDesktopAgentProvisioned(true);
  };

  const onServerSelected = (serverSelected) => {
    console.log(`Selected Server: ${serverSelected.zoneName}`);
  };

  const { start, pause } = useInterval(updateMeasurements, MEASUREMENTS_INTERVAL_MS, { startOnMount: true });

  useEffect(() => {
    initializeSyncStage();
  }, [syncStageWorkerWrapper, desktopAgentConnected, desktopAgentConnectedTimeout]);

  useEffect(() => {
    setAppLoadTime(new Date());
    localStorage.setItem("SyncstageSessionActive", "true");
    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, []);

  useEffect(() => {
    async function executeAsync() {
      if (syncStageWorkerWrapper !== null) {
        console.log('Updating delegates');
        syncStageWorkerWrapper.userDelegate = new SyncStageUserDelegate(
          onUserJoined,
          onUserLeft,
          onUserMuted,
          onUserUnmuted,
          onRecordingStarted,
          onRecordingStopped,
          onSessionOut,
        );
        syncStageWorkerWrapper.connectivityDelegate = new SyncStageConnectivityDelegate(
          onTransmitterConnectivityChanged,
          onReceiverConnectivityChanged,
        );
        syncStageWorkerWrapper.updateOnDesktopAgentReconnected(onDesktopAgentReconnected.bind(this));

        const [mutedState, errorCode] = await syncStageWorkerWrapper.isMicrophoneMuted();
        if (errorCode === SyncStageSDKErrorCode.OK) {
          setMuted(mutedState);
        }
        await buildViewSessionState(sessionData, setConnectedMap, syncStageWorkerWrapper, setVolumeMap);
      }
    }
    executeAsync();
    return () => {
      if (syncStageWorkerWrapper !== null) {
        syncStageWorkerWrapper.userDelegate = null;
        syncStageWorkerWrapper.connectivityDelegate = null;
      }
    };

  }, [syncStageWorkerWrapper, sessionData]);

  useEffect(() => {
    const initWorker = async () => {
      const syncStageDiscoveryDelegate = new SyncStageDiscoveryDelegate(
        (zones) => {
          console.log(JSON.stringify(zones));
        },
        (results) => {
          console.log(JSON.stringify(results));
        },
        onServerSelected,
      );

      const desktopAgentDelegate = new SyncStageDesktopAgentDelegate(
        onDesktopAgentAquired,
        onDesktopAgentReleased,
        onDesktopAgentConnected,
        onDesktopAgentDisconnected,
        onDesktopAgentDeprovisioned,
        onDesktopAgentProvisioned,
      );

      const ssWorker = new SyncStageWorkerWrapper(null, null, syncStageDiscoveryDelegate, desktopAgentDelegate, onJwtExpired);
      const jwt = await onGetSyncstageSDKJWT();
      ssWorker.updateToken(jwt);
      setDesktopAgentProtocolHandler(await ssWorker.getDesktopAgentProtocolHandler());
      setSyncStageWorkerWrapper(ssWorker);
    };

    if (!syncStageWorkerWrapper) {
      initWorker();
    }
  }, []);

  useEffect(() => {
    //on component unmount.
    return () => {
      if (syncStageWorkerWrapper !== null) {
        pause(); // stop measurements
      }
    };
  }, []);

  useEffect(() => {
    desktopAgentConnectedRef.current = desktopAgentConnected;
  }, [desktopAgentConnected]);

  useEffect(() => {
    desktopAgentConnectedTimeoutRef.current = desktopAgentConnectedTimeout;
  }, [desktopAgentConnectedTimeoutRef]);

  useEffect(() => {
    if (!desktopAgentConnectedTimeoutId) {
      console.log('Desktop timeout useEffect');

      const timeoutId = setTimeout(() => {
        setDesktopAgentConnectedTimeoutIfNotConnected();
      }, 5000);

      setDesktopAgentConnectedTimeoutId(timeoutId);
    }
    return () => clearTimeout(desktopAgentConnectedTimeoutId);
  }, []);

  useEffect(() => {
    const initializeSession = async () => {
      if (syncStageWorkerWrapper !== null && desktopAgentProvisioned) {
        console.log(`syncstageSessionCode ${syncstageSessionCode} userId ${userId} fullName ${fullName}`)
        const [data, errorCode] = await syncStageWorkerWrapper.join(
          syncstageSessionCode,
          String(userId),
          fullName,
          null,
          null,
        );

        if (errorCode === SyncStageSDKErrorCode.OK) {
          console.log(`sessionData: ${JSON.stringify(data)}`)
          setSessionData(data);
          setSuccessfullyJoinSession(true);
          return undefined;
        }
      }
    };

    initializeSession();
  }, [syncStageWorkerWrapper, desktopAgentProvisioned]);

  useEffect(() => {
    // React will run it when it is time to clean up:
    return () => {
      setSessionLoadTime(new Date());
      setSessionData(null);
      clearDelegates();
    };
  }, []); // Empty array ensures this runs on mount and unmount only

  const desktopAgentModalFooter = (
    <ModalFooter>
      <Button
        href={desktopAgentProtocolHandler}
      >
        Open Desktop Agent
      </Button>
      <Button
        href={downloadLink}
      >
        Install Desktop Agent
      </Button>
    </ModalFooter>
  );

  return (
      <div style={mountedStyle}>
        <SessionWrapper>
          {(leavingSession) ? (
            <>
              <Grid>
                <p id="header">Leaving the Syncstage session, please wait...</p>
              </Grid>
              <div id="loader">
                <Loader />
              </div>
            </>
          ) : (!successfullyJoinSession) ? (
            <>
              <Grid>
                <p id="header">Setting up the Syncstage session, please wait...</p>
              </Grid>
              <div id="loader">
                <Loader />
              </div>
            </>
          ) : (
            <>
              <Grid item container flexDirection="row" rowGap={2} columnGap={8} alignItems="flex-start" style={{ marginBottom: '90px' }}>
              {!!sessionData && !!sessionData.transmitter && (
                <UserCard transmitter {...sessionData.transmitter} connected={connected} {...measurements} />
              )}
              {Object.entries(receiversMap).map(([identifier, connection]) => (
                <UserCard
                  {...connection}
                  {...measurementsMap[identifier]}
                  connected={connectedMap[identifier]}
                  volume={volumeMap[identifier]}
                  onVolumeChanged={async (volume) => {
                    setVolumeMap({
                      ...volumeMap,
                      [identifier]: volume,
                    });
                  }}
                  onVolumeChangeCommited={async (volume) => {
                    syncStageWorkerWrapper.changeReceiverVolume(identifier, volume);
                    setVolumeMap({
                      ...volumeMap,
                      [identifier]: volume,
                    });
                  }}
                />
              ))}
              <InviteOthers sessionCode={syncstageSessionCode} />
              </Grid>
              <div id="footer">
                <Grid container direction="column" justifyContent="center" alignItems="center" style={{ margin: 0, padding: 0 }}>
                  {isRecording ? (
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      style={{ margin: 0, paddingTop: '8px', paddingBottom: '4px', height: '32px', backgroundColor: theme.recordingBackground }}
                      spacing={2}
                    >
                      <span id="redcircle"></span>
                      <p
                        style={{
                          fontSize: '24px',
                        }}
                      >
                        Recording
                      </p>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ margin: 0, paddingTop: '4px' }}
                    spacing={2}
                  >
                    <Grid item style={{ paddingRight: '32px' }}>
                      <MaterialButton
                        style={{ color: theme.onSurfaceVariant }}
                        onClick={async () => {
                          onLeaveSession();
                        }}>
                        <CallEndIcon />
                      </MaterialButton>
                    </Grid>
                    <Grid item style={{ paddingRight: '32px' }}>
                      <MaterialButton style={{ color: theme.onSurfaceVariant }} onClick={onMutedToggle}>
                        {muted ? <MicOffIcon /> : <Mic />}
                      </MaterialButton>
                    </Grid>
                    <Grid item>
                      <MaterialButton style={{ color: theme.onSurfaceVariant }} onClick={() => setSettingsOpened(true)}>
                        <MoreVertIcon />
                      </MaterialButton>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </>
          )}
        </SessionWrapper>
        <Modal open={settingsOpened} onClose={() => setSettingsOpened(false)}>
          <Box sx={modalStyle}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="center">
              <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ padding: '16px' }}>
                <Typography variant="h4" component="h4" style={{ color: theme.onSurfaceVariant }}>
                  Settings
                </Typography>
                <MaterialButton style={{ color: theme.onSurfaceVariant }} onClick={() => setSettingsOpened(false)}>
                  <CloseIcon />
                </MaterialButton>
              </Grid>
              <Grid item style={{ width: '100%', paddingLeft: 32, color: theme.onSurfaceVariant}}>
                <Typography variant="h5" component="h5">
                  Recording
                </Typography>
              </Grid>

              <Grid item>
                <ButtonContained
                  onClick={() => {
                    if (isRecording) {
                      onStopRecording();
                    } else {
                      onStartRecording();
                    }
                  }}
                >
                  {isRecording ? 'Stop recording' : 'Start recording'}
                </ButtonContained>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          open={desktopAgentCompatible === false && !desktopAgentCompatibleModalClosed}
          onClose={() => {
            setDesktopAgentCompatibleModalClosed(true);
            window.close();
          }}
        >
          <Box sx={modalStyle}>
            <Typography variant="h6" component="h2">
              SyncStage Desktop Agent is incompatible with the current SyncStage SDK version.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              We noticed your Desktop Agent is currently incompatible with the latest web application version. To ensure a seamless
              experience, please update your Desktop Agent. <br />
              <br />
              {desktopAgentLatestCompatibleVersion ? (
                <a href={getDownloadLink()} target="_blank">
                  Click here to download the latest Desktop Agent version
                </a>
              ) : (
                'We could not find matching Desktop Agent version for your OS. Please contact support.'
              )}
            </Typography>
          </Box>
        </Modal>

        <ModalComponent
          show={desktopAgentConnected === false && desktopAgentModalShow}
          onHide={() => {}} // User must launch agent before continue.
          icon={syncstage_image}
          title={'Launch SyncStage Desktop Agent'}
          body={'To have a low latency conversation you need to have the SyncStage Desktop Agent running.'}
          footer={desktopAgentModalFooter}
        />
      </div>
  );
};

export default SyncstageSessionPage;

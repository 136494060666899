// API Project Comms
export const API_ADD_COMMS_REQUEST = 'API_ADD_COMMS_REQUEST';
export const API_ADD_COMMS_SUCCESS = 'API_ADD_COMMS_SUCCESS';
export const API_ADD_COMMS_FAILED = 'API_ADD_COMMS_FAILED';

// API Get Project Comms By ID
export const API_GET_COMMS_BY_ID_REQUEST = 'API_GET_COMMS_BY_ID_REQUEST';
export const API_GET_COMMS_BY_ID_SUCCESS = 'API_GET_COMMS_BY_ID_SUCCESS';
export const API_GET_COMMS_BY_ID_FAILED = 'API_GET_COMMS_BY_ID_FAILED';

// API Get Project Comms
export const API_GET_COMMS_REQUEST = 'API_GET_COMMS_REQUEST';
export const API_GET_COMMS_SUCCESS = 'API_GET_COMMS_SUCCESS';
export const API_GET_COMMS_FAILED = 'API_GET_COMMS_FAILED';

// Delete Project Comms
export const API_DELETE_COMMS_REQUEST = 'API_DELETE_COMMS_REQUEST';
export const API_DELETE_COMMS_SUCCESS = 'API_DELETE_COMMS_SUCCESS';
export const API_DELETE_COMMS_FAILED = 'API_DELETE_COMMS_FAILED';

// Patch Project Comms
export const API_PATCH_COMMS_REQUEST = 'API_PATCH_COMMS_REQUEST';
export const API_PATCH_COMMS_SUCCESS = 'API_PATCH_COMMS_SUCCESS';
export const API_PATCH_COMMS_FAILED = 'API_PATCH_COMMS_FAILED';

// reset
export const RESET_TO_INITIAL_CHANNEL_STATE = 'RESET_TO_INITIAL_CHANNEL_STATE';

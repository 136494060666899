import React from 'react';
import {
  ButtonDateDiv,
  NavContainer,
  StatusLegendDiv,
  LegendDescription,
  RobotStatusCircle,
  StatusContainer,
} from './styles';
import BreadCrumbs from './Components/BreadCrumbs';
import {ROBOT_STATUS_COLORS} from 'Config/colors';

const LayoutTopNav = ({
  links,
  projectName,
}) => {
  const DescritpionContainer = ({color, description}) => (
    <StatusContainer>
      <RobotStatusCircle color={color}/>
      <LegendDescription> &nbsp; {description}</LegendDescription>
    </StatusContainer>
  )
  return (
    <NavContainer>
      <BreadCrumbs links={links} projectName={projectName} />
      <ButtonDateDiv>
        <StatusLegendDiv>
          <DescritpionContainer color={ROBOT_STATUS_COLORS.GREY} description={'Offline'}/>
          <DescritpionContainer color={ROBOT_STATUS_COLORS.GREEN} description={'Robot is available for connection'}/>
          <DescritpionContainer color={ROBOT_STATUS_COLORS.GOLD} description={'Available, but another user connected'}/>
          <DescritpionContainer color={ROBOT_STATUS_COLORS.RED} description={'Not authorized for control'}/>
          <DescritpionContainer color={ROBOT_STATUS_COLORS.BLACK} description={'Robot not subscribed'}/>
        </StatusLegendDiv>
      </ButtonDateDiv>
    </NavContainer>
  );
};

export default LayoutTopNav;

import React from 'react';
import { TeamSearchMainDiv } from '../styles';
import SearchFilters from './SearchFilters';
import SearchHeading from './SearchHeading';
import GearsList from './GearsList';

const GearsInfoSection = ({
  team,
  allOrgGears,
  onChangeAll,
  selectAll,
  loading,
  postsPerPage,
  currentPage,
  setCurrentPage,
  filterState,
  editGear,
  dispatch,
  ACTION_TYPES,
  handleNameFilter,
  handleDeviceFilter,
  handleCategoryFilter,
  filterAssets,
  setFilterAssets,
}) => {
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const prePage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    let totalPages = Math.ceil(allOrgGears.length / postsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <TeamSearchMainDiv>
        <SearchFilters
          dispatch={dispatch}
          filterState={filterState}
          ACTION_TYPES={ACTION_TYPES}
          handleNameFilter={handleNameFilter}
          handleDeviceFilter={handleDeviceFilter}
          handleCategoryFilter={handleCategoryFilter}
        />
        <SearchHeading
          onChangeAll={onChangeAll}
          selectAll={selectAll}
          filterAssets={filterAssets}
          setFilterAssets={setFilterAssets}
        />
        <GearsList
          indexOfFirstPost={indexOfFirstPost}
          indexOfLastPost={indexOfLastPost}
          editGear={editGear}
          filterState={filterState}
          allOrgGears={allOrgGears}
          name_filter={filterState.name_filter}
          device_filter={filterState.device_filter}
          category_filter={filterState.category_filter}
          team={team}
          loading={loading}
          filterAssets={filterAssets}
          postsPerPage={postsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          prePage={prePage}
          nextPage={nextPage}
        />
      </TeamSearchMainDiv>
    </>
  );
};

export default GearsInfoSection;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Stage, Layer, Image, Rect } from 'react-konva';
import { Portal } from 'react-konva-utils';
import { toast } from 'react-toastify';
import useImage from 'use-image';
import queryString from 'query-string';

import {
  MainContentContainer,
  ZoomToolContainer,
  BottomUsersNavContainer2,
} from './styles';
import LaunchPadLayout from '../../../Layouts/LaunchPadLayout';

import BottomUsersNav from '../../../Components/BottomUsersNav';
import ZoomTool from '../../../Components/ZoomTool';
import BottomInstanceNav from '../../../Components/BottomInstanceNav';
import Loader from 'Components/Loader';
import RobotCanvasItem from '../../../Components/Konva/RobotCanvasItem';

import { apiGetAllShootRobotsRequest, apiGetRobotLiveStatusRequest, apiGetAllShootInstanceRequest } from '../../../Redux/actions/gear';
import { apiGetOnlineMembers } from 'Redux/actions/user_information';


function parseCameraConnectionObject(camera_connections) {
  const membersInCanvas = {};
  const gearsInCanvas = {};
  const connections = [];
  const air_ids = [];
  camera_connections.forEach(c => {
    air_ids.push(c?.camera?.air_id);
    if (c.camera_id) {
      gearsInCanvas[c.camera_id] = {
        x: c.camera_x,
        y: c.camera_y,
        camera_image_x: c.camera_image_x,
        camera_image_y: c.camera_image_x,
        rotation: c.camera_rotation,
        camera_placeholder: c.camera_placeholder,
        stream_destination_ip: c.stream_destination_ip,
        data: c.camera,
      };
    }
    if (c.camera_operator_id) {
      membersInCanvas[c.camera_operator_id] = {
        x: c.camera_operator_x,
        y: c.camera_operator_y,
        data: c.camera_operator,
      };
    }
    if (c.camera_id && Array.isArray(c.camera_operator)) {
      const member_ids_parsed = [];
      c.camera_operator.forEach(c => member_ids_parsed.push(c.id));
      connections.push({
        member: member_ids_parsed,
        gear: c.camera_id,
      });
    }
  });

  return {
    membersInCanvas,
    gearsInCanvas,
    connections,
    air_ids,
  };
}

const Main = ({ state, project_id }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const ref = useRef();
  const stageRef = React.useRef();
  const [image] = useImage(state.shoot?.background_image);

  // useSelector
  const auth = useSelector(state => state.auth);
  const assets = useSelector(state => state.assets);
  const sideNavState = useSelector(state => state.sidebar);
  const gear = useSelector(state => state.gear);
  const userInformation = useSelector(state => state.userInformation);

  // Other
  const params = queryString.parse(window.location.search);
  const INTERVAL_MS = 30000;

  // useState
  const [scale, setScale] = useState();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [selectedRobotId, setSelectedRobotId] = useState(null);
  const [isZooming, setIsZooming] = useState(false);
  const [shootInstances, setShootInstances] = useState([]);
  const [robotLiveStatus, setRobotLiveStatus] = useState({});
  const [shootRobots, setShootRobots] = useState({});
  const [robotAirIds, setRobotAirIds] = useState([]);

  const links = [
    {
      title: 'Launch Pad',
      href: `/project/launchpad/${project_id}`,
    },
  ];
  const members = sideNavState.selectedProject?.members;
  var lastCenter = null;
  var lastDist = 0;

  const setStageDims = () => {
    if (stageRef) {
      if (image) {
        const stageCenter = {
          x: ref.current?.offsetWidth / 2,
          y: ref.current?.offsetHeight / 2,
        };
        const backgroundImageCenter = {
          x: (image.width * scale) / 2,
          y: (image.height * scale) / 2,
        };

        const centerPoint = {
          x: stageCenter.x - backgroundImageCenter.x,
          y: stageCenter.y - backgroundImageCenter.y,
        };

        setPosition(centerPoint);
      }

      stageRef.current?.width(ref.current.offsetWidth);
      stageRef.current?.height(ref.current.offsetHeight);
    }
  };

  const handleWheel = event => {
    event.evt.preventDefault();
    const currentStageRef = stageRef.current;

    if (currentStageRef) {
      const stage = currentStageRef.getStage();

      if (event.evt.ctrlKey) {
        const oldScale = stage.scaleX();

        const mousePointTo = {
          x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
          y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
        };

        const unboundedNewScale = oldScale - event.evt.deltaY * 0.01;
        let newScale = unboundedNewScale;
        if (unboundedNewScale < 0.1) {
          newScale = 0.1;
        } else if (unboundedNewScale > 10.0) {
          newScale = 10.0;
        }

        const newPosition = {
          x:
            -(mousePointTo.x - stage.getPointerPosition().x / newScale) *
            newScale,
          y:
            -(mousePointTo.y - stage.getPointerPosition().y / newScale) *
            newScale,
        };

        setScale(newScale);
        setPosition(newPosition);
      } else {
        const dragDistanceScale = 0.75;
        const newPosition = {
          x: position.x - dragDistanceScale * event.evt.deltaX,
          y: position.y - dragDistanceScale * event.evt.deltaY,
        };

        setPosition(newPosition);
      }
    }
  };

  const handleZoomIn = (isScale = true) => {
    const currentStageRef = stageRef.current;

    if (currentStageRef) {
      const stage = currentStageRef.getStage();
      const oldScale = stage.scaleX();
      const mousePointTo = {
        x: ref.current.offsetWidth / 2 / oldScale - stage.x() / oldScale,
        y: ref.current.offsetHeight / 2 / oldScale - stage.y() / oldScale,
      };

      const unboundedNewScale = oldScale;
      let newScale = unboundedNewScale;
      if (isScale)
        newScale = Math.min(10.0, Math.ceil(oldScale * 1.1 * 10) / 10);

      const newPosition = {
        x:
          -(mousePointTo.x - ref.current.offsetWidth / 2 / newScale) * newScale,
        y:
          -(mousePointTo.y - ref.current.offsetHeight / 2 / newScale) *
          newScale,
      };

      setScale(newScale);
      setPosition(newPosition);
    }
  };

  const handleZoomOut = (isScale = true) => {
    const currentStageRef = stageRef.current;

    if (currentStageRef) {
      const stage = currentStageRef.getStage();
      const oldScale = stage.scaleX();
      const mousePointTo = {
        x: ref.current.offsetWidth / 2 / oldScale - stage.x() / oldScale,
        y: ref.current.offsetHeight / 2 / oldScale - stage.y() / oldScale,
      };

      const unboundedNewScale = oldScale;
      let newScale = unboundedNewScale;
      if (isScale)
        newScale = Math.max(0.1, Math.floor(oldScale * 0.9 * 10) / 10);

      const newPosition = {
        x:
          -(mousePointTo.x - ref.current.offsetWidth / 2 / newScale) * newScale,
        y:
          -(mousePointTo.y - ref.current.offsetHeight / 2 / newScale) *
          newScale,
      };

      setScale(newScale);
      setPosition(newPosition);
    }
    // setStageDims();
    // setScale(prevValue => Math.max(0.1, Math.floor(prevValue * 0.9 * 10) / 10));
    // // setIsCanvasDirty(true);
    // setStageDims();
  };

  const handleCentered = () => {
    setStageDims();
  };

  const handleFocusOut = () => {
    setSelectedRobotId(null);
    history.push(window.location.pathname);
  };

  const getDistance = (p1, p2) => {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  };

  const getCenter = (p1, p2) => {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  };

  const handleMultiTouch = e => {
    e.evt.preventDefault();

    var touch1 = e.evt.touches[0];
    var touch2 = e.evt.touches[1];
    const stage = e.target.getStage();

    if (touch1 && touch2) {
      setIsZooming(true);

      var p1 = {
        x: touch1.clientX,
        y: touch1.clientY,
      };
      var p2 = {
        x: touch2.clientX,
        y: touch2.clientY,
      };

      if (!lastCenter) {
        lastCenter = getCenter(p1, p2);
        return;
      }
      let newCenter = getCenter(p1, p2);

      let dist = getDistance(p1, p2);

      if (!lastDist) {
        lastDist = dist;
      }

      // local coordinates of center point
      let pointTo = {
        x: (newCenter.x - stage.x()) / stage.scaleX(),
        y: (newCenter.y - stage.y()) / stage.scaleX(),
      };

      let scale = stage.scaleX() * (dist / lastDist);
      // setScale(scale);
      stage.scaleX(scale);
      stage.scaleY(scale);

      // calculate new position of the stage
      let dx = newCenter.x - lastCenter.x;
      let dy = newCenter.y - lastCenter.y;

      let newPos = {
        x: newCenter.x - pointTo.x * scale + dx,
        y: newCenter.y - pointTo.y * scale + dy,
      };

      stage.position(newPos);
      stage.batchDraw();

      lastDist = dist;
      lastCenter = newCenter;
    }
  };

  const multiTouchEnd = () => {
    lastCenter = null;
    lastDist = 0;
    setIsZooming(false);
  };

  const handleDragStart = e => {
    const stage = e.target.getStage();

    if (isZooming) {
      stage.stopDrag();
    }
    handleZoomIn(false);
    handleZoomOut(false);
  };

  const currentUserRole = () => {
    return userInformation?.data?.organisation_data?.find(item => item.organisation.id === userInformation?.selectedOrganisation)?.role;
  };

  const onDblClick = (robotId, gearData, type='ROBOT') => {
    if (type === 'instance') {
      setSelectedRobotId(null);
      window.open(`dcv://${gearData?.public_ip}`);
    } else {
      setSelectedRobotId(robotId);
      const ip = (gearData?.public_ip !== "" && gearData?.public_ip !== undefined)
                ? gearData.public_ip
                : (gearData?.lan_ip !== "" && gearData?.lan_ip !== undefined)
                  ? gearData.lan_ip
                  : "";
      const robotPlaceholder = gearData?.robot_placeholder;
      const robotName = 'Robot' + robotPlaceholder + '-' + gearData?.shoot_robot_nick_name.trim().replace(/\s/g, "-");

      if (ip === '') {
        toast.error('The ip address of this robot is not set, please set it up in the Shoot Setup page.');
        return;
      } else if (robotPlaceholder === '') {
        toast.error('The placeholder of this robot is not set, please set it up in the Shoot Setup page.');
        return;
      }

      const internalRecordFormat = (gearData?.internal_record_format || '')?.split('/');
      const recordingResolution = internalRecordFormat[0] === '1080'
                                    ? '1920x1080'
                                    : internalRecordFormat[0] === '4k'
                                      ? '4K'
                                      : '';
      const recordingFPS = internalRecordFormat[1] || '';
      const encoder = internalRecordFormat[2] || '';

      const [streamingIP, streamingPort] = (gearData?.stream_destination_ip || '').split(":");
      const streamings = gearData?.external_stream_format?.split('/');
      const streamingResolution = streamings[0] || '';
      const streamingFPS = streamings[1] || '';
      const streamingCodec = streamings[2] || '';

      let syncstageProjectTag;
      if (window.location.href.includes("airproduction.app")) {
        syncstageProjectTag = `PROD-${project_id}`;
      } else {
        syncstageProjectTag = `DEV-${project_id}`;
      }

      const url = `airapp://ip=${ip}&cameraInstance=${robotPlaceholder}&cameraName=${robotName}&recordingResolution=${recordingResolution}&recordingFPS=${recordingFPS}&encoder=${encoder}&streamingIP=${streamingIP}&streamingPort=${streamingPort}&streamingResolution=${streamingResolution}&streamingFPS=${streamingFPS}&streamingCodec=${streamingCodec}&PROJECT=${syncstageProjectTag}`;
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('target', '_blank');
      a.click();
    }
  };

  const getRobotOperatorData = (robotId) => {
    let operatorData = []; // robot operators with online status
    let connectedOperators = shootRobots[robotId].operators;
    let onlineMembers = userInformation?.onlineMembers
    if (connectedOperators.length) {
      if (members) {
        connectedOperators.forEach(cMember => {
          members.forEach(member => {
            if (member.user.toString() === cMember.toString()) {
              member['isOnline'] = false;
              const getStatus = onlineMembers?.find(item => item[cMember]);
              if (getStatus)
                member['isOnline'] = getStatus[Object.keys(getStatus)[0]];
              operatorData.push(member);
              return;
            }
          });
        });
        operatorData.sort((a, b) => (a.isOnline > b.isOnline ? 1 : -1));
      }
    }
    return operatorData;
  }

  const convertStates = (inputStates, selectedKey) => {
    let result = {}
    for (const inputState of inputStates) {
      result[inputState[selectedKey]] = inputState;
    }
    return result;
  };

  const convertRobotStates = (inputStates) => {
    let shootRobotObjs = {};
    let tempRobotAirIds = [];
    inputStates.forEach((state) => {
      shootRobotObjs[state.id] = state;
      tempRobotAirIds.push(state.air_id);
    });
    return [shootRobotObjs, tempRobotAirIds];
  };

  useEffect(() => {
    let onlineMembersTimer;
    if (project_id) {
      dispatch(apiGetOnlineMembers(project_id, auth.token));
      onlineMembersTimer = setInterval(
        () => dispatch(apiGetOnlineMembers(project_id, auth.token)),
        1000 * 30,
      );
    }

    return () => {
      onlineMembersTimer && clearInterval(onlineMembersTimer);
    };
  }, [project_id]);

  useEffect(() => {
    if (state?.shoot?.scale) {
      setScale(state.shoot?.scale);
    }
  }, [state.shoot]);

  useEffect(() => {
    window.addEventListener('resize', setStageDims);

    dispatch(apiGetOnlineMembers(project_id, auth.token));
    return () => {
      window.removeEventListener('resize', setStageDims);
    };
  }, [state]);

  useEffect(() => {
    setStageDims();
  }, [image, ref.current]);

  useEffect(() => {
    dispatch(apiGetAllShootRobotsRequest(auth.token, project_id));
    dispatch(apiGetAllShootInstanceRequest(auth.token, project_id));
  }, []);

  useEffect(() => {
    let tempRobotLiveStatus = convertStates(gear.robotLiveStatus, 'id');
    setRobotLiveStatus(tempRobotLiveStatus);
  }, [gear.robotLiveStatus]);

  useEffect(() => {
    let [shootRobotObjs, tempRobotAirIds] = convertRobotStates(gear.shootRobots);
    setShootRobots(shootRobotObjs);
    setRobotAirIds(tempRobotAirIds);
    if (tempRobotAirIds.length !== 0){
      dispatch(apiGetRobotLiveStatusRequest(auth.token, tempRobotAirIds));
    }
  }, [gear.shootRobots]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (robotAirIds.length !== 0){
        dispatch(apiGetRobotLiveStatusRequest(auth.token, robotAirIds));
      }
    }, INTERVAL_MS);
    return () => {clearInterval(intervalId);}
  }, [robotAirIds]);

  useEffect(() => {
    setShootInstances(gear?.shootInstances);
  }, [gear.shootInstances]);

  useEffect(() => {
    if (params.gearId) {
      setTimeout(() => {
        Object.keys(shootRobots).forEach((robotId, index) => {
          const robotData = shootRobots[robotId];
          if (params.gearId === robotData?.air_id) {
            setSelectedRobotId(robotId);
            return;
          }
        });
      }, 500);
    }
  }, [params]);

  return (
    <LaunchPadLayout links={links}>
      {gear.isLoading ||
      userInformation.isLoading ||
      state?.shoot?.isLoading ? (
        <Loader />
      ) : (
        <MainContentContainer
          ref={ref}
          onChange={() => console.log('Changed!')}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: stageRef.current?.width(),
              height: stageRef.current?.height(),
            }}
          >
            <Stage
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              draggable={selectedRobotId ? false : true}
              ref={stageRef}
              x={position.x}
              y={position.y}
              onWheel={handleWheel}
              scaleX={scale}
              scaleY={scale}
              onTouchMove={handleMultiTouch}
              onTouchEnd={multiTouchEnd}
              onDragStart={handleDragStart}
            >
              <Layer>
                <Image
                  x={0}
                  y={0}
                  image={image}
                  width={image?.width}
                  height={image?.height}
                />
              </Layer>
              <Layer>
                <>
                  {Object.keys(shootRobots).map((robotId, i) => {
                    let selectedShootRobot = shootRobots[robotId];
                    if (selectedShootRobot.is_in_canvas){
                      let operatorData = getRobotOperatorData(robotId);
                      let robotStatusObj = robotLiveStatus?.[selectedShootRobot.robot_id];
                      return (
                        <Portal
                          selector=".focused"
                          key={robotId}
                          enabled={robotId.toString() === selectedRobotId?.toString() ? true : false}
                        >
                          <RobotCanvasItem
                            robotData={selectedShootRobot}
                            operatorData={operatorData}
                            scale={scale}
                            memberItemMoving={false}
                            robotStatusObj={robotStatusObj}
                            assets={assets}
                            userId={userInformation?.data?.user}
                            userRole={currentUserRole()}
                            draggable={false}
                            onDblClick={onDblClick}
                          />
                        </Portal>
                      );
                    }
                  })}
                </>
              </Layer>
              {selectedRobotId ? (
                <Layer
                  width={stageRef.current?.width()}
                  height={stageRef.current?.height()}
                >
                  <Rect
                    x={-position.x / scale}
                    y={-position.y / scale}
                    width={stageRef.current?.width() / scale}
                    height={stageRef.current?.height() / scale}
                    fill="#000000"
                    opacity={0.4}
                    onClick={handleFocusOut}
                    onTouchEnd={handleFocusOut}
                  />
                </Layer>
              ) : null}
              <Layer name="focused" />
            </Stage>
          </div>

          <BottomUsersNavContainer2>
            <BottomInstanceNav
              instances={shootInstances}
              onlineMembers={userInformation?.onlineMembers}
              onDblClick={onDblClick}
            />
            <BottomUsersNav
              shoot={state?.shoot}
              permission_obj={state?.shoot?.permission_obj}
              onlineMembers={userInformation?.onlineMembers}
            />
          </BottomUsersNavContainer2>

          <ZoomToolContainer>
            <ZoomTool
              onZoomIn={handleZoomIn}
              onZoomOut={handleZoomOut}
              centered={handleCentered}
            />
          </ZoomToolContainer>
        </MainContentContainer>
      )}
    </LaunchPadLayout>
  );
};

export default Main;

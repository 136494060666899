import React, { useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { apiGetCommsRequest } from 'Redux/actions/channel';
import DashboardLayout from '../../Layouts/DashboardLayout';
import Main from './Main';
import tutorialCommsBg from '../../Assets/images/tutorialCommsBg.png';

const CommsPage = () => {
  const { project_id } = useParams();
  const dispatch = useDispatch();

  // useSelector
  const channelState = useSelector(state => state.channel);
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(apiGetCommsRequest(project_id, auth.token));
  }, []);

  return (
    <DashboardLayout>
      {
        project_id === '0'?
        (
          <img src={tutorialCommsBg} className='img-fluid mt-4'/>
        ):
        (
          <Main state={channelState} project_id={project_id} />
        )
      }
    </DashboardLayout>
  );
};

export default CommsPage;

import styled from 'styled-components';

export const SessionWrapper = styled.div`
  #header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 72px;
    z-index: 100;
    background: rgb(204, 204, 255);
    display : flex;
    align-items : center;
    justify-content: center;
    font-size: 28px;
  }

  #loader {
    position: fixed;
    top: 40vw;
    left: 0;
    width: 100vw;
    height: 72px;
    z-index: 100;
  }

  #footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: ${(props) => (props.isRecording ? '108px' : '72px')};
    z-index: 100;
    background: linear-gradient(0deg, rgba(208, 188, 255, 0.08), rgba(208, 188, 255, 0.08)), #1c1b1f;
  }

  #redcircle {
    display: inline-block;
    vertical-align: middle;
    margin: 0 8px 18px 0;
    background-color: #93000a;
    border-color: white;
    border-radius: 50%;
    border-width: 5px;
    height: 20px;
    width: 20px;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  a {
    width: 49%;
    margin-right: 2%;
    border-color: #c1c3c7;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background: #7F56D9;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  button {
    width: 49%;
    border-color: #c1c3c7;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
`;

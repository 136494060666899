import styled from 'styled-components';

export const RightNavContentContainer = styled.div`
  overflow-y: scroll;
  height: 80vh;
  padding: 0 24px;
  padding-bottom: 100px;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  // scrollbar-width: none;
`;

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import SettingsNavbar from './SettingsNavbar';

// Redux actions
import { setUserInformation } from '../../Redux/actions/login';
import { apiGetUserInformation, apiPatchUserInformation, resetUserInformationState, updateSelectedOrganization,} from '../../Redux/actions/user_information';
import { showWelcomeModal, updateTutorialState } from '../../Redux/actions/tutorial';
import { clearToken } from '../../Redux/actions/login';
import { resetProjectState } from '../../Redux/actions/project';
import { apiTeamListRequest } from 'Redux/actions/team';

// Pages
import AccountPage from '../../Pages/AccountPage';
import BillingPage from '../../Pages/BillingPage';
import TeamPage from 'Pages/TeamPage';
import GearAssetsPage from '../../Pages/GearAssetsPage';
import ChangePasswordPage from 'Pages/ChangePasswordPage';
import IntegrationsPage from 'Pages/IntegrationsPage';

// Layouts
import DashboardLayout from '../../Layouts/DashboardLayout';

// styles
import { AccountPageContainer, LogoutButton } from './styles';
import { getObjectByLowestValue } from 'Utils/permissions';

// Components
import { PageHeading } from '../../Components/CommonStyles';
import CustomSelect from 'Components/CustomSelect';

const SettingsContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // useSelector
  const auth = useSelector(state => state.auth);
  const userInformation = useSelector(state => state.userInformation);
  const team = useSelector(state => state.team);
  const tutorialState = useSelector(state => state.tutorial);
  const teamCount = team?.teamMembers?.count || 0;

  // useState
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [logoutClicked, setLogoutClicked] = useState();
  const role = getObjectByLowestValue(
    userInformation?.data?.organisation_data,
    'role',
  )?.role;

  useEffect(() => {
    if (auth.token) {
      dispatch(apiGetUserInformation(auth.user_information?.id, auth.token));
      if (selectedOrg) {
        dispatch(apiTeamListRequest(auth.token, 1, selectedOrg, '', null, undefined, null,),);
      }
    }
  }, []);

  useEffect(() => {
    if (logoutClicked || auth.token === null) {
      history.replace('/login');
    }
  }, [logoutClicked, auth, history]);

  const organisationData = useMemo(() => {
    const data = userInformation?.data?.organisation_data || [];
    return data
      .filter(item => item.role === 1 || item.role === 2)
      .map(item => ({
        value: item?.organisation?.id,
        label: item?.organisation?.name,
      }));
  }, [userInformation?.data?.organisation_data]);

  useEffect(() => {
    if (userInformation.success && organisationData.length > 0) {
      dispatch(setUserInformation(userInformation.data));
      dispatch(resetUserInformationState());
      if (!selectedOrg) {
        setSelectedOrg(organisationData[0].value);
        handleChangeOrg(
          organisationData.find(
            data => data.value === userInformation?.selectedOrganisation,
          ) || organisationData[0],
        );
      }
    }
  }, [userInformation.success, organisationData, dispatch]);

  const onSubmit = data => {
    dispatch(
      apiPatchUserInformation(data, auth.user_information?.id, auth.token),
    );
  };

  const handleChangeOrg = org => {
    setSelectedOrg(org.value);
    dispatch(updateSelectedOrganization(org.value));
    dispatch(apiTeamListRequest(auth.token, null, org.value));
  };

  const onLogoutClicked = e => {
    if (
      userInformation?.data?.organisation_data &&
      userInformation?.data?.organisation_data?.length > 0 &&
      userInformation?.data?.first_login === true
    )
      dispatch(
        apiPatchUserInformation(
          { first_login: false },
          auth.user_information?.id,
          auth?.token,
          null,
          false,
        ),
      );
    e.preventDefault();
    const tutorialPayload = {
      ...tutorialState,
      isTour: false,
    };
    dispatch(updateTutorialState(tutorialPayload));
    setLogoutClicked(true);
    dispatch(showWelcomeModal(true));
    dispatch(clearToken(auth.token));
    dispatch(resetProjectState());
  };

  return (
    <DashboardLayout>
      <AccountPageContainer>
        <div className="d-flex">
          <PageHeading>Account Details</PageHeading>
          {organisationData.length > 0 > 0 && (
            <div style={{ width: '200px', marginLeft: '16px' }}>
              <CustomSelect
                options={organisationData}
                ref={null}
                defaultValue={
                  organisationData.find(
                    data =>
                      data.value === userInformation?.selectedOrganisation,
                  ) || organisationData[0]
                }
                onChange={e => handleChangeOrg(e)}
              />
            </div>
          )}
          <div style={{ width: '200px', marginLeft: '16px' }}>
              <LogoutButton className="btn btn-primary" onClick={onLogoutClicked}>
                Log Out
              </LogoutButton>
            </div>
        </div>
        <SettingsNavbar history={history} teamCount={teamCount} />
        <Switch>
          <Route path={'/'} exact>
            <AccountPage
              auth={auth}
              state={userInformation}
              onSubmit={onSubmit}
            />
          </Route>
          <Route path={'/settings/account-details'} exact>
            <AccountPage
              auth={auth}
              state={userInformation}
              onSubmit={onSubmit}
            />
          </Route>
          <Route path={'/settings/password'} exact>
            <ChangePasswordPage auth={auth} />
          </Route>
          {role === 1 && selectedOrg && (
            <>
              <Route path={'/settings/team'} exact>
                <TeamPage
                  auth={auth}
                  state={userInformation}
                  selectedOrg={selectedOrg}
                />
              </Route>
              <Route path={'/settings/integrations'} exact>
                <IntegrationsPage
                  auth={auth}
                  userInformation={userInformation}
                  selectedOrg={selectedOrg}
                />
              </Route>
              <Route path={'/settings/assets'} exact>
                <GearAssetsPage
                  organisationId={selectedOrg}
                  organisationName={
                    organisationData?.find(org => org?.value === selectedOrg)
                      ?.label
                  }
                />
              </Route>
              <Route path={'/settings/billing'} exact>
                <BillingPage organisationId={selectedOrg} />
              </Route>
            </>
          )}
        </Switch>
      </AccountPageContainer>
    </DashboardLayout>
  );
};

export default SettingsContainer;

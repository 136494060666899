export const API_TEAM_LIST_REQUEST = 'API_TEAM_LIST_REQUEST';
export const API_TEAM_LIST_SUCCESS = 'API_TEAM_LIST_SUCCESS';
export const API_TEAM_LIST_FAILED = 'API_TEAM_LIST_FAILED';

export const API_MEMBERS_LIST_REQUEST = 'API_MEMBERS_LIST_REQUEST';
export const API_MEMBERS_LIST_SUCCESS = 'API_MEMBERS_LIST_SUCCESS';
export const API_MEMBERS_LIST_FAILED = 'API_MEMBERS_LIST_FAILED';

// Add Team Member
export const API_TEAM_POST_REQUEST = 'API_TEAM_POST_REQUEST';
export const API_TEAM_POST_SUCCESS = 'API_TEAM_POST_SUCCESS';
export const API_TEAM_POST_FAILED = 'API_TEAM_POST_FAILED';

// Delete Team Member
export const API_TEAM_DELETE_REQUEST = 'API_TEAM_DELETE_REQUEST';
export const API_TEAM_DELETE_SUCCESS = 'API_TEAM_DELETE_SUCCESS';
export const API_TEAM_DELETE_FAILED = 'API_TEAM_DELETE_FAILED';

// Currnt Patch Team Member
export const API_TEAM_PATCH_REQUEST = 'API_TEAM_PATCH_REQUEST';
export const API_TEAM_PATCH_SUCCESS = 'API_TEAM_PATCH_SUCCESS';
export const API_TEAM_PATCH_FAILED = 'API_TEAM_PATCH_FAILED';

// Get All Projects
export const API_PROJECT_LIST_REQUEST = 'API_PROJECT_LIST_REQUEST';
export const API_PROJECT_LIST_SUCCESS = 'API_PROJECT_LIST_SUCCESS';
export const API_PROJECT_LIST_FAILED = 'API_PROJECT_LIST_FAILED';

// Patch Projects
export const API_PROJECT_PATCH_REQUEST = 'API_PROJECT_PATCH_REQUEST';
export const API_PROJECT_PATCH_SUCCESS = 'API_PROJECT_PATCH_SUCCESS';
export const API_PROJECT_PATCH_FAILED = 'API_PROJECT_PATCH_FAILED';

//Pagination Next Page
export const API_NEXT_PAGE_REQUEST = 'API_NEXT_PAGE_REQUEST';
export const API_NEXT_PAGE_SUCCESS = 'API_NEXT_PAGE_SUCCESS';
export const API_NEXT_PAGE_FAILED = 'API_NEXT_PAGE_FAILED';

//Pagination Previos Page
export const API_PREVIOUS_PAGE_REQUEST = 'API_PREVIOUS_PAGE_REQUEST';
export const API_PREVIOUS_PAGE_SUCCESS = 'API_PREVIOUS_PAGE_SUCCESS';
export const API_PREVIOUS_PAGE_FAILED = 'API_PREVIOUS_PAGE_FAILED';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

// styles
import { Header, MainContainer, SubHeader } from './styles';
import { EachCollabolatorsP2P } from 'Pages/ArchiveProjectsPage/styles';
import {
  CloseIcon,
  NavTitle,
  RightNavContainer,
  SideBarContent,
} from 'Layouts/ProjectLayout/styles';

// SVG, PNG
import discord from '../../../Assets/images/discord.svg';
import slack from '../../../Assets/images/slack.svg';
import unityIntercom from '../../../Assets/images/unityIntercom.svg';
import syncstage_image from '../../../Assets/images/sync_stage.png';
import errorIcon from '../../../Assets/icons/errorIcon.svg';
import * as types from '../../../Config/permissionConstant';

// Components
import DocumentCard from '../../../Components/DocumentCard';
import X from 'Components/Icons/X';
import AddCommsContent from 'Components/AddCommsContent';
import Loader from 'Components/Loader';
import { OutlinedButton } from 'Components/IconButton/styles';
import Plus from 'Components/Icons/Plus';
import { ModalFooter } from 'Components/CommonStyles';
import Modal from 'Components/Modal';

// Redux
import { apiCommsDeleteRequest,} from 'Redux/actions/channel';
import {channelServices} from 'Redux/services/channel';

// Other
import { getObjectByLowestValue } from 'Utils/permissions';

const Main = ({ state, project_id }) => {
  const links = [
    {
      title: 'Comms',
      href: `/project/comms/${project_id}`,
    },
  ];

  const CommsType = {
    slack: {
      name: 'Slack',
      icon: slack,
      btnTitle: 'Open Slack',
      description: 'Send notifications to channels and create projects.',
    },
    discord: {
      name: 'Discord',
      icon: discord,
      btnTitle: 'Open Discord',
      description: 'Everything you need for work, all in one place.',
    },
    unity_intercom: {
      name: 'Unity Intercom',
      icon: unityIntercom,
      btnTitle: 'Open Unity',
      description: 'Plan, track, and release great software.',
    },
    sync_stage: {
      name: 'SyncStage',
      icon: syncstage_image,
      btnTitle: 'Open Sync',
      description: 'Ultra low latency audio collaboration.',
    },
  };

  const SideNavPageType = {
    discord: {
      title: 'Edit Discord',
      subTitle: 'Select a default Discord Server for this organization.',
    },
    unity_intercom: {
      title: 'Edit Unity',
      subTitle: 'Select a default unity server for this organization.',
    },
    slack: {
      title: 'Edit Slack',
      subTitle: 'Select a default slack channel for this organization.',
    },
    sync_stage: {
      title: 'Edit SyncStage',
      subTitle: 'Select a default sync stage channel for this organization.',
    },
    ADD_COMMS: {
      title: 'Manage Comms',
      subTitle: 'Select a Comm channel for this project.',
    },
    START_SYNSTAGE_SESSION: {
      title: 'Start Syncstage Session',
      subTitle: 'Choose a Syncstage Server and launch a Syncstage Session',
    },
  };

  const ModalTypes = {
    discord: {
      type: 'discord',
      title: 'Delete Discord?',
      body: `Are you sure you want to delete this discord channel?`,
    },
    slack: {
      type: 'slack',
      title: 'Delete Slack?',
      body: `Are you sure you want to delete this slack channel?`,
    },
    unity_intercom: {
      type: 'unity_intercom',
      title: 'Delete Unity Intercom?',
      body: `Are you sure you want to delete this unity intercom channel?`,
    },
    sync_stage: {
      type: 'sync_stage',
      title: 'Delete SyncStage?',
      body: `Are you sure you want to delete this sync stage channel?`,
    },
    sync_stage_desktop_agent: {
      type: 'sync_stage_desktop_agent',
      title: 'Launch Desktop Agent or Install Desktop Agent.',
      body: `To have a low latency conversation you need to have the SyncStage Desktop Agent running.`,
    },
  };

  const deleteClicked = () => {
    dispatch(
      apiCommsDeleteRequest(selectedDeleteItemId, auth.token, project_id),
    );
    setModalShow(false);
  };

  const onRightNavClose = () => {
    setShowRightNav(false);
  };

  const dispatch = useDispatch();

  // useSelector
  const sidebarState = useSelector(state => state.sidebar);
  const auth = useSelector(state => state.auth);
  const selectProjectDetails = sidebarState?.selectedProject;
  const allowedPermissions =
    selectProjectDetails &&
    getObjectByLowestValue(selectProjectDetails?.permission_obj, 'role')
      ?.allowed_permissions;

  // useState
  const [modalShow, setModalShow] = useState(false);
  const [modalDetails, setModalDetails] = useState(ModalTypes['discord']);
  const [selectedDeleteItemId, setSelectedDeleteItemId] = useState(null);
  const [showRightNav, setShowRightNav] = useState(false);
  const [sideNavPage, setSideNavPage] = useState(null);
  const [commsId, setCommsId] = useState(null);
  const [syncstageSessionActive, setSyncstageSessionActive] = useState(false);
  const [syncstageJwt, setSyncstageJwt] = useState(null);

  const openSyncstageSessionPage = (syncstageSessionCode) => {
    window.open(`/syncstage-session/${syncstageSessionCode}`, '_blank', 'noopener,noreferrer');
  };

  const storageHandler = (event) => {
    if (event.key === 'SyncstageSessionActive') {
      if (event.newValue === 'true') {
        setSyncstageSessionActive(true);
      } else if (event.newValue === 'false') {
        setSyncstageSessionActive(false);
      }
    };
  };

  useEffect(async () => {
    window.addEventListener("storage", storageHandler);
    let jwt = await channelServices.apiGetSyncstageDeveloperAPIJwt(auth.token);
    setSyncstageJwt(jwt.slice(1, -1));

    return () => {
      window.removeEventListener("storage", storageHandler);
      setSyncstageJwt(null);
    }
  }, []);

  const modalFooter = (
    <ModalFooter>
      <Button onClick={() => setModalShow(false)} variant="light">
        Cancel
      </Button>
      <Button onClick={deleteClicked} variant="danger">
        Delete
      </Button>
    </ModalFooter>
  );

  return (
    <>
      <MainContainer>
        <div className="d-flex justify-content-between">
          <div>
            <Header>Integrations and connected apps</Header>
            <br />
            <SubHeader>
              Supercharge your workflow and connect the tool you use every day.
            </SubHeader>
          </div>
          {allowedPermissions?.includes(types.ADD_COMMS) && (
            <OutlinedButton
              className="btn btn-sm fw-semibold"
              style={{ minWidth: '145px', color: '#344054' }}
              onClick={() => {
                setCommsId(null);
                setShowRightNav(true);
                setSideNavPage('ADD_COMMS');
              }}
            >
              <Plus stroke="#344054" /> Add New
            </OutlinedButton>
          )}
        </div>
        {(state?.isLoading ) ? (
          <Loader />
        ) : (
          <div
            className="row gy-3"
            style={{ marginTop: '48px', marginBottom: '75px' }}
          >
            {allowedPermissions &&
            allowedPermissions[0] !== types.NO_PERMISSION &&
            state.projectComms?.length
              ? state.projectComms.map(
                  link =>
                    CommsType[link.type] !== undefined && (
                      <div className="col-lg-6 col-xl-4" key={link.id}>
                        {
                          <DocumentCard
                            btnTitle={CommsType[link.type]?.btnTitle}
                            image={CommsType[link.type]?.icon}
                            title={CommsType[link.type]?.name}
                            subTitle={link.title}
                            key={link.id}
                            description={CommsType[link.type]?.description}
                            isSyncstage= {link.type === 'sync_stage'}//{CommsType[link.type] === 'sync_stage'}
                            url={link.url}
                            editAction={
                              allowedPermissions.includes(types.DELETE_COMMS) && (
                                <EachCollabolatorsP2P
                                  onClick={() => {
                                    setModalShow(true);
                                    setModalDetails(ModalTypes[link.type]);
                                    setSelectedDeleteItemId(link.id);
                                  }}
                                  role="button"
                                >
                                  Delete
                                </EachCollabolatorsP2P>
                              )
                            }
                            deleteAction= {
                              allowedPermissions.includes(types.EDIT_COMMS) && (!link.syncstage_session_code) && (
                                <button
                                  className="btn btn-link text-decoration-none"
                                  onClick={() => {
                                    setSideNavPage(link.type);
                                    setShowRightNav(true);
                                    setCommsId(link.id);
                                  }}
                                  rel="noopener noreferrer"
                                >
                                  Edit
                                </button>
                              )
                            }
                            joinAction= {
                              (!!link.syncstage_session_code || !syncstageSessionActive) && (
                                <button
                                  className="btn btn-link text-decoration-none"
                                  onClick={() => {
                                    if (syncstageSessionActive === false) {
                                      openSyncstageSessionPage(link.syncstage_session_code);
                                    } else {
                                      toast.error("Syncstage session active in another browser tab. Please close the tab before joining this session.")
                                    }
                                  }}
                                  rel="noopener noreferrer"
                                >
                                  Join
                                </button>
                              )
                            }
                          />
                        }
                      </div>
                    ),
                )
              : null}
          </div>
        )}

        {showRightNav && (
          <RightNavContainer
            style={{ position: 'fixed', top: '0', right: '0' }}
          >
            <CloseIcon onClick={onRightNavClose}>
              <X />
            </CloseIcon>
            <NavTitle>
              {SideNavPageType[sideNavPage]?.title}
              <br />
              <span>{SideNavPageType[sideNavPage]?.subTitle}</span>
            </NavTitle>
            <SideBarContent>
              <AddCommsContent
                auth={auth}
                onRightNavClose={onRightNavClose}
                projectId={project_id}
                commsId={commsId}
                syncstageJwt={syncstageJwt}
              />
            </SideBarContent>
          </RightNavContainer>
        )}
      </MainContainer>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        icon={errorIcon}
        title={modalDetails.title}
        body={modalDetails.body}
        footer={modalFooter}
      />
    </>
  );
};
export default Main;

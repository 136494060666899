export const API_CREATE_ORG = 'API_CREATE_ORG';
export const API_CREATE_ORG_SUCCESS = 'API_CREATE_ORG_SUCCESS';
export const API_CREATE_ORG_FAILED = 'API_CREATE_ORG_FAILED';

export const RESET_ONBOARDING = 'RESET_ONBOARDING';

// DEPRECATED SECTION
// export const API_IS_AIR_VALID = 'API_IS_AIR_VALID';
// export const API_IS_AIR_VALID_SUCCESS = 'API_IS_AIR_VALID_SUCCESS';
// export const API_IS_AIR_VALID_FAILED = 'API_IS_AIR_VALID_FAILED';

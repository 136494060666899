import styled from 'styled-components';

export const NavContainer = styled.div`
  width: 100%;
  height: 80px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`;

export const Badge = styled.span`
  background: #f9fafb;
  border-radius: 6px;
  padding: 4px 8px;
  cursor: pointer;
`;

export const ButtonDateDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PublishedDate = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-right: 18px;
`;

export const PublishButton = styled.button`
  margin-left: 12px;
`;

export const StatusLegendDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-right: 10px;
  padding-bottom: 1px;
  padding-top: 1px;
  border-radius: 3%;
  background: #F9F6EE;
`;

export const StatusContainer = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RobotStatusCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${(props) => props.color || 'gray'};
`;

export const LegendDescription = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #667085;
`;

import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useQuery from 'hooks/useQuery';

// styles
import { CollaboratorsContainer } from './styles';

// Redux actions
import { apiGetAllOrgRobotsRequest, apiUpdateOrgRobotRequest, apiGetAllOrgInstanceRequest, apiUpdateOrgInstanceRequest} from '../../Redux/actions/gear';
import { resetInvite } from 'Redux/actions/invite';
import { apiClearUrl, apiGetAvailablePkgRequest, apiGetInstanceUsageRequest, apiGetStoragePlanRequest, apiGetWasabiUsedStorageRequest, } from 'Redux/actions/assets';
import { apiGetAllPaymentMethodRequest } from 'Redux/actions/payment';
import { apiGetMyStoragePlan, } from 'Redux/actions/user_information';

// Components
import GearHeader from './components/GearHeader';
import GearsInfoSection from './components/GearsInfoSection';
import EditStorage from './components/EditStorage';
import OnboardInstance from './components/OnboardInstance';
import EditOrgRobot from '../../Components/EditOrgGears/EditOrgRobot';
import EditOrgInstance from '../../Components/EditOrgGears/EditOrgInstance';
import PaymentDetailsForm from 'Pages/BillingPage/components/PaymentDetailsForm';
import SelectPaymentMethod from 'Pages/BillingPage/components/SelectPaymentMethod';
import Loader from 'Components/Loader';
import X from 'Components/Icons/X';
import OnboardRobot from './components/OnboardRobot';

const GearAssetsPage = ({ organisationId, organisationName }) => {
  const dispatchRedux = useDispatch();
  const params = useQuery();

  // useSelector
  const gearState = useSelector(state => state.gear);
  const auth = useSelector(state => state.auth);
  const team = useSelector(state => state.team);
  const payment = useSelector(state => state.payment);
  const userInformation = useSelector(state => state.userInformation);
  const invite = useSelector(state => state.invite);
  const assets = useSelector(state => state.assets);
  const storage = userInformation.storagePlan;
  const availableStoragePkgs = assets?.availablePkgs?.filter(f => f.package_type === 1,);
  const availableCameraPkgs = assets?.availablePkgs?.filter(f => f.package_type === 2,);
  const loading = team?.isLoading;

  const [sideNavPage, setSideNavPage] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [allOrgGears, setAllOrgGears] = useState([]);
  const [postsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectGear, setSelectGear] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [storagePlanDetails, setStoragePlanDetails] = useState(null);
  const [filterAssets, setFilterAssets] = useState({
    heading: '',
    arrowDirection: 'up',
    state: true,
  });
  const [instanceCount, setInstanceCount] = useState(0);

  // Temporary useless
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [rightNavTitle, setRightNavTitle] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [cardAdded, setCardAdded] = useState(null);
  let isCardAdded = false;

  const ACTION_TYPES = {
    NAME_FILTER: 'Name_filter',
    DEVICE_FILTER: 'Device_filter',
    CATEGORY_FILTER: 'Category_filter',
    CLEAR_FILTERS: 'Clear_filter',
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case ACTION_TYPES.DEVICE_FILTER:
        return { ...state, device_filter: action.payload.text };
      case ACTION_TYPES.NAME_FILTER:
        return { ...state, name_filter: action.payload.text };
      case ACTION_TYPES.CATEGORY_FILTER:
        return { ...state, category_filter: action.payload.text };

      default:
        return state;
    }
  };

  const [filterState, dispatch] = useReducer(reducer, {
    name_filter: '',
    device_filter: '',
    category_filter: '',
  });

  const handleNameFilter = e => {
    setCurrentPage(1);
    dispatch({
      type: ACTION_TYPES.NAME_FILTER,
      payload: { text: e.target.value },
    });
  };

  const handleDeviceFilter = e => {
    setCurrentPage(1);
    dispatch({
      type: ACTION_TYPES.DEVICE_FILTER,
      payload: { text: e.value !== '0' ? e.value : null },
    });
  };

  const handleCategoryFilter = e => {
    setCurrentPage(1);
    dispatch({
      type: ACTION_TYPES.CATEGORY_FILTER,
      payload: { text: e.value !== '0' ? e.value : null },
    });
  };

  const onChangeRightNav = e => {
    setSideNavPage(e);
  };

  const closeSideBar = () => {
    setSideNavPage('');
  };

  const onChangeAll = val => {
    setSelectAll(!selectAll);
  };

  const onConfirm = () => {
    // if (selectedPackage[0]?.id === 3) {
    //   setSideNavPage('');
    // } else {

    setSideNavPage('Payment Method');
    // }
  };

  const editGear = (data, id) => {
    setSelectGear(data);
    if (data.instance_size) {
      onChangeRightNav('editInstance');
    } else {
      onChangeRightNav('EditOrgRobot');
    }
  };

  const onGearEditApi = (gearData, isDelete = false) => {
    if ('instance_size' in gearData) {
      dispatchRedux(apiUpdateOrgInstanceRequest(auth.token, gearData))
    } else {
      dispatchRedux(apiUpdateOrgRobotRequest(auth.token, gearData))
    }
  };

  // const closeSideBar2 = () => {
  //   setSideNavPage('Payment Method');
  //   setSideNavPage('Payment Method');
  // };

  // const handleSideNavPage = (type, id) => {
  //   setSelectedMethod(id);
  //   if (type === 'Payment Method') {
  //     setSideNavPage('Payment Method');
  //     setSideNavPage('Payment Method');
  //     setRightNavTitle('Payment Method');
  //   } else if (type === 'Payment Details') {
  //     setPaymentDetails(true);
  //     setSideNavPage('Payment Details');
  //     setSideNavPage('Payment Details');
  //     setRightNavTitle('Payment Details');
  //   } else {
  //     setSideNavPage('editStorage');
  //   }
  // };

  useEffect(() => {
    if (userInformation.storagePlan) {
      const data = userInformation.storagePlan;
      const planDetails = {
        storage: 0,
        price: 0,
        price_supporting_text: '',
        billingDate: data?.length > 1 ? data[1]?.end_date : null,
        cameras: 0,
        instances: 0,
        storageType: 'GB',
      };
      data.forEach(item => {
        const tempStorage =
          item.package.storage_type !== 'gb'
            ? item.package.storage * 1000 * item.quantity
            : item.package.storage * item.quantity;
        planDetails.storage = Number(planDetails.storage) + Number(tempStorage);
        planDetails.price = planDetails.price + item?.package?.price;
        planDetails.price_supporting_text =
          item?.package?.price_supporting_text;
      });
      if (planDetails.storage >= 1000) {
        planDetails.storageType = 'TB';
        planDetails.storage = planDetails.storage / 1000;
      }
      planDetails.price = planDetails.price / 100;
      setStoragePlanDetails(planDetails);
    }
  }, [userInformation.storagePlan]);

  useEffect(() => {
    if (assets?.url) {
      window.location.href = assets?.url;
      dispatch(apiClearUrl());
    }
  }, [assets?.url]);

  // useEffect(() => {
  //   if (gearState.redirect_url) {
  //     window.location.replace(gearState.redirect_url);
  //   }
  // }, [gearState.redirect_url]);

  useEffect(() => {
    let tempGears = [];
    if (gearState?.orgInstances) {
      tempGears = tempGears.concat(gearState?.orgInstances);
      setInstanceCount(gearState?.orgInstances.length)
    }
    tempGears = tempGears.concat(gearState?.orgRobots);
    setAllOrgGears(tempGears);
  }, [gearState?.orgInstances, gearState.orgRobots]);

  useEffect(() => {
    if (
      params.get('payment_status') &&
      params.get('payment_status') === 'success'
    ) {
      toast.success('Payment was made successfully.', { toastId: 'success' });
    }

    if (
      params.get('payment_status') &&
      params.get('payment_status') === 'cancel'
    ) {
      toast.error('The payment was canceled.', { toastId: 'cancel' });
    }

    window.history.pushState({}, document.title, window.location.pathname);

    dispatchRedux(apiGetMyStoragePlan(auth.token, organisationId));
    dispatchRedux(apiGetStoragePlanRequest(auth.token, organisationId));
    dispatchRedux(apiGetWasabiUsedStorageRequest(auth.token, organisationId));
    dispatchRedux(apiGetAvailablePkgRequest(auth.token));
    dispatchRedux(apiGetAllPaymentMethodRequest(auth.token));
    dispatchRedux(apiGetInstanceUsageRequest(auth.token));
    dispatchRedux(apiGetAllOrgRobotsRequest(auth.token, organisationId));
    dispatchRedux(apiGetAllOrgInstanceRequest(auth.token, organisationId));
  }, [organisationId]);

  useEffect(() => {
    if (invite.success) {
      dispatch(resetInvite());
    }
  }, [invite]);

  // useEffect(() => {
  //   if (payment && !paymentDetails) {
  //     isCardAdded =
  //       payment.paymentMethods?.data?.length > 0
  //         ? 'Payment Method'
  //         : 'Payment Method';

  //     setCardAdded(isCardAdded);
  //     setSideNavPage(isCardAdded);
  //     setRightNavTitle(isCardAdded);
  //   }
  // }, [payment]);

  const rightSideNavPageObj = {
    'editStorage': (
      <EditStorage
        onChangeRightNav={onChangeRightNav}
        availablePkgs={availableStoragePkgs}
        auth={auth}
        payment={payment}
        storage={storage}
        onConfirm={onConfirm}
        selectedPackage={selectedPackage}
        setSelectedPackage={setSelectedPackage}
      />
    ),
    'onboardInstance': (
      <OnboardInstance
        onChangeRightNav={onChangeRightNav}
        organisationId={organisationId}
        organisationName={organisationName}
      />
    ),
    'editInstance': (
      <EditOrgInstance
        editInstance={selectGear}
        closeSideBar={closeSideBar}
        onGearEditApi={onGearEditApi}
      />
    ),
    // 'Payment Details': (
    //   <RightNavContainer>
    //     <CloseIcon onClick={closeSideBar}>
    //       <X />
    //     </CloseIcon>

    //     <NavTitle>{rightNavTitle}</NavTitle>
    //     <SideBarContent>
    //       <PaymentDetailsForm
    //         onRightNavClose={closeSideBar2}
    //         handleSideNavPage={handleSideNavPage}
    //         selectedMethod={selectedMethod}
    //         paymentMethods={payment?.paymentMethods?.data}
    //       />
    //     </SideBarContent>
    //   </RightNavContainer>
    // ),
    // 'Payment Method': (
    //   <RightNavContainer>
    //     <CloseIcon onClick={closeSideBar}>
    //       <X />
    //     </CloseIcon>

    //     <NavTitle>{rightNavTitle}</NavTitle>
    //     <SideBarContent>
    //       <SelectPaymentMethod
    //         onRightNavClose={closeSideBar}
    //         handleSideNavPage={handleSideNavPage}
    //         paymentMethods={payment?.paymentMethods?.data}
    //         customerDetails={payment?.customerDetails?.invoice_settings}
    //         storagePlan={storage}
    //         selectedPackage={selectedPackage}
    //         availablePkgs={assets?.availablePkgs}
    //         assets={assets}
    //         organisationId={organisationId}
    //       />
    //     </SideBarContent>
    //   </RightNavContainer>
    // ),

    'OnboardRobot': (
      <OnboardRobot
        onChangeRightNav={onChangeRightNav}
        organisationId={organisationId}
        organisationName={organisationName}
      />
    ),

    'EditOrgRobot': (
      <EditOrgRobot
        selectedRobot={selectGear}
        onGearEditApi={onGearEditApi}
        closeSideBar={closeSideBar}
      />
    ),
  };

  return assets?.isLoading ? (
    <Loader />
  ) : (
    <CollaboratorsContainer>
      <GearHeader
        onChangeRightNav={onChangeRightNav}
        robotCount={gearState.orgRobots.length}
        instanceCount={instanceCount}
        assets={assets}
        storage={storagePlanDetails}
      />

      <GearsInfoSection
        team={team}
        allOrgGears={allOrgGears}
        onChangeAll={onChangeAll}
        selectAll={selectAll}
        loading={loading}
        postsPerPage={postsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        filterState={filterState}
        editGear={editGear}
        dispatch={dispatch}
        ACTION_TYPES={ACTION_TYPES}
        handleNameFilter={handleNameFilter}
        handleDeviceFilter={handleDeviceFilter}
        handleCategoryFilter={handleCategoryFilter}
        filterAssets={filterAssets}
        setFilterAssets={setFilterAssets}
      />

      {sideNavPage in rightSideNavPageObj
        ? rightSideNavPageObj[sideNavPage]
        : null
      }
    </CollaboratorsContainer>
  );
};

export default GearAssetsPage;

import React, { useState, useEffect } from 'react';
import InsIcon from '../../../Assets/images/GearInsIcon.png';
import CameraIcon from '../../../Assets/images/GearCamIcon.png';
import moment from 'moment';
import {
  Collabolators,
  CollabolatorsAvatar,
  EachCollabolators,
  EachCollabolatorsP,
  EachCollabolatorsP1,
  EachCollabolatorsP2,
  EachCollabolatorsP2Div,
  EachCollabolatorsP3,
  EachCollabolatorsP3P,
  EachCollabolatorsP4,
  EachCollabolatorsP5,
  EachCollabolatorsP5P,
  EachCollabolatorsP6P,
} from '../styles';
import { typeOptions } from 'Config/permissionConstant';
import SearchFooter from './SearchFooter';

const GearsList = props => {
  let {
    editGear,
    allOrgGears,
    name_filter,
    device_filter,
    category_filter,
    indexOfLastPost,
    indexOfFirstPost,
    filterAssets,
    postsPerPage,
    setCurrentPage,
    currentPage,
    prePage,
    nextPg,
  } = props;
  let filterByDevice = device_filter ? parseInt(device_filter) : 0;

  const [gearsList, setGearsList] = useState([]);

  useEffect(() => {
    let tempGearsList = allOrgGears
      .filter(f => 'instance_size' in f
        ? f?.nick_name
          .toLowerCase()
          .includes(name_filter.toLowerCase().trim())
        : f?.robot_org_nick_name
          .toLowerCase()
          .includes(name_filter.toLowerCase().trim())
      )
      .filter(f => filterByDevice === 1
        ? 'robot_org_nick_name' in f
        : filterByDevice === 2
          ? f?.instance_type === 'VIMIX_MAIN'
          : filterByDevice === 3
            ? f?.instance_type === 'VIMIX_REPLY'
            : filterByDevice === 4
              ? f?.instance_type === 'SRT_GATEWAY'
              : f,
      )
      // .filter(f =>
      //   category_filter
      //     ? category_filter?.toLowerCase() === 'available'
      //       ? f?.is_available
      //       : category_filter?.toLowerCase() === 'engaged'
      //         ? !f?.is_available
      //         : f
      //     : f,
      // )
      ?.map(f => {
        if (!f.instance_type) {
          f['instance_type'] = 'AIR One™';
        }
        return f;
      });

    if (
      filterAssets.heading === 'name' &&
      filterAssets.arrowDirection === 'up'
    ) {
      tempGearsList = tempGearsList.sort((a, b) =>
        (a?.robot_org_nick_name || a?.nick_name)?.toLowerCase() >
        (b?.robot_org_nick_name || b?.nick_name)?.toLowerCase()
          ? 1
          : -1,
      );
    } else if (
      filterAssets.heading === 'name' &&
      filterAssets.arrowDirection === 'down'
    ) {
      tempGearsList = tempGearsList.sort((a, b) =>
        (a?.robot_org_nick_name || a?.nick_name)?.toLowerCase() <
        (b?.robot_org_nick_name || b?.nick_name)?.toLowerCase()
          ? 1
          : -1,
      );
    }
    // else if (
    //   filterAssets.heading === 'type' &&
    //   filterAssets.arrowDirection === 'up'
    // ) {
    //   tempGearsList = tempGearsList.sort((a, b) =>
    //     a.instance_type?.toLowerCase() > b.instance_type?.toLowerCase()
    //       ? 1
    //       : -1,
    //   );
    // }
    // else if (
    //   filterAssets.heading === 'type' &&
    //   filterAssets.arrowDirection === 'down'
    // ) {
    //   tempGearsList = tempGearsList.sort((a, b) =>
    //     a.instance_type?.toLowerCase() < b.instance_type?.toLowerCase()
    //       ? 1
    //       : -1,
    //   );
    // }
    // else if (
    //   filterAssets.heading === 'price' &&
    //   filterAssets.arrowDirection === 'up'
    // ) {
    //   tempGearsList = tempGearsList.sort((a, b) =>
    //     (a?.package_info?.price || 0) > (b?.package_info?.price || 0)
    //       ? 1
    //       : -1,
    //   );
    // }
    // else if (
    //   filterAssets.heading === 'price' &&
    //   filterAssets.arrowDirection === 'down'
    // ) {
    //   tempGearsList = tempGearsList.sort((a, b) =>
    //     (a?.package_info?.price || 0) < (b?.package_info?.price || 0)
    //       ? 1
    //       : -1,
    //   );
    // }
    // else if (
    //   filterAssets.heading === 'exp_date' &&
    //   filterAssets.arrowDirection === 'up'
    // ) {
    //   const endDate = endDateStr =>
    //     endDateStr ? moment(endDateStr)?.format('x') : 0;
    //   tempGearsList = tempGearsList.sort((a, b) =>
    //     endDate(a.end_date) > endDate(b?.end_date) ? 1 : -1,
    //   );
    // }
    // else if (
    //   filterAssets.heading === 'exp_date' &&
    //   filterAssets.arrowDirection === 'down'
    // ) {
    //   const endDate = endDateStr =>
    //     endDateStr ? moment(endDateStr)?.format('x') : 0;
    //   tempGearsList = tempGearsList.sort((a, b) =>
    //     endDate(a.end_date) < endDate(b.end_date) ? 1 : -1,
    //   );
    // }
    setGearsList(tempGearsList);
  }, [
    filterAssets,
    name_filter,
    device_filter,
    category_filter,
    allOrgGears,
  ]);
  return (
    <>
      <Collabolators>
        {gearsList?.slice(indexOfFirstPost, indexOfLastPost)
          ?.map((gearData, index) => {
            return (
              <EachCollabolators
                key={index}
              >
                <EachCollabolatorsP1>
                  <CollabolatorsAvatar
                    src={
                      gearData?.instance_type === 'AIR One™'
                        ? CameraIcon
                        : InsIcon
                    }
                  />
                  <div>
                    <EachCollabolatorsP>
                      {gearData?.robot_org_nick_name || gearData?.nick_name}
                    </EachCollabolatorsP>
                    <EachCollabolatorsP6P>
                      {gearData?.air_id}
                    </EachCollabolatorsP6P>
                  </div>
                </EachCollabolatorsP1>
                <EachCollabolatorsP2>
                  <EachCollabolatorsP2Div>
                    <div>
                      <p>
                        {typeOptions.find(
                          type => type?.value === gearData?.instance_type,
                        )?.label || gearData?.instance_type}
                      </p>
                    </div>
                  </EachCollabolatorsP2Div>
                </EachCollabolatorsP2>
                <EachCollabolatorsP3>
                  <EachCollabolatorsP3P>
                    {gearData?.package_info?.price &&
                      `$${gearData?.package_info?.price / 100}`}{' '}
                    {gearData?.package_info?.price_supporting_text}
                  </EachCollabolatorsP3P>
                </EachCollabolatorsP3>
                <EachCollabolatorsP4>
                  {gearData?.end_date &&
                    moment(gearData.end_date).format('MMM Do, YYYY')}
                </EachCollabolatorsP4>
                <EachCollabolatorsP5 onClick={() => editGear(gearData, index)}>
                  <EachCollabolatorsP5P>Edit</EachCollabolatorsP5P>
                </EachCollabolatorsP5>
              </EachCollabolators>
            );
          })}
      </Collabolators>
      <SearchFooter
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        prePage={prePage}
        nextPg={nextPg}
        count={gearsList?.length || 0}
      />
    </>
  );
};

export default GearsList;

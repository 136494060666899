import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import {
  OptionsValueContainer,
  RightNavContainer,
  RightNavContainerFoot,
  RightNavContainerX,
  RightNavContainerXPar,
  RightNavSecDiv,
  RNCFBtn,
  RNCFBtn2,
  SBMSDiv3,
  SBMSDiv3a,
  SBMSInput2,
  SDMHeading,
  SDMHeadingP,
  SideBarMainDiv,
  TeamHeadBtnImgP,
  TeamHeadBtnImgPa,
} from '../styles';

// PNG
import cross from '../../../Assets/images/cross.png';
import tick from '../../../Assets/images/tick.png';

// Components
import {
  DropDownIconContainer,
  OptionText,
} from '../../../Components/CommonStyles';

// Redux actions
import { apiOnboardOrgInstanceRequest, clearInstanceDetails,} from 'Redux/actions/gear';

// Config
import { regionOptions, sizeOptions, typeOptions,} from 'Config/permissionConstant';

const OnboardInstance = ({
  onChangeRightNav,
  organisationId,
  organisationName
}) => {
  const dispatch = useDispatch();

  // useSelector
  const auth = useSelector(state => state.auth);
  const gears = useSelector(state => state.gear);

  // useState
  const [instance, setInstance] = useState({owner_name: organisationName});
  const [error, setError] = useState({
    nick_name: '',
    air_id: '',
    instance_size: '',
    instance_type: '',
    lan_ip: '',
    public_ip: '',
  });

  const checkIPValidity = (value, fieldName) => {
    const ipRegEx =
      /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:([1-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?$/;
    if (value.length > 0 && !ipRegEx?.test(value)) {
      setError(prev => ({
        ...prev,
        [fieldName]: 'Invalid IP address',
      }));
      setInstance(prev => ({ ...prev, [fieldName]: value }));
    } else {
      setInstance(prev => ({ ...prev, [fieldName]: value }));
      setError(prev => ({ ...prev, [fieldName]: '' }));
    }
  };

  const closeSideBar = () => {
    dispatch(clearInstanceDetails());
    onChangeRightNav('');
  };

  const onConfirm = () => {
    if (!instance.instance_type) {
      setError(prev => ({ ...prev, instance_type: 'This field is required' }));
    }

    if (!instance.instance_size) {
      setError(prev => ({ ...prev, instance_size: 'This field is required' }));
    }

    if (!instance.owner_name) {
      setError(prev => ({ ...prev, owner_name: 'This field is required' }));
    }

    if (!instance.nick_name) {
      setError(prev => ({ ...prev, nick_name: 'This field is required' }));
    }

    if (!instance.air_id) {
      setError(prev => ({ ...prev, air_id: 'This field is required' }));
    }
    if (!instance.instance_type || !instance.instance_size || !instance.owner_name || !instance.nick_name || !instance.air_id)
      return;
    closeSideBar();
    instance['organisation_id'] = organisationId;
    dispatch(apiOnboardOrgInstanceRequest(auth.token, instance))
  };

  // useEffect(() => {
  //   if (instance.air_id?.length > 4) {
  //     dispatch(apiGetInstanceDetailRequest(instance?.air_id, auth.token));
  //   }
  // }, [instance.air_id]);

  useEffect(() => {
    if (gears?.instanceDetails) {
      console.log('INSTANCE', gears?.instanceDetails);
      setInstance(prev => ({
        ...prev,
        id: gears?.instanceDetails?.id,
        owner_name: gears?.instanceDetails?.owner_name,
        nick_name: gears?.instanceDetails?.nick_name,
        lan_ip: gears?.instanceDetails?.lan_ip,
        public_ip: gears?.instanceDetails?.public_ip,
        auto_update: gears?.instanceDetails?.auto_update,
        instance_size: gears?.instanceDetails?.instance_size,
        instance_type: gears?.instanceDetails?.instance_type,
        instance_regioin: gears?.instanceDetails?.instance_regioin,
        vmix_licence_number: gears?.instanceDetails?.vmix_licence_number,
      }));
      setError(prev => ({
        ...prev,
        air_id: '',
      }));
    } else if (gears?.instanceDetailsErr) {
      setError(prev => ({
        ...prev,
        air_id: gears?.instanceDetailsErr,
      }));
    }

    return () => dispatch(clearInstanceDetails());
  }, [gears?.instanceDetails, gears?.instanceDetailsErr, dispatch]);

  useEffect(() => {
    setError(prev => ({
      ...prev,
      nick_name: '',
      air_id: '',
      instance_size: '',
      instance_type: '',
      owner_name: '',
    }));
  }, [instance?.nick_name, instance?.air_id, instance?.instance_size, instance?.instance_type, instance?.owner_name,]);

  const customRoleStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#344054',
      width: '90%',
      backgroundColor: '#fff',
    }),
    control: () => ({
      width: '100%',
      display: 'flex',
      border: '1px solid #D0D5DD',
      borderRadius: 8,
      height: 44,
      fontWeight: 400,
      color: '#344054',
      fontSize: 16,
      fontFamily: 'Inter',
    }),
    container: base => ({
      ...base,
      flex: 1,
    }),
  };

  const ValueContainer = props => {
    return (
      <components.Option {...props}>
        <OptionsValueContainer>
          <OptionText>{props.data.label}</OptionText>
          {props.data.label === props.isSelected ? (
            <img src={tick} style={{ width: 13.33, height: 9.17 }} />
          ) : null}
        </OptionsValueContainer>
      </components.Option>
    );
  };

  const DropDownIcon = props => (
    <DropDownIconContainer>
      <svg width={12} height={8} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m1 1.5 5 5 5-5"
          stroke="#667085"
          strokeWidth={1.667}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </DropDownIconContainer>
  );

  const nameChangeHandler = e => {
    const reg = /^([^$%,./<>?;':"`~\_=+(\*&^%$#@!|)]*)$/;
    if (reg.test(e.target.value)) {
      setInstance(prev => ({
        ...prev,
        vmix_licence_number: e.target.value,
      }));
    }
  };

  return (
    <RightNavContainer>
      {/* =========================Header=================================== */}
      <RightNavContainerX onClick={closeSideBar}>
        <RightNavContainerXPar src={cross} />
      </RightNavContainerX>
      <SideBarMainDiv>
        <SDMHeading>Onboard Instance</SDMHeading>
        {/* =========================Body=================================== */}
        <RightNavSecDiv>
          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>Instance Type</SDMHeadingP>
            <SBMSDiv3a>
              <Select
                options={typeOptions}
                placeholder={'Instance Type'}
                styles={customRoleStyles}
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    instance_type: e.value,
                  }))
                }
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DropDownIcon,
                  Option: ValueContainer,
                }}
                value={
                  instance?.instance_type
                    ? typeOptions?.find(
                        type => type?.value === instance?.instance_type,
                      ) || ''
                    : ''
                }
              />
            </SBMSDiv3a>
            {error?.instance_type && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.instance_type}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Software License Number</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="Enter License"
                defaultValue={''}
                value={instance?.vmix_licence_number}
                style={{ color: '#667085' }}
                onChange={nameChangeHandler}
              />
            </SBMSDiv3>
          </div>

          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>Instance Region</SDMHeadingP>
            <SBMSDiv3a>
              <Select
                options={regionOptions}
                placeholder={'Select Region'}
                styles={customRoleStyles}
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    instance_regioin: e.value,
                  }))
                }
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DropDownIcon,
                  Option: ValueContainer,
                }}
                value={
                  instance?.instance_regioin
                    ? regionOptions?.find(
                        region => region?.value === instance?.instance_regioin,
                      ) || ''
                    : ''
                }
              />
            </SBMSDiv3a>
          </div>

          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>Size:</SDMHeadingP>
            <SBMSDiv3a>
              <Select
                options={sizeOptions}
                placeholder={'Instance Size'}
                styles={customRoleStyles}
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    instance_size: e.value,
                  }))
                }
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DropDownIcon,
                  Option: ValueContainer,
                }}
                value={
                  instance?.instance_size
                    ? sizeOptions?.find(
                        size => size?.value === instance?.instance_size,
                      ) || ''
                    : ''
                }
              />
            </SBMSDiv3a>
            {error?.instance_size && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.instance_size}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Owner</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="Owner"
                style={{ color: '#667085' }}
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    owner_name: e.target.value,
                  }))
                }
                value={instance?.owner_name || ''}
              />
            </SBMSDiv3>
            {error?.owner_name && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.owner_name}
                </p>
              </div>
            )}
          </div>
          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Nickname</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="Enter Nickname"
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    nick_name: e.target.value,
                  }))
                }
                value={instance?.nick_name || ''}
              />
            </SBMSDiv3>
            {error?.nick_name && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.nick_name}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>AIR ID</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="ID 0192847502-B"
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    air_id: e.target.value,
                  }))
                }
                value={instance?.air_id || ''}
              />
            </SBMSDiv3>
            {error?.air_id && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.air_id}
                </p>
              </div>
            )}
          </div>
          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>WAN IP</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="-"
                onChange={e => {
                  checkIPValidity(e.target.value, 'public_ip');
                }}
                value={instance?.public_ip || ''}
              />
            </SBMSDiv3>
            {error?.public_ip && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.public_ip}
                </p>
              </div>
            )}
          </div>
          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>LAN IP</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="-"
                onChange={e => {checkIPValidity(e.target.value, 'lan_ip');}}
                value={instance?.lan_ip || ''}
              />
            </SBMSDiv3>
            {error?.lan_ip && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.lan_ip}
                </p>
              </div>
            )}
          </div>
        </RightNavSecDiv>
      </SideBarMainDiv>
      {/* =========================Footer=================================== */}
      <RightNavContainerFoot>
        <RNCFBtn>
          <TeamHeadBtnImgP onClick={closeSideBar}>Cancel</TeamHeadBtnImgP>
        </RNCFBtn>
        <RNCFBtn2>
          <TeamHeadBtnImgPa onClick={onConfirm}>Confirm</TeamHeadBtnImgPa>
        </RNCFBtn2>
      </RightNavContainerFoot>
    </RightNavContainer>
  );
};

export default OnboardInstance;

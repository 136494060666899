import React, { useState } from 'react';
import {
  SearchHeadingDiv,
  SearchHeadingDivP1,
  SearchHeadingDivP1pa,
  SearchHeadingDivP2,
  SearchHeadingDivP3,
  ArrowImg,
} from '../styles';
import arrow from '../../../Assets/images/arrowLeft.png';

const SearchHeading = ({ sortProjects, setSortProjects }) => {
  const [arrowDirection, setArrowDirection] = useState(true);

  const handleHeadingClick = heading => {
    const direction = sortProjects.heading === heading ? !arrowDirection : true;

    setArrowDirection(direction);

    setSortProjects(prev => ({
      ...prev,
      heading,
      arrowDirection: direction ? 'up' : 'down',
    }));
  };

  return (
    <SearchHeadingDiv>
      <SearchHeadingDivP1 onClick={() => handleHeadingClick('name')}>
        <SearchHeadingDivP1pa>Project Title</SearchHeadingDivP1pa>
        {sortProjects?.heading === 'name' &&
        (sortProjects?.arrowDirection === 'up' ||
          sortProjects?.arrowDirection === 'down') ? (
          <ArrowImg src={arrow} direction={sortProjects?.arrowDirection} />
        ) : null}
      </SearchHeadingDivP1>
      <SearchHeadingDivP2 onClick={() => handleHeadingClick('created_at')}>
        <SearchHeadingDivP1pa>Date Created</SearchHeadingDivP1pa>
        {sortProjects?.heading === 'created_at' &&
        (sortProjects?.arrowDirection === 'up' ||
          sortProjects?.arrowDirection === 'down') ? (
          <ArrowImg src={arrow} direction={sortProjects?.arrowDirection} />
        ) : null}
      </SearchHeadingDivP2>
      <SearchHeadingDivP3></SearchHeadingDivP3>
    </SearchHeadingDiv>
  );
};

export default SearchHeading;

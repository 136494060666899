import DocumentCard from '../../../Components/DocumentCard';
import { Header, MainContainer, SubHeader } from './styles';
import discord from '../../../Assets/images/discord.svg';
import slack from '../../../Assets/images/slack.svg';
import unityIntercom from '../../../Assets/images/unityIntercom.svg';
import syncStage from '../../../Assets/images/sync_stage.png';
import multiview from '../../../Assets/images/multiview.png';
import programFeed from '../../../Assets/images/program_feed.png';
import dashboardIcon from '../../../Assets/images/gauge.png';
import {
  CloseIcon,
  NavTitle,
  RightNavContainer,
  SideBarContent,
} from 'Layouts/ProjectLayout/styles';
import X from 'Components/Icons/X';
import { useState, useEffect } from 'react';
import AddChannels from 'Components/AddChannels';
import { getObjectByLowestValue } from 'Utils/permissions';

const Main = ({ state, auth, userInformation, organisationId }) => {
  const connectedApps = [
    {
      type: 'discord',
      title: 'Discord',
      subTitle: '',
      description: 'Everything you need for work, all in one place.',
      image: discord,
      btnTitle: 'Open Discord',
      url: null,
    },
    {
      type: 'unity_intercom',
      title: 'Unity Intercom',
      subTitle: '',
      description: 'Plan, track, and release great software.',
      image: unityIntercom,
      btnTitle: 'Open Unity',
      url: null,
    },
    {
      type: 'slack',
      title: 'Slack',
      subTitle: '',
      description: 'Send notifications to channels and create projects.',
      image: slack,
      btnTitle: 'Open Slack',
      url: null,
    },
    {
      type: 'sync_stage',
      title: 'SyncStage',
      subTitle: '',
      description: 'Ultra low latency audio collaboration.',
      image: syncStage,
      btnTitle: 'Open Sync',
      url: null,
    },
    {
      type: 'multi_view',
      title: 'Multiview',
      subTitle: '',
      image: multiview,
      btnTitle: 'Open',
      description: 'View multiple cameras, program and preview',
      url: null,
    },
    {
      type: 'program_feed',
      title: 'Program Feed',
      subTitle: '',
      image: programFeed,
      btnTitle: 'Open',
      description: 'View program feed fullscreen.',
      url: null,
    },
    {
      type: 'dashboard',
      title: 'Dashboard',
      subTitle: '',
      image: dashboardIcon,
      btnTitle: 'Open',
      description: 'View dashboard screen',
      url: null,
    },
  ];

  const SideNavPageType = {
    discord: {
      title: 'Edit Discord',
      subTitle: 'Select a default Discord Server for this organization.',
    },
    unity_intercom: {
      title: 'Edit Unity Intercom',
      subTitle: 'Select a default unity server for this organization.',
    },
    slack: {
      title: 'Edit Slack',
      subTitle: 'Select a default slack channel for this organization.',
    },
    sync_stage: {
      title: 'Edit SyncStage',
      subTitle: 'Select a default sync stage for this organization.',
    },
    multi_view: {
      title: 'Edit Mutiview',
      subTitle: 'Select a default multiview link for this organization.',
    },
    program_feed: {
      title: 'Edit Program Feed',
      subTitle: 'Select a default program feed link for this organization.',
    },
    dashboard: {
      title: 'Edit Dashboard',
      subTitle: 'Select a default dashboard link for this organization.',
    },
  };
  const [showRightNav, setShowRightNav] = useState(false);
  const [sideNavPage, setSideNavPage] = useState(null);
  const [channelData, setChannelData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const onRightNavClose = () => {
    setShowRightNav(false);
    setSelectedData(null);
  };

  // const organisation_data = getObjectByLowestValue(
  //   userInformation?.data?.organisation_data,
  //   'role',
  // );

  useEffect(() => {
    if (state.projectComms) {
      state.projectComms.forEach(item => {
        const channel = connectedApps.findIndex(app => app.type === item.type);
        console.log('channel : ', channel);
        if (channel !== -1) {
          connectedApps[channel].url = item?.url;
          connectedApps[channel].id = item?.id;
        }
      });
      setChannelData(connectedApps);
    } else setChannelData(connectedApps);
  }, [state.projectComms]);

  return (
    <MainContainer>
      <Header>Integrations and connected apps</Header>
      <br />
      <SubHeader>
        Supercharge your workflow and connect the tool you use every day.
      </SubHeader>
      <div className="row" style={{ marginTop: '48px' }}>
        {channelData &&
          channelData.map((app, index) => (
            <div
              className="col-lg-6 col-xl-4"
              style={{ marginBottom: '12px' }}
              key={index}
            >
              <DocumentCard
                btnTitle={app.btnTitle}
                url={app.url}
                title={app.title}
                subTitle={app.subTitle}
                description={app.description}
                image={app.image}
                deleteAction={
                  <button
                    className="btn btn-link text-decoration-none"
                    onClick={() => {
                      setSideNavPage(app.type);
                      setShowRightNav(true);
                      if (app.id) {
                        setSelectedData(
                          state.projectComms.find(item => item.id === app.id),
                        );
                      }
                    }}
                    rel="noopener noreferrer"
                  >
                    Edit
                  </button>
                }
              />
            </div>
          ))}
      </div>
      {showRightNav && (
        <RightNavContainer style={{ position: 'fixed', top: '0', right: '0' }}>
          <CloseIcon onClick={onRightNavClose}>
            <X />
          </CloseIcon>

          <NavTitle>
            {SideNavPageType[sideNavPage]?.title}
            <br />
            <span>{SideNavPageType[sideNavPage]?.subTitle}</span>
          </NavTitle>

          <SideBarContent>
            <AddChannels
              selectedData={selectedData}
              onRightNavClose={onRightNavClose}
              auth={auth}
              organisationId={organisationId}
              sideNavPage={sideNavPage}
            />
          </SideBarContent>
        </RightNavContainer>
      )}
    </MainContainer>
  );
};
export default Main;

import * as types from '../constants/invite';

// invite
export const apiInviteRequest = (
  token,
  email,
  user_type = null,
  project_id = null,
  collaborator_id = null,
) => ({
  type: types.API_INVITE_REQUEST,
  token,
  email,
  user_type,
  project_id,
  collaborator_id,
});

export const apiInviteRequestSuccess = response => ({
  type: types.API_INVITE_REQUEST_SUCCESS,
  response,
});

export const apiInviteRequestFailed = response => ({
  type: types.API_INVITE_REQUEST_FAILED,
  response,
});

export const apiCheckInvitationRequest = token_id => ({
  type: types.API_CHECK_INVITATION_REQUEST,
  token_id,
});

export const apiCheckInvitationSuccess = response => ({
  type: types.API_CHECK_INVITATION_SUCCESS,
  response,
});

export const apiCheckInvitationFailed = response => ({
  type: types.API_CHECK_INVITATION_FAILED,
  response,
});

export const apiGetPendingInvitationsRequest = (token, selectedLevel, id) => ({
  type: types.API_GET_PENDING_INVITATIONS_REQUEST,
  token,
  selectedLevel,
  id,
});

export const apiGetProjectPendingInvitationsSuccess = response => ({
  type: types.API_GET_PROJECT_PENDING_INVITATIONS_SUCCESS,
  response,
});

export const apiGetOrgPendingInvitationsSuccess = response => ({
  type: types.API_GET_ORG_PENDING_INVITATIONS_SUCCESS,
  response,
});

export const apiGetPendingInvitationsFailed = response => ({
  type: types.API_GET_PENDING_INVITATIONS_FAILED,
  response,
});

export const apiResendInvitationRequest = (token, invitationInformation) => ({
  type: types.API_RESEND_INVITATION_REQUEST,
  token,
  invitationInformation,
});

export const apiResendInvitationSuccess = response => ({
  type: types.API_RESEND_INVITATION_SUCCESS,
  response,
});

export const apiResendInvitationFailed = response => ({
  type: types.API_RESEND_INVITATION_FAILED,
  response,
});

export const resetInvite = () => ({
  type: types.RESET_INVITE,
});

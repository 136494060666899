import { put, call, all, spawn, takeEvery, delay } from 'redux-saga/effects';
import { gearServices } from '../services/gear';
import * as types from '../constants/gear';
import * as actions from '../actions/gear';

function* apiGetAllOrgRobotsWorker(action) {
  try {
    const result = yield call(gearServices.apiGetAllOrgRobots, action);
    yield put(actions.apiGetAllOrgRobotsSuccess(result, action));
  } catch (err) {
    yield put(actions.apiGetAllOrgRobotsFailed(err, action));
  }
}
function* apiGetAllOrgRobotsWatcher() {
  yield takeEvery(
    types.API_GET_ALL_ORG_ROBOTS_REQUEST,
    apiGetAllOrgRobotsWorker,
  );
}

function* apiOnboardOrgRobotWorker(action) {
  try {
    const result = yield call(gearServices.apiOnboardOrgRobot, action);
    yield put(actions.apiOnboardOrgRobotSuccess(result, action));
  } catch (err) {
    yield put(actions.apiOnboardOrgRobotFailed(err, action));
  }
}
function* apiOnboardOrgRobotWatcher() {
  yield takeEvery(
    types.API_ONBOARD_ORG_ROBOT_REQUEST,
    apiOnboardOrgRobotWorker,
  );
}

function* apiUpdateOrgRobotWorker(action) {
  try {
    const result = yield call(gearServices.apiUpdateOrgRobot, action);
    yield put(actions.apiUpdateOrgRobotSuccess(result, action));
  } catch (err) {
    yield put(actions.apiUpdateOrgRobotFailed(err));
  }
}
function* apiUpdateOrgRobotWatcher() {
  yield takeEvery(
    types.API_UPDATE_ORG_ROBOT_REQUEST,
    apiUpdateOrgRobotWorker,
  );
}

function* apiCreateShootRobotWorker(action) {
  try {
    const result = yield call(gearServices.apiCreateShootRobot, action);
    yield put(actions.apiCreateShootRobotSuccess(result, action));
  } catch (err) {
    yield put(actions.apiCreateShootRobotFailed(err, action));
  }
}
function* apiCreateShootRobotWatcher() {
  yield takeEvery(
    types.API_CREATE_SHOOT_ROBOT_REQUEST,
    apiCreateShootRobotWorker,
  );
}

function* apiGetAllShootRobotsWorker(action) {
  try {
    const result = yield call(gearServices.apiGetAllShootRobots, action);
    yield put(actions.apiGetAllShootRobotsSuccess(result, action));
  } catch (err) {
    yield put(actions.apiGetAllShootRobotsFailed(err, action));
  }
}
function* apiGetAllShootRobotsWatcher() {
  yield takeEvery(
    types.API_GET_ALL_SHOOT_ROBOT_REQUEST,
    apiGetAllShootRobotsWorker,
  );
}

function* apiUpdateShootRobotWorker(action) {
  try {
    const result = yield call(gearServices.apiUpdateShootRobot, action);
    yield put(actions.apiUpdateShootRobotSuccess(result, action));
  } catch (err) {
    yield put(actions.apiUpdateShootRobotFailed(err, action));
  }
}
function* apiUpdateShootRobotWatcher() {
  yield takeEvery(
    types.API_UPDATE_SHOOT_ROBOT_REQUEST,
    apiUpdateShootRobotWorker,
  );
}

function* apiDeleteShootRobotWorker(action) {
  try {
    const result = yield call(gearServices.apiDeleteShootRobot, action);
    yield put(actions.apiDeleteShootRobotSuccess(result, action));
  } catch (err) {
    yield put(actions.apiDeleteShootRobotFailed(err, action));
  }
}
function* apiDeleteShootRobotWatcher() {
  yield takeEvery(
    types.API_DELETE_SHOOT_ROBOT_REQUEST,
    apiDeleteShootRobotWorker,
  );
}

function* apiCheckValidRobotWorker(action) {
  try {
    const result = yield call(gearServices.apiCheckValidRobot, action);
    yield put(actions.apiCheckValidRobotSuccess(result, action));
  } catch (err) {
    yield put(actions.apiCheckValidRobotFailed(action));
  }
}
function* apiCheckValidRobotWatcher() {
  yield takeEvery(
    types.API_CHECK_VALID_ROBOT_REQUEST,
    apiCheckValidRobotWorker,
  );
}

function* apiGetInventoryRobotInfoWorker(action) {
  try {
    const result = yield call(gearServices.apiGetInventoryRobotInfo, action);
    yield put(actions.apiGetInventoryRobotInfoSuccess(result, action));
  } catch (err) {
    yield put(actions.apiGetInventoryRobotInfoFailed(action));
  }
}
function* apiGetInventoryRobotInfoWatcher() {
  yield takeEvery(
    types.API_GET_INVENTORY_ROBOT_INFO_REQUEST,
    apiGetInventoryRobotInfoWorker,
  );
}

function* apiGetRobotLiveStatusWorker(action) {
  try {
    const result = yield call(gearServices.apiGetRobotLiveStatus, action);
    yield put(actions.apiGetRobotLiveStatusSuccess(result, action));
  } catch (err) {
    yield put(actions.apiGetRobotLiveStatusFailed(err, action));
  }
}
function* apiGetRobotLiveStatusWatcher() {
  yield takeEvery(
    types.API_GET_ROBOT_LIVE_STATUS_REQUEST,
    apiGetRobotLiveStatusWorker,
  );
}

function* apiGetAllOrgInstanceWorker(action) {
  try {
    const result = yield call(gearServices.apiGetAllOrgInstance, action);
    yield put(actions.apiGetAllOrgInstanceSuccess(result, action));
  } catch (err) {
    yield put(actions.apiGetAllOrgInstanceFailed(err, action));
  }
}
function* apiGetAllOrgInstanceWatcher() {
  yield takeEvery(
    types.API_GET_ALL_ORG_INSTANCE_REQUEST,
    apiGetAllOrgInstanceWorker,
  );
}

function* apiOnboardOrgInstanceWorker(action) {
  try {
    const result = yield call(gearServices.apiOnboardOrgInstance, action);
    yield put(actions.apiOnboardOrgInstanceSuccess(result, action));
  } catch (err) {
    yield put(actions.apiOnboardOrgInstanceFailed(err, action));
  }
}
function* apiOnboardOrgInstanceWatcher() {
  yield takeEvery(
    types.API_ONBOARD_ORG_INSTANCE_REQUEST,
    apiOnboardOrgInstanceWorker,
  );
}

function* apiUpdateOrgInstanceWorker(action) {
  try {
    const result = yield call(gearServices.apiUpdateOrgInstance, action);
    yield put(actions.apiUpdateOrgInstanceSuccess(result, action));
  } catch (err) {
    yield put(actions.apiUpdateOrgInstanceFailed(err));
  }
}
function* apiUpdateOrgInstanceWatcher() {
  yield takeEvery(
    types.API_UPDATE_ORG_INSTANCE_REQUEST,
    apiUpdateOrgInstanceWorker,
  );
}

function* apiCreateShootInstanceWorker(action) {
  try {
    const result = yield call(gearServices.apiCreateShootInstance, action);
    yield put(actions.apiCreateShootInstanceSuccess(result, action));
  } catch (err) {
    yield put(actions.apiCreateShootInstanceFailed(err, action));
  }
}
function* apiCreateShootInstanceWatcher() {
  yield takeEvery(
    types.API_CREATE_SHOOT_INSTANCE_REQUEST,
    apiCreateShootInstanceWorker,
  );
}

function* apiGetAllShootInstanceWorker(action) {
  try {
    const result = yield call(gearServices.apiGetAllShootInstance, action);
    yield put(actions.apiGetAllShootInstanceSuccess(result, action));
  } catch (err) {
    yield put(actions.apiGetAllShootInstanceFailed(err, action));
  }
}
function* apiGetAllShootInstanceWatcher() {
  yield takeEvery(
    types.API_GET_ALL_SHOOT_INSTANCE_REQUEST,
    apiGetAllShootInstanceWorker,
  );
}

function* apiUpdateShootInstanceWorker(action) {
  try {
    const result = yield call(gearServices.apiUpdateShootInstance, action);
    yield put(actions.apiUpdateShootInstanceSuccess(result, action));
  } catch (err) {
    yield put(actions.apiUpdateShootInstanceFailed(err, action));
  }
}
function* apiUpdateShootInstanceWatcher() {
  yield takeEvery(
    types.API_UPDATE_SHOOT_INSTANCE_REQUEST,
    apiUpdateShootInstanceWorker,
  );
}

function* apiDeleteShootInstanceWorker(action) {
  try {
    const result = yield call(gearServices.apiDeleteShootInstance, action);
    yield put(actions.apiDeleteShootInstanceSuccess(result, action));
  } catch (err) {
    yield put(actions.apiDeleteShootInstanceFailed(err, action));
  }
}
function* apiDeleteShootInstanceWatcher() {
  yield takeEvery(
    types.API_DELETE_SHOOT_INSTANCE_REQUEST,
    apiDeleteShootInstanceWorker,
  );
}

// DEPRECATED SECTION
// function* apiGetInstanceDetailsWorker(action) {
//   try {
//     const result = yield call(gearServices.apiGetInstanceDetails, action);
//     yield put(actions.apiGetInstanceDetailSuccess(result, action));
//   } catch (err) {
//     yield put(actions.apiGetInstanceDetailFailed(err, action));
//   }
// }
// function* apiGetInstanceDetailsWatcher() {
//   yield takeEvery(
//     types.API_GET_INSTANCE_DETAILS_REQUEST,
//     apiGetInstanceDetailsWorker,
//   );
// }
// function* apiPostGearWorker(action) {
//   try {
//     if (action.formType === '1') {
//       const result = yield call(gearServices.apiPostCamera, action);
//       yield put(actions.apiGearPostSuccess(result, action));
//       yield put(
//         assetsActions.apiGetAllAssetsRequest(
//           action.token,
//           action.organisationId,
//         ),
//       );
//     } else { // Deprecated
//       const result = yield call(gearServices.apiPostInstance, action);
//       yield put(actions.apiGearPostSuccess(result, action));
//       yield put(
//         assetsActions.apiGetAllAssetsRequest(
//           action.token,
//           action.organisationId,
//         ),
//       );
//     }
//   } catch (err) {
//     console.log('The err is: ' + err)
//     console.log('The action is: ' + JSON.stringify(action));
//     yield put(actions.apiGearPostFailed(err, action));
//   }
// }
// function* apiPostGearWatcher() {
//   yield takeEvery(types.API_GEAR_POST_REQUEST, apiPostGearWorker);
// }
// function* apiGetCameraDeactivatesWorker(action) {
//   try {
//     const result = yield call(gearServices.apiGetCamDeactivate, action);
//     yield put(actions.apiGetCameraDeactivateSuccess(result, action));
//     yield put(
//       assetsActions.apiGetAllAssetsRequest(action.token, action.organisationId),
//     );
//     yield put(shootActions.apiShootGetRequest(action.token, action.project_id));
//   } catch (err) {
//     yield put(actions.apiGetCameraDeactivateFailed(err, action));
//   }
// }
// function* apiGetCameraDeactivatesWatcher() {
//   yield takeEvery(
//     types.API_POST_CAMERA_DEACTIVATE_REQUEST,
//     apiGetCameraDeactivatesWorker,
//   );
// }
// function* apiListGearWorker(action) {
//   try {
//     const result = yield call(gearServices.apiListGear, action);

//     yield put(actions.apiGearListSuccess(result, action));
//   } catch (err) {
//     yield put(actions.apiGearListFailed(err, action));
//   }
// }
// function* apiListGearWatcher() {
//   yield takeEvery(types.API_GEAR_LIST_REQUEST, apiListGearWorker);
// }
// function* apiGearGlobalListWorker(action) {
//   try {
//     const result = yield call(gearServices.apiGearGlobalList, action);

//     yield put(actions.apiGearGlobalListSuccess(result, action));
//   } catch (err) {
//     yield put(actions.apiGearGlobalListFailed(err, action));
//   }
// }
// function* apiGearGlobalListWatcher() {
//   yield takeEvery(types.API_GEAR_GLOBAL_LIST_REQUEST, apiGearGlobalListWorker);
// }
// function* apiGearAssignedListWorker(action) {
//   try {
//     const result = yield call(gearServices.apiGearAssignedList, action);

//     yield put(actions.apiGearAssignedListSuccess(result, action));
//     yield put(
//       assetsActions.apiGetAllAssetsRequest(
//         action.token,
//         action?.organisationId,
//       ),
//     );
//   } catch (err) {
//     yield put(actions.apiGearAssignedListFailed(err, action));
//   }
// }
// function* apiGearAssignedListWatcher() {
//   yield takeEvery(
//     types.API_GEAR_ASSIGN_LIST_REQUEST,
//     apiGearAssignedListWorker,
//   );
// }
// function* apiPostGearAssignWorker(action) {
//   try {
//     yield call(gearServices.apiGearAssign, action);
//     yield put(
//       actions.apiGearAssignedListRequest(
//         action.token,
//         action.project_id,
//         action?.organisationId,
//       ),
//     );
//     yield put(
//       assetsActions.apiGetAllAssetsRequest(
//         action.token,
//         action?.organisationId,
//       ),
//     );
//   } catch (err) {
//     yield put(actions.apiGearAssignedFailed(err, action));
//   }
// }
// function* apiPostGearAssignWatcher() {
//   yield takeEvery(types.API_GEAR_ASSIGN_REQUEST, apiPostGearAssignWorker);
// }
// function* apiPatchCameraAssignWorker(action) {
//   try {
//     const result = yield call(gearServices.apiPatchCamera, action);
//     // yield call(gearServices.apiPatchCamera, action);
//     yield put(actions.apiCameraPatchSuccess(result, action));
//     yield put(
//       assetsActions.apiGetAllAssetsRequest(action.token, action.organisationId),
//     );
//     // if (action.project_id)
//     //   yield put(
//     //     actions.apiGearAssignedListRequest(
//     //       action.token,
//     //       action.project_id,
//     //       action.organisationId,
//     //     ),
//     //   );
//   } catch (err) {
//     yield put(actions.apiCameraPatchFailed(err, action));
//   }
// }
// function* apiPatchCameraAssignWatcher() {
//   yield takeEvery(types.API_CAMERA_PATCH_REQUEST, apiPatchCameraAssignWorker);
// }
// function* apiPatchInstanceAssignWorker(action) {
//   if (action?.gear?.organisation) {
//     delete action.gear.organisation;
//   }

//   try {
//     const result = yield call(gearServices.apiPatchInstance, action);
//     if (action?.isDelete) {
//       yield put(actions.apiInstanceDeleteSuccess(result, action));
//     } else {
//       yield put(actions.apiInstancePatchSuccess(result, action));
//     }
//     yield put(
//       assetsActions.apiGetAllAssetsRequest(action.token, action.organisationId),
//     );
//     if (action.project_id)
//       yield put(
//         actions.apiGearAssignedListRequest(
//           action.token,
//           action.project_id,
//           action.organisationId,
//         ),
//       );
//   } catch (err) {
//     yield put(actions.apiInstancePatchFailed(err, action));
//   }
// }
// function* apiPatchInstanceAssignWatcher() {
//   yield takeEvery(
//     types.API_INSTANCE_PATCH_REQUEST,
//     apiPatchInstanceAssignWorker,
//   );
// }
// function* apiGetCameraDetailsWorker(action) {
//   try {
//     const result = yield call(gearServices.apiGetCamDetails, action);
//     yield put(actions.apiGetCameraDetailSuccess(result, action));
//   } catch (err) {
//     yield put(actions.apiGetCameraDetailFailed(err, action));
//   }
// }
// function* apiGetCameraDetailsWatcher() {
//   yield takeEvery(
//     types.API_GET_CAMERA_DETAILS_REQUEST,
//     apiGetCameraDetailsWorker,
//   );
// }
// END

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* gearRootSaga() {
  const sagas = [
    apiGetAllOrgRobotsWatcher,
    apiOnboardOrgRobotWatcher,
    apiUpdateOrgRobotWatcher,
    apiCreateShootRobotWatcher,
    apiGetAllShootRobotsWatcher,
    apiUpdateShootRobotWatcher,
    apiDeleteShootRobotWatcher,
    apiCheckValidRobotWatcher,
    apiGetInventoryRobotInfoWatcher,
    apiGetRobotLiveStatusWatcher,
    apiGetAllOrgInstanceWatcher,
    apiOnboardOrgInstanceWatcher,
    apiUpdateOrgInstanceWatcher,
    apiCreateShootInstanceWatcher,
    apiGetAllShootInstanceWatcher,
    apiUpdateShootInstanceWatcher,
    apiDeleteShootInstanceWatcher,
    // apiGetInstanceDetailsWatcher,
    // apiPostGearWatcher,
    // apiGetCameraDetailsWatcher,
    // apiGetCameraDeactivatesWatcher,
    // apiListGearWatcher,
    // apiGearGlobalListWatcher,
    // apiGearAssignedListWatcher,
    // apiPostGearAssignWatcher,
    // apiPatchCameraAssignWatcher,
    // apiPatchInstanceAssignWatcher,
  ];

  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      }),
    ),
  );
}
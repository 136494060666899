import axios from 'axios';
import { appConfig } from '../../Config/app';
import { dataToFormData } from '../../Utils/reduxServiceUtils'

const authAPI = axios.create({
  baseURL: appConfig.API_BASE_URL,
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
});

function apiInviteRequest(action) {
  const { email, user_type, project_id, collaborator_id } = action;
  console.log(email, user_type, project_id, collaborator_id);
  return authAPI.get(
    `/api/v1/user/invite/${email}${
      user_type !== null ? '?type=' + user_type : ''
    }${project_id !== null ? '&project=' + project_id : ''}${
      collaborator_id !== null ? '&organisation=' + collaborator_id : ''
    }`,
    null,
  );
}

function apiCheckInvitation(action) {
  return authAPI.get(
    `/api/v1/user/member-invitations/${action.token_id}/`,
    null,
  );
}

function apiGetPendingInvitations(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  return authAPI.get(
    `/api/v1/user/get-pending-invitations/${action.id}?selected_level=${action.selectedLevel}`,
    {headers},
  );
}

function apiResendInvitation(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  const formData = dataToFormData(action.invitationInformation);
  return authAPI.post(
    `/api/v1/user/resend-invitations/`, formData, {headers},
  );
}

export const inviteServices = {
  apiInviteRequest,
  apiCheckInvitation,
  apiGetPendingInvitations,
  apiResendInvitation,
};

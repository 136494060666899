const ConrnerUpRight = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4999 11.6668L16.6666 7.50016M16.6666 7.50016L12.4999 3.3335M16.6666 7.50016H6.66659C5.78253 7.50016 4.93468 7.85135 4.30956 8.47647C3.68444 9.10159 3.33325 9.94944 3.33325 10.8335V16.6668"
        stroke="#667085"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ConrnerUpRight;

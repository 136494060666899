import moment from 'moment';

export const sortByDate = (data, keyField, order = 1) => {
  const arr = data?.sort((a, b) => {
    const dateA = a?.[keyField] ? moment(a[keyField]).format('x') : null;
    const dateB = b?.[keyField] ? moment(b[keyField]).format('x') : null;
    if (dateA && dateB) {
      return (dateA - dateB) * order;
    }
    // Handle cases where either a.date or b.date is undefined or null
    return 0;
  });

  return arr;
};

export const getCombinedDate = (date, time, isUtcString) => {
  if (date && time) {
    let calculatedDateString;
    if (isUtcString) {
      calculatedDateString = `${date} ${time}`;
      return moment.utc(calculatedDateString);
    } else {
      const utcDate = moment(date);
      const utcTime = moment(time);
      calculatedDateString = `${utcDate.format('YYYY-MM-DD')} ${utcTime.format(
        'HH:mm',
      )}`;
      return moment(calculatedDateString);
    }
  }

  return;
};

import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import Accordion from '../Accordion';
import Modal from '../Modal';
import { CrewMemberListItem, PendingInvitedUserListItem } from '../MemberListItem';
import Divider from '../Divider';

// SVG
import errorIcon from '../../Assets/icons/errorIcon.svg';

// styles
import { ModalFooter } from '../CommonStyles';
import { RightNavContentContainer } from './styles';

const RightNavContent = ({
  members,
  onCrewAdd,
  onMemberDrag,
  handleMemberUnAssign,
  membersTitle = 'Crew',
  emailCrews,
  myPermission,
  projectPendingInvitations,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalOperation, setModalOperation] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [data, setData] = useState(null);

  const onMessageCrewMember = id => console.log(id);
  const onMailCrewMember = id => console.log(id);

  const handleMemberDelete = (id, name) => {
    setShowModal(true);
    setModalOperation('member-delete');
    setModalData({
      title: name,
      message: 'Are you sure you want to unassign this member?',
    });
    setData(id);
  };

  const executeModalOperation = () => {
    if (modalOperation === 'member-delete') {
      handleMemberUnAssign(data);
    };
  };

  const modalFooter = (
    <ModalFooter>
      <Button onClick={() => setShowModal(false)} variant="light">
        No, Cancel
      </Button>
      <Button onClick={executeModalOperation} variant="danger">
        Yes, Delete
      </Button>
    </ModalFooter>
  );
  return (
    <RightNavContentContainer>
      <Accordion
        title={membersTitle}
        onAdd={onCrewAdd}
        myPermission={myPermission}
        emailCrews={emailCrews}
      >
        {members?.map((m, index) => (
            <CrewMemberListItem
              key={index}
              id={m.user}
              name={
                m?.first_name ? `${m?.first_name} ${m?.last_name}` : m?.email
              }
              avatar={m?.profile_image}
              email={m?.user_details?.email.toLowerCase()}
              phone={m?.phone}
              user_details={m?.user_details}
              job_title="Camera Operator"
              onDragStart={onMemberDrag}
              onMessage={onMessageCrewMember}
              onMail={onMailCrewMember}
              handleMemberDelete={handleMemberDelete}
              myPermission={myPermission}
            />
          ))}
      </Accordion>

      <Divider />

      {projectPendingInvitations?.map((invitation, index) => (
        <PendingInvitedUserListItem
          key={invitation.id}
          invitationInformation={invitation}
          myPermission={myPermission}
        />
      ))}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        icon={errorIcon}
        title={modalData?.title}
        body={modalData?.message}
        footer={modalFooter}
      />
    </RightNavContentContainer>
  );
};

export default RightNavContent;

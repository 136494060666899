import React, { useState, useEffect } from 'react';
import { ModalBody } from 'Pages/WelcomePage/styles';
import { Modal as ReactModal, Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { appConfig } from 'Config/app';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'Components/Loader';
import { COLOR_CODE } from 'Config/colors';
import Badge from 'Components/Badge';
import {
  AddCrewContentContainer,
  MembersSearchList,
} from 'Components/AddCommsContent/styles';

import './styles.css';
import { setLoginCallBack } from 'Redux/actions/login';
import {
  NoCrewMemberFoundCard,
  NoUserFoundText,
} from 'Components/AddCollaboratorContent/styles';
import { ModalFooter } from 'Components/CommonStyles';
import CustomSelect from 'Components/CustomSelect';

function ScannedProjectListPage() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [show, setShow] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null);
  const auth = useSelector(state => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const { air_id } = useParams();

  function handleModal() {
    setShow(false);
    history.push('/');
  }

  const authAPI = axios.create({
    baseURL: appConfig.API_BASE_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${auth.token}`,
    },
  });

  useEffect(() => {
    async function getData() {
      try {
        const response = await authAPI.get(
          `/api/v1/project/related-project/${air_id}/`,
        );
        console.log('res air : ', response.data?.data);
        const options = Object.keys(response.data?.data).map(key => ({
          value: response.data?.data[key].id,
          label: response.data?.data[key].name,
        }));
        setData(options);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setData(null);
        setLoading(false);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    if (auth.token === null) {
      dispatch(
        setLoginCallBack(history.location.pathname + history.location.search),
      );
      history.push('/login');
    }
  }, [auth, history]);

  return (
    <ReactModal
      show={show}
      onHide={handleModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ReactModal.Header closeButton>
        <ReactModal.Title
          id="contained-modal-title-vcenter"
          style={{ margin: '0px' }}
        >
          Assigned projects
        </ReactModal.Title>
      </ReactModal.Header>
      <ReactModal.Body>
        <ModalBody style={{ width: '100%' }}>
          <AddCrewContentContainer>
            {loading === false ? (
              <MembersSearchList style={{ padding: '0px', textAlign: 'left' }}>
                {data ? (
                  // Object.keys(data).map(key => (
                  //   <div
                  //     className="d-flex mb-3"
                  //     key={data[key].id}
                  //     onClick={() =>
                  //       history.push(
                  //         `/project/launchpad/${data[key].id}?gearId=${air_id}`,
                  //       )
                  //     }
                  //     style={{ cursor: 'pointer' }}
                  //   >
                  //     <Badge
                  //       color={COLOR_CODE[Number(data[key].id % 11)].color}
                  //       bgColor={COLOR_CODE[Number(data[key].id % 11)].bgColor}
                  //       title={data[key].name}
                  //     />
                  //   </div>
                  // ))
                  <CustomSelect
                    options={data}
                    placeholder="Select Project"
                    onChange={e => setSelectedProject(e.value)}
                    isSearchable={true}
                  />
                ) : (
                  <NoCrewMemberFoundCard>
                    <NoUserFoundText>No Projects Found.</NoUserFoundText>
                  </NoCrewMemberFoundCard>
                )}
              </MembersSearchList>
            ) : (
              <Loader />
            )}
          </AddCrewContentContainer>
        </ModalBody>
        <ModalFooter variant="primary">
          <Button onClick={handleModal} variant="light">
            Cancel
          </Button>
          <Button
            onClick={() =>
              history.push(
                `/project/launchpad/${selectedProject}?gearId=${air_id}`,
              )
            }
            variant="primary"
            disabled={!selectedProject}
          >
            Continue
          </Button>
        </ModalFooter>
      </ReactModal.Body>
    </ReactModal>
  );
}

export default ScannedProjectListPage;

import React from 'react';
import { GearHeaderMain } from '../styles';
import Storage from './Storage';
import CloudInstances from './CloudInstances';
import RobotAssetHeader from './RobotAssetHeader';

const GearHeader = ({
  onChangeRightNav,
  robotCount,
  instanceCount,
  assets,
  storage,
}) => {
  return (
    <GearHeaderMain>
      <Storage
        onChangeRightNav={onChangeRightNav}
        assets={assets}
        storage={storage}
      />
      <RobotAssetHeader
        onChangeRightNav={onChangeRightNav}
        robotCount={robotCount}
      />
      <CloudInstances
        onChangeRightNav={onChangeRightNav}
        instanceCount={instanceCount}
      />
    </GearHeaderMain>
  );
};

export default GearHeader;

export const API_SHOOT_GET_REQUEST = 'API_SHOOT_GET_REQUEST';
export const API_SHOOT_GET_SUCCESS = 'API_SHOOT_GET_SUCCESS';
export const API_SHOOT_GET_FAILED = 'API_SHOOT_GET_FAILED';

// Add shoot
export const API_SHOOT_POST_REQUEST = 'API_SHOOT_POST_REQUEST';
export const API_SHOOT_POST_SUCCESS = 'API_SHOOT_POST_SUCCESS';
export const API_SHOOT_POST_FAILED = 'API_SHOOT_POST_FAILED';

// Patch shoot
export const API_SHOOT_PATCH_REQUEST = 'API_SHOOT_PATCH_REQUEST';

export const API_BACKGROUND_DELETE_REQUEST = 'API_BACKGROUND_DELETE_REQUEST';

export const API_SHOOT_PATCH_SUCCESS = 'API_SHOOT_PATCH_SUCCESS';
export const API_SHOOT_PATCH_FAILED = 'API_SHOOT_PATCH_FAILED';

export const API_UPDATE_PERMISSION = 'API_UPDATE_PERMISSION';
export const API_REMOVE_PERMISSION = 'API_REMOVE_PERMISSION';
export const API_REMOVE_PERMISSION_SUCCESS = 'API_REMOVE_PERMISSION_SUCCESS';

export const SET_UPDATE_PERMISSION_STATUS = 'SET_UPDATE_PERMISSION_STATUS';

export const API_RELATED_GEAR_REQUEST = 'API_RELATED_GEAR_REQUEST';
export const API_RELATED_GEAR_FAILED = 'API_RELATED_GEAR_FAILED';
export const SET_RELATED_GEAR_ID = 'SET_RELATED_GEAR_ID';

// reset
export const RESET_SHOOT = 'RESET_SHOOT';

// side pan
export const TOGGLE_SIDEPAN = 'TOGGLE_SIDEPAN';

// side pan
export const TOGGLE_CREW_VIEW = 'TOGGLE_CREW_VIEW';
// side pan
export const TOGGLE_GEAR_VIEW = 'TOGGLE_GEAR_VIEW';
// side pan
export const TOGGLE_SITE_VIEW = 'TOGGLE_SITE_VIEW';
// Set Background Image Size
export const TOGGLE_BACKGROUND_IMAGE_SIZE = 'TOGGLE_BACKGROUND_IMAGE_SIZE';
// Toggle RIGHT_NAV_ON_MOBILE
export const TOGGLE_RIGHT_NAV_ON_MOBILE = 'TOGGLE_RIGHT_NAV_ON_MOBILE';

// LOGIN
export const API_LOGIN_REQUEST = 'API_LOGIN_REQUEST';
export const API_LOGIN_REQUEST_SUCCESS = 'API_LOGIN_REQUEST_SUCCESS';
export const API_LOGIN_REQUEST_FAILED = 'API_LOGIN_REQUEST_FAILED';
export const LOGIN_CALLBACK = 'LOGIN_CALLBACK';

// LOGOUT
export const CLEAR_TOKEN = 'CLEAR_TOKEN';
export const RESET_MSG = 'RESET_MSG';

// user information
export const SET_USER_INFORMATION = 'SET_USER_INFORMATION';

// REQUEST PASSWORD RESET
export const API_PASSWORD_RESET_REQUEST = 'API_PASSWORD_RESET_REQUEST';
export const API_PASSWORD_RESET_SUCCESS = 'API_PASSWORD_RESET_SUCCESS';
export const API_PASSWORD_RESET_FAILED = 'API_PASSWORD_RESET_FAILED';

// REQUEST PASSWORD RESET CONFIRM
export const API_PASSWORD_RESET_CONFIRM_REQUEST =
  'API_PASSWORD_RESET_CONFIRM_REQUEST';
export const API_PASSWORD_RESET_CONFIRM_SUCCESS =
  'API_PASSWORD_RESET_CONFIRM_SUCCESS';
export const API_PASSWORD_RESET_CONFIRM_FAILED =
  'API_PASSWORD_RESET_CONFIRM_FAILED';

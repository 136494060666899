import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

// styles
import {
  AddCrewContentContainer,
  AddGearFormContainer,
  NavFooter,
  DoneButton,
  AddLink,
} from './styles';
import { FormErrorText } from '../CommonStyles';
import { OutlinedButton } from '../IconButton/styles';

// Components
import PlusNaked from 'Components/Icons/PlusNaked';
import X from 'Components/Icons/X';
import Loader from 'Components/Loader';

// Redux
import {
  apiAddCommsRequest,
  apiGetCommsByIdRequest,
  apiPatchCommsRequest,
} from 'Redux/actions/channel';

const AddCommsContent = ({
  auth,
  onRightNavClose,
  projectId,
  commsId = null, // Comm card id. If pass, means Edit buttton is clicked. Only non-syncstage type comm can be updated.
  syncstageJwt,
}) => {
  const dispatch = useDispatch();

  // useSelector
  const channelState = useSelector(state => state.channel);
  const sidebarState = useSelector(state => state.sidebar);
  let orgId = sidebarState.selectedProject?.organisation.id;
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      data: [{ type: 'discord', title: '', session_key: '', url: '' }],
    },
    mode: 'onSubmit',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'data',
    rules: {
      required: true,
    },
  });

  const updateComm = () => {
    handleSubmit(data => {
      const result = {
        type: data.type,
        title: data.title,
        url: data.url,
        session_key: data?.session_key,
      };
      dispatch(apiPatchCommsRequest(commsId, projectId, result, auth.token));
    })();
  };

  const createNewComms = async () => {
    handleSubmit(formdata => {
      let commsData = [];
      for (const item of formdata.data) {
        if (item.type !== 'sync_stage') {
          commsData.push({
            "type": item.type,
            "title": item.title,
            "url": item.url,
          });
        } else {
          commsData.push({
            "type": item.type,
            "title": item.title,
            "ORG": orgId,
            "PROJECT": projectId
          });
        }
      }
      const createCommCardData = {
        project: projectId,
        jwt: syncstageJwt,
        commsData: commsData,
      };
      dispatch(apiAddCommsRequest(createCommCardData, auth.token)); // Create Syncstage sessions and all comms card in the Backend ProjectCommsSerializer
      onRightNavClose();
    })();
  }

  useEffect(() => {
    if (commsId) {
      dispatch(apiGetCommsByIdRequest(commsId, auth.token));
    }
  }, [commsId]);

  useEffect(() => {
    if (channelState.data) {
      setValue('type', channelState?.data?.type);
      setValue('title', channelState?.data?.title);
      setValue('url', channelState?.data?.url);
      setValue('session_key', channelState?.data?.session_key);
    }
  }, [channelState.data]);

  return channelState?.isLoadingServers ? (
    <Loader />
  ) : commsId ? (
      <AddCrewContentContainer>
        <AddGearFormContainer>
          <form>
            {fields.map((item, index) => (
              <div key={item.id}>
                {index > 0 ? (
                  <div
                    onClick={() => remove(index)}
                    className="float-end cursor-pointer"
                    role="button"
                  >
                    <X />
                  </div>
                ) : null}
                <div className="mb-3">
                  <label htmlFor="_type" className="form-label">
                    Type
                  </label>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue="1"
                    rules={{
                      required: 'This field is required.',
                    }}
                    render={({ field }) => (
                      <select {...field} className="form-select" disabled>
                        <option value={'discord'}>Discord</option>
                        <option value={'slack'}>Slack</option>
                        <option value={'unity_intercom'}>Unity Intercom </option>
                      </select>
                    )}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor={`data[${index}].title`} className="form-label">
                    Title
                  </label>

                  <input
                    {...register(`title`)}
                    type="text"
                    className="form-control"
                    placeholder="Add Title"
                    required
                  />

                  {errors.title ? (
                    <FormErrorText className="form-text">
                      This field is required
                    </FormErrorText>
                  ) : null}
                </div>

                <div className="mb-3">
                  <label htmlFor={`data[${index}].url`} className="form-label">
                    URL
                  </label>

                  <input
                    {...register(`url`, {
                      // required: {
                      //   value: true,
                      //   message: 'This field is required',
                      // },
                      pattern: {
                        value:
                          /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                        message: 'This URL is not valid',
                      },
                    })}
                    type="text"
                    className="form-control"
                    placeholder="Add URL"
                  />

                  {errors?.url ? (
                    <FormErrorText className="form-text">
                      {errors?.url?.message}
                    </FormErrorText>
                  ) : null}
                </div>
              </div>
            ))}
          </form>
        </AddGearFormContainer>
        <NavFooter>
          <OutlinedButton className="btn btn-sm" onClick={onRightNavClose}>
            Cancel
          </OutlinedButton>
          <DoneButton
            className="btn btn-primary btn-sm"
            onClick={updateComm}
          >
            Apply
          </DoneButton>
        </NavFooter>
      </AddCrewContentContainer>
  ) : (
    <AddCrewContentContainer>
      <AddGearFormContainer>
        <form>
          {fields.map((item, index) => (
            <div key={item.id}>
              {index > 0 ? (
                <div
                  onClick={() => remove(index)}
                  className="float-end cursor-pointer"
                  role="button"
                >
                  <X />
                </div>
              ) : null}
              <div className="mb-3">
                <label htmlFor="_type" className="form-label">
                  Type
                </label>
                <select
                  className="form-select"
                  {...register(`data[${index}].type`, {
                    required: true,
                  })}
                  defaultValue="discord"
                >
                  <option value={'discord'}>Discord</option>
                  <option value={'slack'}>Slack</option>
                  <option value={'unity_intercom'}>Unity Intercom </option>
                  <option value={'sync_stage'}>SyncStage</option>
                  {/* <option value={'other'}>Other</option> */}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor={`data[${index}].title`} className="form-label">
                  Title
                </label>

                <input
                  {...register(
                    `data[${index}].title`,
                    //  {
                    //   required: true,
                    // }
                  )}
                  type="text"
                  className="form-control"
                  placeholder="Add Title"
                />

                {errors.data?.[index]?.title ? (
                  <FormErrorText className="form-text">
                    This field is required
                  </FormErrorText>
                ) : null}
              </div>

              {watch(`data[${index}].type`) !== 'sync_stage' && (
                <div className="mb-3">
                  <label htmlFor={`data[${index}].url`} className="form-label">
                    URL
                  </label>

                  <input
                    {...register(`url`, {
                      // required: {
                      //   value: true,
                      //   message: 'This field is required',
                      // },
                      pattern: {
                        value:
                          /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                        message: 'This URL is not valid',
                      },
                    })}
                    type="text"
                    className="form-control"
                    placeholder="Add URL"
                  />

                  {errors?.url ? (
                    <FormErrorText className="form-text">
                      {errors?.url?.message}
                    </FormErrorText>
                  ) : null}
                </div>
              )}
              <div
                style={{ border: '1px solid #E4E7EC' }}
                className="my-3"
              ></div>
            </div>
          ))}
        </form>
        <AddLink
          onClick={() => {
            append({
              type: 'discord',
              title: '',
              session_key: '',
              // url: 'https://',
              url: '',
            });
          }}
        >
          <PlusNaked strokeColor="#6941C6" /> Add Another Comm
        </AddLink>
      </AddGearFormContainer>
      <NavFooter>
        <OutlinedButton className="btn btn-sm" onClick={onRightNavClose}>
          Cancel
        </OutlinedButton>
        <DoneButton
          className="btn btn-primary btn-sm"
          onClick={createNewComms}
        >
          Apply
        </DoneButton>
      </NavFooter>
    </AddCrewContentContainer>
  );
};

export default AddCommsContent;

import React from 'react';
import LayoutTopNav from '../../Components/LayoutTopNav'

import { ContentGround, LayoutContainer, SiteContainer } from './styles';

const LaunchPadLayout = ({ children, links = [], projectName = null }) => {
  return (
    <LayoutContainer>
      <ContentGround>
        <LayoutTopNav links={links} projectName={projectName}/>
        <SiteContainer>{children}</SiteContainer>
      </ContentGround>
    </LayoutContainer>
  );
};

export default LaunchPadLayout;

import Clear from '@mui/icons-material/Clear';
import Stack from '@mui/material/Stack';
import Badge from 'Components/Badge';
//import Select, { components as Components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';

const ValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1rem;
`;

const Value = styled.div`
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  margin: 0 0.55rem 0.55rem 0;
  font-size: 0.75rem;
  color: black;
  background-color: rgba(247, 173, 46, 0.6);
  user-select: none;
`;

const XButton = styled.button`
  all: unset;
  margin-left: 0.3rem;
  color: black;
  transition: fill 0.15s ease-in-out;
  cursor: pointer;
  &:hover {
    color: #bb392d;
  }
  &:focus {
    color: #c82f21;
  }
`;

const CustomOutsideSelect = props => {
  const customStyles = {
    control: (provided, { isDisabled }) => ({
      ...provided,
      width: '100%',
      height: 'auto',
      minHeight: '40px',
      maxHeight: '100px',
      overflow: 'auto',
      borderRadius: '8px',
      border: '1px solid #D0D5DD',
      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
      background: '#FFFFFF',
      cursor: isDisabled ? 'not-allowed' : 'default',
    }),
    clearIndicator: provided => ({
      ...provided,
      padding: '0px',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      paddingLeft: '4px',
    }),
  };
  const { isMulti, value, onChange, isCreatable } = props;
  const handleRemoveValue = e => {
    if (!onChange) return;
    const buttonName = e;
    const removedValue = value.find(val => val.value === buttonName);
    if (!removedValue) return;
    onChange(
      value.filter(val => val.value !== buttonName),
      { action: 'remove-value', removedValue },
    );
  };

  return (
    <div>
      <CreatableSelect
        {...props}
        controlShouldRenderValue={!isMulti}
        isCreatable={isCreatable}
        options={props.options}
        styles={customStyles}
        isClearable={false}
        components={{
          IndicatorSeparator: null,
        }}
      />
      <ValuesContainer>
        <Stack direction="row" flexWrap={'wrap'} gap={'8px'} maxWidth={'400px'}>
          {isMulti
            ? value?.map(val => (
                <Badge
                  title={val.label}
                  key={val.value}
                  suffix={
                    <Clear
                      fontSize={'18px'}
                      style={{ padding: '2px', cursor: 'pointer' }}
                      onClick={() => handleRemoveValue(val.value)}
                    />
                  }
                />
              ))
            : null}
        </Stack>
      </ValuesContainer>
    </div>
  );
};

export default CustomOutsideSelect;

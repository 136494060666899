import axios from 'axios';
import { appConfig } from '../../Config/app';

const authAPI = axios.create({
  baseURL: appConfig.API_BASE_URL,
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
});

const dataToFormData = data => {
  const formData = new FormData();
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key];
      formData.append(key, element);
    }
  }

  return formData;
};

function apiGetAllOrgRobots(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  return authAPI.get(`/api/v1/gear/get-all-org-robots/${action.organisationId}/`, {},
    {
      headers,
    },
  );
}

function apiOnboardOrgRobot(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  const formData = dataToFormData(action.robotData);

  return authAPI.post(`/api/v1/gear/org-robot/`, formData,
    {
      headers,
    },
  );
}

function apiUpdateOrgRobot(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  const formData = dataToFormData(action.robotData);
  return authAPI.patch(`/api/v1/gear/org-robot/${action.robotData.id}/`, formData,
    {
      headers,
    },
  );
}

function apiCreateShootRobot(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  const formData = dataToFormData(action.robotData);
  return authAPI.post(`/api/v1/gear/shoot-robot/`, formData,
    {
      headers,
    },
  );
}

function apiGetAllShootRobots(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  return authAPI.get(`/api/v1/gear/get-all-shoot-robots/${action.projectId}/`, {},
    {
      headers,
    },
  );
}

function apiUpdateShootRobot(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  const formData = dataToFormData(action.updateData);
  return authAPI.patch(`/api/v1/gear/shoot-robot/${action.robotId}/`, formData,
    {
      headers,
    },
  );
}

function apiDeleteShootRobot(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  return authAPI.delete(`/api/v1/gear/shoot-robot/${action.robotId}/`, {},
    {
      headers,
    },
  );
}

function apiCheckValidRobot(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  return authAPI.get(`/api/v1/gear/check-valid-robot/${action.airId}/`, {},
    {
      headers,
    },
  );
}

function apiGetInventoryRobotInfo(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  return authAPI.get(`/api/v1/gear/robot/${action.robotId}/`, {},
    {
      headers,
    },
  );
}

function apiGetRobotLiveStatus(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  return authAPI.get(`/api/v1/gear/robot/${action.robotIds.toString()}/`, {},
    {
      headers,
    },
  );
}

function apiGetAllOrgInstance(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  return authAPI.get(`/api/v1/gear/get-all-org-instances/${action.organisationId}/`, {
      headers,
  });
}

function apiOnboardOrgInstance(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  const formData = dataToFormData(action.instanceData);
  return authAPI.post(`/api/v1/gear/org-instance/`, formData,
    {
      headers,
    },
  );
}

function apiUpdateOrgInstance(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  const formData = dataToFormData(action.instanceData);
  return authAPI.patch(`/api/v1/gear/org-instance/${action.instanceData.id}/`, formData,
    {
      headers,
    },
  );
}

function apiCreateShootInstance(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  const formData = dataToFormData(action.instanceData);
  return authAPI.post(`/api/v1/gear/shoot-instance/`, formData,
    {
      headers,
    },
  );
}

function apiGetAllShootInstance(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  return authAPI.get(`/api/v1/gear/get-all-shoot-instance/${action.projectId}/`, {headers});
}

function apiUpdateShootInstance(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  const formData = dataToFormData(action.updateData);
  return authAPI.patch(`/api/v1/gear/shoot-instance/${action.instanceId}/`, formData,
    {
      headers,
    },
  );
}

function apiDeleteShootInstance(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${action.token}`,
  };
  return authAPI.delete(`/api/v1/gear/shoot-instance/${action.instanceId}/`,{headers,});
}

// DEPRECATED SECTION
// function apiGetInstanceDetails(action) {
//   const headers = {
//     Accept: 'application/json',
//     Authorization: `Token ${action.token}`,
//   };

//   return authAPI.get(
//     `/api/v1/gear/air-instance-details/?air_id=${action.air_ids}`,
//     {
//       headers,
//     },
//   );
// }
// function apiPostCamera(action) {
//   const headers = {
//     Accept: 'application/json',
//     Authorization: `Token ${action.token}`,
//   };

//   const formData = dataToFormData(action.data);
//   console.log('The formData is:' + JSON.stringify(formData));

//   // console.log('service', formData);
//   return authAPI.post(`/api/v1/gear/onboard-camera/`, formData, { headers });
// }
// function apiPostInstance(action) {
//   const headers = {
//     Accept: 'application/json',
//     Authorization: `Token ${action.token}`,
//   };

//   const formData = dataToFormData(action.data);

//   return authAPI.post(`/api/v1/gear/air-instance/`, formData, { headers });
// }
// function apiListGear(action) {
//   const headers = {
//     Accept: 'application/json',
//     Authorization: `Token ${action.token}`,
//   };

//   return authAPI.get(`/api/v1/gear/camera/`, { headers });
// }
// function apiPostGear(action) {
//   const headers = {
//     Accept: 'application/json',
//     Authorization: `Token ${action.token}`,
//   };

//   const formData = dataToFormData(action.data);

//   return authAPI.post(`/api/v1/gear/camera/`, formData, { headers });
// }
// function apiGearGlobalList(action) {
//   const headers = {
//     Accept: 'application/json',
//     Authorization: `Token ${action.token}`,
//   };

//   return authAPI.get(
//     `/api/v1/gear/list/?organisation_id=${action?.organisationId}`,
//     {
//       headers,
//     },
//   );
// }
// function apiGetCamDeactivate(action) {
//   const headers = {
//     // Accept: 'application/json',
//     Authorization: `Token ${action.token}`,
//   };
//   return authAPI.post(
//     `/api/v1/gear/deactivate-camera/${action.air_ids}/`,
//     {},
//     {
//       headers,
//     },
//   );
// }
// function apiGetCamDetails(action) {
//   const headers = {
//     Accept: 'application/json',
//     Authorization: `Token ${action.token}`,
//   };

//   return authAPI.get(`/api/v1/gear/get-camera-details/${action.air_ids}/`, {
//     headers,
//   });
// }
// function apiGearAssignedList(action) {
//   const headers = {
//     Accept: 'application/json',
//     Authorization: `Token ${action.token}`,
//   };

//   return authAPI.get(`/api/v1/gear/${action.project_id}/assigned/`, {
//     headers,
//   });
// }
// function apiGearAssign(action) {
//   const headers = {
//     Accept: 'application/json',
//     Authorization: `Token ${action.token}`,
//   };

//   const formData = dataToFormData(action.data);

//   return authAPI.post(`/api/v1/gear/${action.project_id}/assigned/`, formData, {
//     headers,
//   });
// }
// function apiPatchCamera(action) {
//   const headers = {
//     Accept: 'application/json',
//     Authorization: `Token ${action.token}`,
//   };
//   // console.log('gear_id', data);

//   const newFormDataParams = {
//     owner_name: action.gear.owner_name,
//     nick_name: action.gear.nick_name,
//     internal_record_format: action.gear.internal_record_format,
//     external_record_format: action.gear.external_record_format,
//     auto_update: action.gear.auto_update ? 1 : 0,
//     lan_ip: action.gear.lan_ip,
//     public_ip: action.gear.public_ip,
//   };
//   const newFormData = dataToFormData(newFormDataParams);
//   // here action.gear_id is infact the camera_org_id
//   return authAPI.patch(
//     `/api/v1/gear/onboard-camera/${action.gear_id}/`,
//     newFormData,
//     {
//       headers,
//     },
//   );

//   // deprecated API endpoint
//   const formData = dataToFormData(action.gear);
//   return authAPI.patch(`/api/v1/gear/air-camera/${action.gear_id}/`, formData, {
//     headers,
//   });
// }
// function apiPatchInstance(action) {
//   const headers = {
//     Accept: 'application/json',
//     Authorization: `Token ${action.token}`,
//   };

//   const formData = dataToFormData(action.gear);
//   console.log('formData', formData);
//   if (action?.isDelete) {
//     return authAPI.delete(`/api/v1/gear/air-instance/${action.gear_id}/`, {
//       headers,
//     });
//   } else
//     return authAPI.patch(
//       `/api/v1/gear/air-instance/${action.gear_id}/`,
//       formData,
//       {
//         headers,
//       },
//     );
// }
// END

export const gearServices = {
  apiGetAllOrgRobots,
  apiOnboardOrgRobot,
  apiUpdateOrgRobot,
  apiCreateShootRobot,
  apiGetAllShootRobots,
  apiUpdateShootRobot,
  apiDeleteShootRobot,
  apiCheckValidRobot,
  apiGetInventoryRobotInfo,
  apiGetRobotLiveStatus,
  apiGetAllOrgInstance,
  apiOnboardOrgInstance,
  apiUpdateOrgInstance,
  apiCreateShootInstance,
  apiGetAllShootInstance,
  apiUpdateShootInstance,
  apiDeleteShootInstance,
  // apiGetInstanceDetails,
  // apiPostCamera,
  // apiPostInstance,
  // apiListGear,
  // apiPostGear,
  // apiGearGlobalList,
  // apiGetCamDeactivate,
  // apiGetCamDetails,
  // apiGearAssignedList,
  // apiGearAssign,
  // apiPatchCamera,
  // apiPatchInstance,
};
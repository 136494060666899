const ROBOT_STATUS_COLORS = {
  GREEN: '#12B76A',
  GOLD: '#FBC348',
  RED: '#FF5C5C',
  GREY: '#D0D5DD',
  BLACK: '#000000',
};

export const checkRobotStatusColor = (
  selectedRobotLiveStatus,
  userHasAccess,
) => {
  const timeNow = new Date();

  if (!userHasAccess) {
    return ROBOT_STATUS_COLORS.RED;
  } else {
    // const inactivityThresholdMinutes = 3;
    // const inactiveRecently = new Date(selectedRobotLiveStatus?.last_activity) < timeNow - inactivityThresholdMinutes * 60 * 1000;
    if (selectedRobotLiveStatus?.air_station_field_status === 0) {
      return ROBOT_STATUS_COLORS.GREY;
    } else if (selectedRobotLiveStatus?.controllers > 0) {
      return ROBOT_STATUS_COLORS.GOLD;
    } else if (selectedRobotLiveStatus?.controllers === 0) {
      return ROBOT_STATUS_COLORS.GREEN;
    }
  }
  return ROBOT_STATUS_COLORS.GREY;
};

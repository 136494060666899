const TagsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.24992 6.25016H6.25825M17.5749 11.5918L11.5999 17.5668C11.4451 17.7218 11.2613 17.8447 11.059 17.9286C10.8567 18.0125 10.6398 18.0556 10.4208 18.0556C10.2017 18.0556 9.98485 18.0125 9.78252 17.9286C9.58019 17.8447 9.39637 17.7218 9.24159 17.5668L2.08325 10.4168V2.0835H10.4166L17.5749 9.24183C17.8853 9.5541 18.0596 9.97652 18.0596 10.4168C18.0596 10.8571 17.8853 11.2796 17.5749 11.5918Z"
        stroke="#667085"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TagsIcon;

// Get My Storage PLan
export const API_GET_STORAGE_PLAN_REQUEST = 'API_GET_STORAGE_PLAN_REQUEST';
export const API_GET_STORAGE_PLAN_SUCCESS = 'API_GET_STORAGE_PLAN_SUCCESS';
export const API_GET_STORAGE_PLAN_FAILED = 'API_GET_STORAGE_PLAN_FAILED';

// Get Instance Usage
export const API_GET_INSTANCE_USAGE_REQUEST = 'API_GET_INSTANCE_USAGE_REQUEST';
export const API_GET_INSTANCE_USAGE_SUCCESS = 'API_GET_INSTANCE_USAGE_SUCCESS';
export const API_GET_INSTANCE_USAGE_FAILED = 'API_GET_INSTANCE_USAGE_FAILED';

// Get Used wasabi Storage
export const API_GET_WASABI_USED_STORAGE_REQUEST =
  'API_GET_WASABI_USED_STORAGE_REQUEST';
export const API_GET_WASABI_USED_STORAGE_SUCCESS =
  'API_GET_WASABI_USED_STORAGE_SUCCESS';
export const API_GET_WASABI_USED_STORAGE_FAILED =
  'API_GET_WASABI_USED_STORAGE_FAILED';

// Get Available Packages
export const API_GET_AVAILABLE_PACKAGES_REQUEST =
  'API_GET_AVAILABLE_PACKAGES_REQUEST';
export const API_GET_AVAILABLE_PACKAGES_SUCCESS =
  'API_GET_AVAILABLE_PACKAGES_SUCCESS';
export const API_GET_AVAILABLE_PACKAGES_FAILED =
  'API_GET_AVAILABLE_PACKAGES_FAILED';

// 1st Time Purchase
export const API_CREATE_PAYMENT_SESSION_REQUEST =
  'API_CREATE_PAYMENT_SESSION_REQUEST';
export const API_CREATE_PAYMENT_SESSION_SUCCESS =
  'API_CREATE_PAYMENT_SESSION_SUCCESS';
export const API_CREATE_PAYMENT_SESSION_FAILED =
  'API_CREATE_PAYMENT_SESSION_FAILED';

// Continue with Same Package
export const API_PAYMENT_SAME_PACKAGE_SESSION_REQUEST =
  'API_PAYMENT_SAME_PACKAGE_SESSION_REQUEST';
export const API_PAYMENT_SAME_PACKAGE_SESSION_SUCCESS =
  'API_PAYMENT_SAME_PACKAGE_SESSION_SUCCESS';
export const API_PAYMENT_SAME_PACKAGE_SESSION_FAILED =
  'API_PAYMENT_SAME_PACKAGE_SESSION_FAILED';

// Continue with NEW Package
export const API_PAYMENT_NEW_PACKAGE_SESSION_REQUEST =
  'API_PAYMENT_NEW_PACKAGE_SESSION_REQUEST';
export const API_PAYMENT_NEW_PACKAGE_SESSION_SUCCESS =
  'API_PAYMENT_NEW_PACKAGE_SESSION_SUCCESS';
export const API_PAYMENT_NEW_PACKAGE_SESSION_FAILED =
  'API_PAYMENT_NEW_PACKAGE_SESSION_FAILED';

export const API_CLEAR_URL = 'API_CLEAR_URL';

// DEPRECATED SECTION
// export const API_GET_ALL_ASSETS_REQUEST = 'API_GET_ALL_ASSETS_REQUEST';
// export const API_GET_ALL_ASSETS_SUCCESS = 'API_GET_ALL_ASSETS_SUCCESS';
// export const API_GET_ALL_ASSETS_FAILED = 'API_GET_ALL_ASSETS_FAILED';
// END